import { KeyStat } from 'domains/lessons/models/LessonStats';
import { average } from 'utils/average';

function getStatsFromKeysStats(keysStats: KeyStat[]) {
  let numCorrectKeystrokes = 0;
  let numWrongKeystrokes = 0;
  let numMissedKeystrokes = 0;

  let numTouchCorrectKeystrokes = 0;
  let numTouchWrongKeystrokes = 0;
  let numTouchMissedKeystrokes = 0;

  const reactions: number[] = [];
  const touchReactions: number[] = [];

  keysStats.forEach((keyStats) => {
    numCorrectKeystrokes += keyStats.numCorrectKeystrokes;
    numWrongKeystrokes += keyStats.numWrongKeystrokes;
    numMissedKeystrokes += keyStats.numMissedKeystrokes;

    numTouchCorrectKeystrokes += keyStats.numTouchCorrectKeystrokes;
    numTouchWrongKeystrokes += keyStats.numTouchWrongKeystrokes;
    numTouchMissedKeystrokes += keyStats.numTouchMissedKeystrokes;

    reactions.push(...keyStats.reactions);
    touchReactions.push(...keyStats.touchReactions);
  });

  return {
    numCorrectKeystrokes: numCorrectKeystrokes + numTouchCorrectKeystrokes,
    numWrongKeystrokes: numWrongKeystrokes + numTouchWrongKeystrokes,
    numMissedKeystrokes: numMissedKeystrokes + numTouchMissedKeystrokes,
    accuracy:
      (numCorrectKeystrokes + numTouchCorrectKeystrokes) /
      (numCorrectKeystrokes +
        numWrongKeystrokes +
        numMissedKeystrokes +
        numTouchCorrectKeystrokes +
        numTouchWrongKeystrokes +
        numTouchMissedKeystrokes),
    averageReaction: average(
      [...reactions, ...touchReactions].filter((value) => !!value)
    ),
  };
}

export default getStatsFromKeysStats;
