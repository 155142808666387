import Box from '@mui/material/Box';
import { ReactNode } from 'react';

interface Props {
  x: number;
  y: number;
  children: ReactNode;
}

const Rect = ({ x, y, children }: Props) => {
  return (
    <Box component="svg" x={x} y={y}>
      {children}
    </Box>
  );
};

export default Rect;
