import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';

interface Props {
  name: string;
  value: string | number;
}

const StatView: FC<Props> = ({ name, value }) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        width: '100px',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" color="textPrimary">
        {value}
      </Typography>

      <Divider variant="middle" flexItem sx={{ margin: '8px 0' }} />

      <Typography variant="body2" color="textSecondary">
        {name}
      </Typography>
    </Box>
  );
};

export default memo(StatView);
