import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ansiKeyStyles from 'components/KeyboardStyles/ansiKeyStyles';
import isoKeyStyles from 'components/KeyboardStyles/isoKeyStyles';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { FC, memo, useCallback } from 'react';

const colors = {
  0: '#f4b3c2',
  1: '#fdcf8c',
  2: '#bcdd9c',
  3: '#a3dce0',
  4: '#aec6e0',
  5: '#aec6e0',
  6: '#a3dce0',
  7: '#bcdd9c',
  8: '#fdcf8c',
  9: '#f4b3c2',
};

interface Props extends CheckboxProps {
  variant: string;
  homing?: boolean;

  checked: boolean;
  label: string;
  showKey: boolean;
  physicalLayout: PhysicalLayout;
  reaction?: number;
  error?: boolean;
}

const CheckboxKey: FC<Props> = ({
  variant,
  homing,

  label,
  checked,
  showKey,
  physicalLayout,
  reaction,
  error,
  ...props
}) => {
  const getKeyStyle = useCallback(
    (physicalLayout: string, variant: string) => {
      let keyStyles = null;
      switch (physicalLayout) {
        case 'ansi':
          keyStyles = ansiKeyStyles;
          break;
        case 'iso':
          keyStyles = isoKeyStyles;
          break;
        default:
          break;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return keyStyles[variant] ?? keyStyles['key'];
    },
    [physicalLayout, variant]
  );

  return (
    <FormControlLabel
      labelPlacement="start"
      sx={{
        ...getKeyStyle(physicalLayout, variant),
        visibility: showKey ? 'initial' : 'hidden',
        '&.MuiFormControlLabel-root': {
          fontFamily: 'inherit',
          position: 'relative',
          display: 'flex',
          fontSize: 'inherit',
          boxSizing: 'border-box',

          padding: '1% 0.6em',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          // fontSize: '1vw',
          // visibility: showKey ? 'initial' : 'hidden',
          // backgroundColor: colors[typingZone],
          ':before': homing
            ? {
                content: '""',
                position: 'absolute',
                bottom: '10%',
                right: 'calc(50%+5px)',
                height: '5%',
                width: '25%',
                borderRadius: '1em',
                backgroundColor: '#7C7C7C',
              }
            : {},
          color: '#222',
          background: '#efefee',

          borderWidth: checked ? '2px' : '1px',
          borderStyle: 'solid',
          borderColor: checked ? '#0B72B9' : '#808080',
          borderRadius: '0.2em',
          flexDirection: '',
          minHeight: '48px',
          margin: 0,
          backgroundColor: checked ? '#efefee' : '#ddd',
          '> .MuiCheckbox-root': {
            display: 'none',
          },
          ':after': {
            content: reaction ? `"${reaction}"` : "''",
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            color: 'green',
            fontSize: '0.6em',
            bottom: 0,
            width: '100%',
            height: reaction
              ? `${100 - (reaction * 100) / 2000}%`
              : error
              ? '100%'
              : 0,
            backgroundColor: reaction
              ? 'rgba(000,187,045, 0.3)'
              : error
              ? 'rgba(201,060,032, 0.3)'
              : 'initial',
            position: 'absolute',
          },
        },
        '& .MuiFormControlLabel-label': {
          alignSelf: 'center',
          color: checked ? '#000' : '#808080',
          fontSize: '1rem',
        },
      }}
      control={
        <Checkbox
          disableRipple
          size="small"
          sx={{
            '&.MuiCheckbox-root': { padding: 0, order: 2, alignSelf: 'end' },
          }}
          {...props}
        />
      }
      label={label}
    />
  );
};

export default memo(CheckboxKey);
