import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import { push } from 'connected-react-router';
import { authenticate, startFetching, stopFetching } from 'domains/auth/slice';

import { enqueueSnackbar } from 'domains/notifier/slice';
import { apply, put } from 'redux-saga/effects';

import { setUser } from 'domains/user/slice';
import api from '../../../../REST/api';

function* register({ payload: { email, password } }) {
  try {
    yield put(startFetching());

    const response = yield apply(api, api.auth.register, [{ email, password }]);
    const { data: user, message } = yield apply(response, response.json);

    if (response.status !== 201) {
      throw new Error(message);
    }

    yield put(setUser(user));

    yield put(
      enqueueSnackbar(
        { message: message },
        {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: (key) => (
            <NotifierCloseButton label="Хорошо" notifierKey={key} />
          ),
          persist: true,
        }
      )
    );

    yield put(authenticate());
    yield put(push('/'));
  } catch (error) {
    // console.log({ error });
    yield put(
      enqueueSnackbar(
        { message: error.message },
        {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: (key) => <NotifierCloseButton notifierKey={key} />,
        }
      )
    );
  } finally {
    yield put(stopFetching());
  }
}

export default register;
