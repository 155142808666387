import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Breakpoint } from '@mui/system/createTheme';
import Catcher from 'components/Catcher';
import Settings, { OptionsType } from 'components/Demo/Settings';
import IntroDialog from 'components/IntroDialog';
import KeyboardLayoutLabel from 'components/KeyboardSetup/Label';
import LessonKeys from 'components/LessonList/LessonKeys';
import LessonResultDialog from 'components/LessonResultDialog';
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import Stats from 'components/Stats';
import StyledDialog from 'components/StyledDialog';
import Tutor from 'components/Tutor';
import { layouts } from 'domains/keyboard/layouts';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import getTypingZones from 'domains/keyboard/typingZones/getTypingZones';
import { LessonStats } from 'domains/lessons/models/LessonStats';
import { enqueueSnackbar } from 'domains/notifier/slice';
import { convertLogsToLessonStatsInfo } from 'domains/test/convertLogsToLessonStatsInfo';
import { Helmet } from 'react-helmet';
import {
  logicalLayoutSelector,
  optionsSelector,
  physicalLayoutSelector,
} from 'domains/user/selectors/settings';
import { updateOptions } from 'domains/user/slice';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks';
import useLesson from 'hooks/useLesson';
import useLocalStorage from 'hooks/useLocalStorage';
import * as ROUTES from 'navigation/routes';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { Link as LinkRouter } from 'react-router-dom';
import api from 'REST/api';
import { convertLogToLessonStatsData } from 'utils/convertLogToLessonStatsData';
import { number, object, string } from 'yup';

function valuetext(value: number) {
  return `${value}°C`;
}
const breakpoints = ['lg', 'xl'] as Breakpoint[];

export const defaultOptions: OptionsType = {
  taktTime: 2000,
  delayTime: 200,
  numControlChars: 'byCharsNum',
  entryLevel: 7,
};

interface Props {}

const LessonPage: FC<Props> = () => {
  const location = useLocation();
  const lessonId = location.pathname.split('/')[2];

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const physicalLayout: PhysicalLayout | null = useAppSelector(
    physicalLayoutSelector
  );
  const logicalLayout: LogicalLayout | null = useAppSelector(
    logicalLayoutSelector
  );

  const optionsFromStore: OptionsType | null = useAppSelector(optionsSelector);

  const [options, setOptions] = useState<OptionsType>({
    ...defaultOptions,
    ...optionsFromStore,
  });

  const [showRulesInLesson, setShowRulesInLesson] = useLocalStorage<boolean>(
    'showRulesInLesson',
    true
  );

  const [localSettingsExpanded, setLocalSettingsExpanded] =
    useLocalStorage<boolean>('settingsExpanded', false);

  const handleSettingsExpandClick = () => {
    setLocalSettingsExpanded(!localSettingsExpanded);
  };

  const [openIntroDialog, setOpenIntroDialog] = useState(showRulesInLesson);

  const handleIntroDialogOpen = () => {
    setOpenIntroDialog(true);
  };

  const handleIntroDialogClose = () => {
    setShowRulesInLesson(false);
    setOpenIntroDialog(false);
  };

  const validationSchema = object().shape({
    numControlChars: string(),
    entryLevel: number().positive().integer(),
    taktTime: number().positive().integer(),
    delayTime: number().positive().integer(),
  });

  const formik = useFormik({
    initialValues: options,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values) => {
      // console.log('SUBMIT OPTIONS: ', { values });
      handleOptionChange(values);
    },
    enableReinitialize: true,
  });

  function handleNumControlCharsChange(
    event: SelectChangeEvent<number | 'byCharsNum'>
  ): void {
    const { target } = event;
    formik.setFieldValue('numControlChars', target.value);
    formik.submitForm();
  }

  function handleDelayTimeChange(event: SelectChangeEvent<number>): void {
    const { target } = event;
    formik.setFieldValue('delayTime', target.value);
    formik.submitForm();
  }

  function handleEntryLevelChange(event: SelectChangeEvent<number>): void {
    const { target } = event;
    formik.setFieldValue('entryLevel', target.value);
    formik.submitForm();
  }

  function handleTaktTimeChange(event: SelectChangeEvent<number>): void {
    const { target } = event;
    formik.setFieldValue('taktTime', target.value);
    formik.submitForm();
  }

  const {
    isLoading,
    isError,
    error,
    data: lesson,
  } = useQuery(
    ['nickname', lessonId],
    (params) => {
      const [_key, lessonId] = params.queryKey;
      // console.log({ lessonId, logicalLayout, physicalLayout });
      return api.lesson.fetch(lessonId);
    },
    {
      onSuccess: (data) => {
        // console.log('UNIQUE SUCCESS', data);
      },
      onError: (error) => {
        dispatch(
          enqueueSnackbar(
            { message: error.message },
            {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              action: (key) => <NotifierCloseButton notifierKey={key} />,
            }
          )
        );
      },
      enabled: !!lessonId,
    }
  );

  const typingZones = useMemo(
    () => getTypingZones(physicalLayout),
    [physicalLayout]
  );

  const {
    pressedKeys,
    isBlindly,
    progress1,
    progress2,
    logs,
    isFinished,
    isStarted,
    isPaused,
    isFocused,
    testedKey,
    reset,
    handleKeyPress,
    handleKeyUp,
    handleKeyDown,
    handleFocus,
    handleBlur,
  } = useLesson(
    lesson?.chars ?? [],

    // [layout?.physical].filter(
    //     (key) =>
    //       !excludedChars?.[layout?.logical].find(
    //         (excludedKey) =>
    //           excludedKey &&
    //           excludedKey.code === key.code &&
    //           excludedKey.shiftKey === key.shiftKey
    //       )
    //   )
    // : []) ?? [],
    {
      layoutId: lesson?.logicalLayout,
      algorithm: lesson?.type,
      ...options,
    }
  ); // TODO: мержить пальцы пользователя, чтобы получить set?

  const [open, setOpen] = useState(false);
  const [tutorSizeCursor, setTutorSizeCursor] = useLocalStorage<number>(
    'tutorSizeCursor',
    0
  );
  const [statsInfo, setStatsInfo] = useState<LessonStats>();

  const handleTutorSizeChange = () => {
    setTutorSizeCursor(
      tutorSizeCursor + 1 > breakpoints.length - 1 ? 0 : tutorSizeCursor + 1
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    if (isFinished) {
      const lessonStats: LessonStats = convertLogsToLessonStatsInfo(
        lesson,
        logs,
        {
          delayTime: options.delayTime,
          taktTime: options.taktTime,
        },
        lesson?.logicalLayout,
        physicalLayout
      );
      sendStatMutation(lessonStats);
      setStatsInfo(lessonStats);
      handleOpen();
    }
  }, [isFinished]);

  const { mutate: updateOptionsMutate, isLoading: updateOptionsLoading } =
    useMutation(api.settings.updateOptions, {
      onSuccess: (_data, variables) => {
        dispatch(updateOptions(variables));
      },
      onError: (error) => {
        enqueueSnackbar(
          { message: error.message },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        );
      },
    });

  // отправка статистики
  const { mutate: sendStatMutation, isLoading: sendLessonStatIsLoading } =
    useMutation(api.lesson.sendLessonStats, {
      onSuccess: () => {
        // Инвалидация и обновление
        // console.log('LESSON STATS SUCCESS');
      },
      onError: (error) => {
        dispatch(
          enqueueSnackbar(
            { message: error.message },
            {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              action: (key) => <NotifierCloseButton notifierKey={key} />,
            }
          )
        );
      },
    });

  function handleOptionChange(values: OptionsType) {
    setOptions(values);
    updateOptionsMutate(values);
  }

  return (
    <Catcher>
      <Helmet>
        <title>Урок {lessonId} - keycap.pro</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        {isLoading ? (
          <Container maxWidth="md" sx={{ mb: 0 }}>
            <Box
              sx={{
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // flexWrap: 'nowrap',
                textAlign: 'center',
                margin: 'auto',
                mt: 20,
                width: '100%',
              }}
            >
              <Typography gutterBottom>Загрузка урока...</Typography>
              <LinearProgress />
            </Box>
          </Container>
        ) : !isError ? (
          <>
            <Container maxWidth="md" sx={{ mb: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Box sx={{}}>
                  <Breadcrumbs
                    // separator={<NavigateNextIcon fontSize="small" />}
                    separator="/"
                    aria-label="breadcrumb"
                    sx={{ mb: 0.25 }}
                  >
                    <Link
                      underline="hover"
                      key="1"
                      color="inherit"
                      component={LinkRouter}
                      variant="caption"
                      to={`${ROUTES.LESSONS}`}
                    >
                      {t(`exercises`).toLowerCase()}
                    </Link>
                    <Link
                      underline="hover"
                      key="2"
                      color="inherit"
                      component={LinkRouter}
                      variant="caption"
                      to={`${ROUTES.LESSONS}#${lesson?.type}`}
                    >
                      {t(`${lesson?.type}`).toLowerCase()}
                    </Link>
                    <Typography
                      key="3"
                      component="span"
                      variant="caption"
                      color="text.primary"
                    >
                      {lesson?.type !== 'custom' && lesson?.type !== 'test'
                        ? t(`lessonTitles.${lesson?.title}`)
                        : lesson?.title}
                    </Typography>
                  </Breadcrumbs>
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Typography
                      component="h2"
                      variant="h4"
                      sx={{ fontWeight: 400 }}
                      color="text.primary"
                      gutterBottom
                    >
                      {lesson?.type !== 'custom' && lesson?.type !== 'test'
                        ? t(`lessonTitles.${lesson?.title}`)
                        : lesson?.title}
                    </Typography>
                  </Box>
                </Box>

                <KeyboardLayoutLabel
                  logicalLayout={lesson?.logicalLayout}
                  physicalLayout={physicalLayout}
                  emulated={
                    lesson?.logicalLayout
                      ? layouts[lesson?.logicalLayout].emulated
                      : null
                  }
                  showLabel={false}
                />
              </Box>

              <Box sx={{ display: 'inline-flex', alignItems: 'center', mb: 2 }}>
                <LessonKeys
                  keys={lesson?.chars ?? []}
                  excludedKeys={
                    []
                    // logicalLayout ? excludedChars?.[logicalLayout] : null
                  }
                  loading={isLoading}
                />
              </Box>

              <Settings
                entryLevel={formik.values.entryLevel}
                expanded={localSettingsExpanded}
                disabled={isStarted}
                sizeCursor={tutorSizeCursor}
                onReset={reset}
                onSettingsExpandClick={handleSettingsExpandClick}
                onTutorSizeChange={handleTutorSizeChange}
                onIntroDialogOpen={handleIntroDialogOpen}
                numControlChars={formik.values.numControlChars}
                delayTime={formik.values.delayTime}
                taktTime={formik.values.taktTime}
                onTaktTimeChange={handleTaktTimeChange}
                onDelayTimeChange={handleDelayTimeChange}
                onEntryLevelChange={handleEntryLevelChange}
                onNumControlCharsChange={handleNumControlCharsChange}
                onResetOptions={() => handleOptionChange(defaultOptions)}
              />
            </Container>
            <Container maxWidth={breakpoints[tutorSizeCursor]} sx={{ mb: 8 }}>
              <Tutor
                pressedKeys={pressedKeys}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                isStarted={isStarted}
                isPaused={isPaused}
                isFocused={isFocused}
                testedKey={testedKey}
                physicalLayout={physicalLayout}
                logicalLayout={lesson?.logicalLayout}
                typingZones={typingZones}
                reset={reset}
                onKeyPress={handleKeyPress}
                onFocus={handleFocus}
                onBlur={handleBlur}
                progress1={progress1}
                progress2={progress2}
                isBlindly={isBlindly}
              />
            </Container>

            <Stats {...convertLogToLessonStatsData(logs)} />
          </>
        ) : (
          <Container maxWidth="md" sx={{ mb: 0 }}>
            <Box
              sx={{
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // flexWrap: 'nowrap',
                textAlign: 'center',
                margin: 'auto',
                // mt: 20,
                width: '100%',
              }}
            >
              <Alert severity="error">Не удалось загузить урок.</Alert>
            </Box>
          </Container>
        )}
      </Container>

      <StyledDialog
        onClose={handleClose}
        aria-labelledby="test-result-dialog"
        open={open}
        onSubmit={handleClose}
        maxWidth="xs"
        fullWidth
      >
        <LessonResultDialog
          onClose={handleClose}
          onSubmit={handleClose}
          statsInfo={statsInfo}
        />
      </StyledDialog>

      <IntroDialog open={openIntroDialog} onClose={handleIntroDialogClose} />
    </Catcher>
  );
};

export default LessonPage;
