export default function byField(
  field: string,
  orderBy: 'asc' | 'desc' = 'asc'
) {
  return (a, b) =>
    a[field] > b[field]
      ? orderBy === 'asc'
        ? 1
        : -1
      : orderBy === 'asc'
      ? -1
      : 1;
}
