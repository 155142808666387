import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

interface Props extends SelectProps<LogicalLayout> {
  options: LogicalLayout[];
}

const LogicalLayoutSelect: FC<Props> = ({ label, options, ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="layout-select-label">{label}</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          label={label}
          size="small"
          fullWidth
          {...props}
        >
          {options?.map((layout) => (
            <MenuItem key={layout} value={layout}>
              {t(`logicalLayouts.${layout}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default LogicalLayoutSelect;
