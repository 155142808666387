const ansiLayout = [
  [
    { id: 'Backquote', width: 40 },
    { id: 'Digit1', width: 40 },
    { id: 'Digit2', width: 40 },
    { id: 'Digit3', width: 40 },
    { id: 'Digit4', width: 40 },
    { id: 'Digit5', width: 40 },
    { id: 'Digit6', width: 40 },
    { id: 'Digit7', width: 40 },
    { id: 'Digit8', width: 40 },
    { id: 'Digit9', width: 40 },
    { id: 'Digit0', width: 40 },
    { id: 'Minus', width: 40 },
    { id: 'Equal', width: 40 },
    { id: 'Backspace', width: 83 },
  ],
  [
    { id: 'Tab', width: 63 },
    { id: 'KeyQ', width: 40 },
    { id: 'KeyW', width: 40 },
    { id: 'KeyE', width: 40 },
    { id: 'KeyR', width: 40 },
    { id: 'KeyT', width: 40 },
    { id: 'KeyY', width: 40 },
    { id: 'KeyU', width: 40 },
    { id: 'KeyI', width: 40 },
    { id: 'KeyO', width: 40 },
    { id: 'KeyP', width: 40 },
    { id: 'BracketLeft', width: 40 },
    { id: 'BracketRight', width: 40 },
    { id: 'Backslash', width: 60 },
  ],
  [
    { id: 'CapsLock', width: 70 },
    { id: 'KeyA', width: 40 },
    { id: 'KeyS', width: 40 },
    { id: 'KeyD', width: 40 },
    { id: 'KeyF', width: 40 },
    { id: 'KeyG', width: 40 },
    { id: 'KeyH', width: 40 },
    { id: 'KeyJ', width: 40 },
    { id: 'KeyK', width: 40 },
    { id: 'KeyL', width: 40 },
    { id: 'Semicolon', width: 40 },
    { id: 'Quote', width: 40 },
    { id: 'Enter', width: 95 },
  ],
  [
    { id: 'ShiftLeft', width: 92 },
    // { id: 'IntlBackslash',   width: 40,  },
    { id: 'KeyZ', width: 40 },
    { id: 'KeyX', width: 40 },
    { id: 'KeyC', width: 40 },
    { id: 'KeyV', width: 40 },
    { id: 'KeyB', width: 40 },
    { id: 'KeyN', width: 40 },
    { id: 'KeyM', width: 40 },
    { id: 'Comma', width: 40 },
    { id: 'Period', width: 40 },
    { id: 'Slash', width: 40 },
    { id: 'ShiftRight', width: 115 },
  ],
  [
    { id: 'ControlLeft', width: 63 },
    // { id: 'MetaLeft',   width: 115,  },
    { id: 'AltLeft', width: 63 },
    { id: 'Space', width: 369 },
    { id: 'AltRight', width: 63 },
    // { id: 'MetaRight',   width: 115,  },
    // { id: 'ContextMenu',   width: 115,  },
    { id: 'ControlRight', width: 63 },
  ],
];

export default ansiLayout;
