import { useTheme } from '@emotion/react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logout from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import GlobalStyles from '@mui/material/GlobalStyles';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Logo from 'components/icons/Logo';
import authActions from 'domains/auth/actions';
import { displayNameSelector } from 'domains/user/selectors/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import * as ROUTES from 'navigation/routes';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface Props {}

const Header: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const theme = useTheme();

  // console.log(location);

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const displayName = useAppSelector(displayNameSelector);

  const userProvider = useAppSelector((state) => state.user.provider);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorProjectMenuEl, setAnchorProjectMenuEl] =
    useState<null | HTMLElement>(null);
  const openProjectMenu = Boolean(anchorProjectMenuEl);

  const handleProjectMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorProjectMenuEl(event.currentTarget);
  };

  const handleProjectMenuClose = () => {
    setAnchorProjectMenuEl(null);
  };

  const isActive = useCallback(
    (location, path) => {
      return location.pathname === path ||
        `/${location.pathname.split('/')[1]}` === path
        ? { color: theme.palette.primary.main }
        : { color: theme.palette.text.secondary };
    },
    [location]
  );

  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
      />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                flexDirection: 'row',
                // gap: '35px',
                flexGrow: 1,
              }}
            >
              <Logo fontSize="large" sx={{ fontSize: '2.7rem', mb: '2px' }} />
              <Button
                // component={Link}
                // to={ROUTES.HOME}
                variant="text"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textTransform: 'none',
                  '&.MuiButton-root:hover': {
                    backgroundColor: 'transparent',
                  },
                  mr: '35px',
                }}
                disableElevation
                disableFocusRipple
                disableTouchRipple
                disableRipple
                aria-owns={openProjectMenu ? 'project-menu' : undefined}
                aria-controls={openProjectMenu ? 'project-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openProjectMenu ? 'true' : undefined}
                onClick={handleProjectMenuClick}
                onMouseOver={handleProjectMenuClick}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {`${t('root_domain')}.`}
                  </Typography>
                  <Typography variant="h6" color="primary" noWrap>
                    {`${t('tld')}`}
                  </Typography>
                </Box>
                {/* <Arrow opened={openProjectMenu} /> */}
                <KeyboardArrowDownIcon
                  sx={{
                    position: 'absolute',
                    bottom: '-12%',
                    // transition: 'all 0.25s cubic-bezier(0.25, 1.7, 0.35, 0.8)',
                    transform: openProjectMenu ? 'rotateZ(-180deg)' : '',
                    transition: 'transform 500ms ease !important',
                  }}
                  color="inherit"
                  fontSize="small"
                />
              </Button>
              <Menu
                anchorEl={anchorProjectMenuEl}
                id="project-menu"
                open={openProjectMenu}
                onClose={handleProjectMenuClose}
                onClick={handleProjectMenuClose}
                MenuListProps={{ onMouseLeave: handleProjectMenuClose }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

                    mt: 1.5,
                    '& .MuiList-root': {
                      // backgroundColor: theme.palette.primary.main,
                      // color: '#ffffff',

                      padding: 0,
                    },
                    '& .MuiMenuItem-root': {
                      justifyContent: 'center',
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              >
                <MenuItem
                  component={MuiLink}
                  href={ROUTES.DONATION}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    '&.MuiMenuItem-root': {
                      backgroundColor: theme.palette.primary.main,
                      color: '#ffffff',
                    },
                  }}
                  onClick={() => {
                    if (window.ym) {
                      window.ym(90304960, 'reachGoal', 'CLICK_ON_DONATE');
                    }
                    return true;
                  }}
                >
                  {t('donate')}
                </MenuItem>
                {/* <MenuItem component={Link} to={ROUTES.ACCOUNT}>
                  {t('feedback')}
                </MenuItem> */}

                <Box
                  display="flex"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 0.5,
                  }}
                >
                  <Typography align="center" variant="caption">
                    v{process.env.REACT_APP_VERSION}
                  </Typography>
                </Box>
              </Menu>
              <nav>
                {isAuthenticated ? (
                  <>
                    <Button
                      component={Link}
                      to={ROUTES.HOME}
                      variant="text"
                      sx={{
                        textTransform: 'none',
                        ...isActive(location, ROUTES.HOME),
                      }}
                    >
                      {t('home').toLowerCase()}
                    </Button>
                    <Button
                      component={Link}
                      to={ROUTES.LESSONS}
                      variant="text"
                      sx={{
                        textTransform: 'none',
                        ...isActive(location, ROUTES.LESSONS),
                      }}
                    >
                      {t('exercises').toLowerCase()}
                    </Button>
                    <Button
                      component={Link}
                      to={ROUTES.TEST}
                      variant="text"
                      sx={{
                        textTransform: 'none',
                        ...isActive(location, ROUTES.TEST),
                      }}
                    >
                      {t('testing').toLowerCase()}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      component={Link}
                      to={ROUTES.HOME}
                      variant="text"
                      sx={{
                        textTransform: 'none',
                        ...isActive(location, ROUTES.HOME),
                      }}
                    >
                      {t('home').toLowerCase()}
                    </Button>
                  </>
                )}
              </nav>
            </Box>

            {isAuthenticated ? (
              <>
                <div>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontWeight: 500 }}
                    >
                      {displayName}
                    </Typography>
                    <Tooltip title={t('account_settings')}>
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 0.75 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                      >
                        <Avatar sx={{ width: 32, height: 32 }}>
                          <AccountCircle sx={{ width: 32, height: 32 }} />
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {!userProvider && [
                      <MenuItem
                        key="item"
                        component={Link}
                        to={ROUTES.ACCOUNT}
                        selected={location.pathname === ROUTES.ACCOUNT}
                      >
                        <Avatar /> {t('my_account')}
                      </MenuItem>,
                      <Divider key="divider" />,
                    ]}
                    <MenuItem
                      onClick={() => {
                        dispatch(authActions.logoutAsync());
                      }}
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      {t('log_out')}
                    </MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <ButtonGroup
                disableElevation
                size="small"
                aria-label="signin/register area"
                sx={{
                  ml: 1.5,
                }}
              >
                <Button
                  component={Link}
                  to={ROUTES.LOGIN}
                  variant="outlined"
                  sx={{
                    ...isActive(location, ROUTES.LOGIN),
                    '&.MuiButton-root:hover': {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {t('log_in')}
                </Button>
                <Button
                  component={Link}
                  to={ROUTES.SIGNUP}
                  variant="outlined"
                  sx={{
                    ...isActive(location, ROUTES.SIGNUP),
                    '&.MuiButton-root:hover': {
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  {t('signup')}
                </Button>
              </ButtonGroup>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
