import { createSlice } from '@reduxjs/toolkit';

type authSliceState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isFetching: boolean;
};

const initialState: authSliceState = {
  isAuthenticated: false,
  isInitialized: false,
  isFetching: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startFetching: (state) => {
      state.isFetching = true;
    },
    stopFetching: (state) => {
      state.isFetching = false;
    },
    authenticate: (state) => {
      state.isAuthenticated = true;
    },
    initialize: (state) => {
      state.isInitialized = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
});

export const { startFetching, stopFetching, authenticate, initialize, logout } =
  authSlice.actions;

export default authSlice.reducer;
