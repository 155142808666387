import Box from '@mui/material/Box';
import Text from 'components/SVGKeyboard/Text';
import { memo } from 'react';

interface KeyProps {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  lowerGlyph: string;
  fill?: string;
  fontColor?: string;
  isVisible?: boolean;
}

function IsoEnter({
  id,
  x,
  y,
  width,
  height,
  fill = '#efefee',
  lowerGlyph,
  fontColor = '#22211f',
  isVisible = true,
}: KeyProps): JSX.Element {
  return (
    <Box id={id} component="svg" x={x} y={y}>
      <Box component="path" d="m 0,0 0,40 7,0 0,42 53,0 0,-82 z" fill={fill} />
      {isVisible && (
        <Text
          // x="50%"
          // y="50%"
          x={12}
          y={height / 2 + 2}
          dominantBaseline="middle"
          // textAnchor="middle"
          fill={fontColor}
          textRendering="optimizeLegibility"
          sx={{ fontSize: '10px', cursor: 'default', userSelect: 'none' }}
        >
          {lowerGlyph}
        </Text>
      )}
    </Box>
  );
}

export default memo(IsoEnter);
