import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

interface Props extends SvgIconProps {}

const GitHubIcon: FC<Props> = ({
  width = '48px',
  height = '48px',
  ...props
}) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      {...props}
      fill="none"
      viewBox="0 0 64 64"
    >
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 64 64"
      >
    </svg> */}

      <path d="M32 10c12.15 0 22 9.85 22 22 0 9.768-6.369 18.045-15.179 20.916l.006-.021s-1.485-.696-1.453-1.938c.035-1.367 0-4.556 0-5.727 0-2.01-1.272-3.434-1.272-3.434s9.977.112 9.977-10.533c0-4.107-2.147-6.245-2.147-6.245s1.128-4.385-.39-6.245c-1.701-.184-4.749 1.626-6.05 2.472 0 0-2.062-.846-5.492-.846s-5.492.846-5.492.846c-1.301-.846-4.348-2.656-6.05-2.472-1.518 1.86-.39 6.245-.39 6.245s-2.147 2.137-2.147 6.245c0 10.645 9.977 10.533 9.977 10.533s-1.005 1.136-1.225 2.806c-.696.236-1.721.528-2.549.528-2.165 0-3.812-2.105-4.416-3.078-.595-.96-1.815-1.766-2.953-1.766-.749 0-1.115.375-1.115.803s1.05.727 1.743 1.521c1.461 1.674 1.435 5.438 6.641 5.438.565 0 1.719-.139 2.588-.256-.005 1.185-.007 2.436.012 3.167.031 1.242-1.453 1.938-1.453 1.938l.006.021C16.369 50.045 10 41.768 10 32c0-12.15 9.85-22 22-22z"></path>
      {/* <path d="M25 8C15.611 8 8 15.611 8 25s7.611 17 17 17 17-7.611 17-17S34.389 8 25 8zm0 1c8.837 0 16 7.163 16 16 0 7.316-4.915 13.472-11.62 15.379a1.248 1.248 0 01-.228-.729c.027-1.057 0-3.52 0-4.425 0-1.553-.982-2.655-.982-2.655s7.709.088 7.709-8.138c0-3.174-1.656-4.827-1.656-4.827s.87-3.389-.303-4.826c-1.315-.142-3.67 1.257-4.676 1.91 0 0-1.591-.652-4.242-.652s-4.244.652-4.244.652c-1.006-.653-3.361-2.052-4.676-1.91-1.173 1.437-.303 4.826-.303 4.826s-1.658 1.65-1.658 4.825c0 8.226 7.71 8.138 7.71 8.138s-.777.875-.948 2.162c-.532.189-1.267.416-1.969.416-1.673 0-2.944-1.626-3.41-2.378-.46-.742-1.402-1.366-2.281-1.366-.58 0-.864.29-.864.621s.812.561 1.348 1.174c1.13 1.293 1.11 4.203 5.133 4.203.474 0 1.325-.107 2.008-.2-.005.943-.015 1.864 0 2.448.007.283-.092.524-.229.73C13.915 38.473 9 32.317 9 25c0-8.837 7.163-16 16-16z"></path> */}
    </SvgIcon>
  );
};
export default GitHubIcon;
