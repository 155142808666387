/**
 * https://w3c.github.io/uievents-code/#code-value-tables
 */

export const jcuken = {
  Backquote: {
    defaultKey: { key: 'ё', type: 'letter' },
    shiftKey: { key: 'Ё', type: 'letter' },
  },
  Digit1: {
    defaultKey: { key: '1', type: 'digit' },
    shiftKey: { key: '!', type: 'symbol' },
  },
  Digit2: {
    defaultKey: { key: '2', type: 'digit' },
    shiftKey: { key: '"', type: 'symbol' },
  },
  Digit3: {
    defaultKey: { key: '3', type: 'digit' },
    shiftKey: { key: '№', type: 'symbol' },
  },
  Digit4: {
    defaultKey: { key: '4', type: 'digit' },
    shiftKey: { key: ';', type: 'symbol' },
  },
  Digit5: {
    defaultKey: { key: '5', type: 'digit' },
    shiftKey: { key: '%', type: 'symbol' },
  },
  Digit6: {
    defaultKey: { key: '6', type: 'digit' },
    shiftKey: { key: ':', type: 'symbol' },
  },
  Digit7: {
    defaultKey: { key: '7', type: 'digit' },
    shiftKey: { key: '?', type: 'symbol' },
  },
  Digit8: {
    defaultKey: { key: '8', type: 'digit' },
    shiftKey: { key: '*', type: 'symbol' },
  },
  Digit9: {
    defaultKey: { key: '9', type: 'digit' },
    shiftKey: { key: '(', type: 'symbol' },
  },
  Digit0: {
    defaultKey: { key: '0', type: 'digit' },
    shiftKey: { key: ')', type: 'symbol' },
  },
  Minus: {
    defaultKey: { key: '-', type: 'symbol' },
    shiftKey: { key: '_', type: 'symbol' },
  },
  Equal: {
    defaultKey: { key: '=', type: 'symbol' },
    shiftKey: { key: '+', type: 'symbol' },
  },
  Backspace: { defaultKey: { key: 'Backspace', type: 'sys' } },
  Tab: { defaultKey: { key: 'Tab', type: 'sys' } },
  KeyQ: {
    defaultKey: { key: 'й', type: 'letter' },
    shiftKey: { key: 'Й', type: 'letter' },
  },
  KeyW: {
    defaultKey: { key: 'ц', type: 'letter' },
    shiftKey: { key: 'Ц', type: 'letter' },
  },
  KeyE: {
    defaultKey: { key: 'у', type: 'letter' },
    shiftKey: { key: 'У', type: 'letter' },
  },
  KeyR: {
    defaultKey: { key: 'к', type: 'letter' },
    shiftKey: { key: 'К', type: 'letter' },
  },
  KeyT: {
    defaultKey: { key: 'е', type: 'letter' },
    shiftKey: { key: 'Е', type: 'letter' },
  },
  KeyY: {
    defaultKey: { key: 'н', type: 'letter' },
    shiftKey: { key: 'Н', type: 'letter' },
  },
  KeyU: {
    defaultKey: { key: 'г', type: 'letter' },
    shiftKey: { key: 'Г', type: 'letter' },
  },
  KeyI: {
    defaultKey: { key: 'ш', type: 'letter' },
    shiftKey: { key: 'Ш', type: 'letter' },
  },
  KeyO: {
    defaultKey: { key: 'щ', type: 'letter' },
    shiftKey: { key: 'Щ', type: 'letter' },
  },
  KeyP: {
    defaultKey: { key: 'з', type: 'letter' },
    shiftKey: { key: 'З', type: 'letter' },
  },
  BracketLeft: {
    defaultKey: { key: 'х', type: 'letter' },
    shiftKey: { key: 'Х', type: 'letter' },
  },
  BracketRight: {
    defaultKey: { key: 'ъ', type: 'letter' },
    shiftKey: { key: 'Ъ', type: 'letter' },
  },
  Backslash: {
    defaultKey: { key: '\\', type: 'symbol' },
    shiftKey: { key: '/', type: 'symbol' },
    alternate: 'IntlBackslash',
  },
  CapsLock: { defaultKey: { key: 'CapsLock', type: 'sys' } },
  KeyA: {
    defaultKey: { key: 'ф', type: 'letter' },
    shiftKey: { key: 'Ф', type: 'letter' },
  },
  KeyS: {
    defaultKey: { key: 'ы', type: 'letter' },
    shiftKey: { key: 'Ы', type: 'letter' },
  },
  KeyD: {
    defaultKey: { key: 'в', type: 'letter' },
    shiftKey: { key: 'В', type: 'letter' },
  },
  KeyF: {
    defaultKey: { key: 'а', type: 'letter' },
    shiftKey: { key: 'А', type: 'letter' },
  },
  KeyG: {
    defaultKey: { key: 'п', type: 'letter' },
    shiftKey: { key: 'П', type: 'letter' },
  },
  KeyH: {
    defaultKey: { key: 'р', type: 'letter' },
    shiftKey: { key: 'Р', type: 'letter' },
  },
  KeyJ: {
    defaultKey: { key: 'о', type: 'letter' },
    shiftKey: { key: 'О', type: 'letter' },
  },
  KeyK: {
    defaultKey: { key: 'л', type: 'letter' },
    shiftKey: { key: 'Л', type: 'letter' },
  },
  KeyL: {
    defaultKey: { key: 'д', type: 'letter' },
    shiftKey: { key: 'Д', type: 'letter' },
  },
  Semicolon: {
    defaultKey: { key: 'ж', type: 'letter' },
    shiftKey: { key: 'Ж', type: 'letter' },
  },
  Quote: {
    defaultKey: { key: 'э', type: 'letter' },
    shiftKey: { key: 'Э', type: 'letter' },
  },
  Enter: { defaultKey: { key: 'Enter', type: 'sys' } },
  ShiftLeft: { defaultKey: { key: 'Shift', type: 'sys' } },
  IntlBackslash: {
    defaultKey: { key: '\\', type: 'symbol' },
    shiftKey: { key: '/', type: 'symbol' },
    alternate: 'Backslash',
  },
  KeyZ: {
    defaultKey: { key: 'я', type: 'letter' },
    shiftKey: { key: 'Я', type: 'letter' },
  },
  KeyX: {
    defaultKey: { key: 'ч', type: 'letter' },
    shiftKey: { key: 'Ч', type: 'letter' },
  },
  KeyC: {
    defaultKey: { key: 'с', type: 'letter' },
    shiftKey: { key: 'С', type: 'letter' },
  },
  KeyV: {
    defaultKey: { key: 'м', type: 'letter' },
    shiftKey: { key: 'М', type: 'letter' },
  },
  KeyB: {
    defaultKey: { key: 'и', type: 'letter' },
    shiftKey: { key: 'И', type: 'letter' },
  },
  KeyN: {
    defaultKey: { key: 'т', type: 'letter' },
    shiftKey: { key: 'Т', type: 'letter' },
  },
  KeyM: {
    defaultKey: { key: 'ь', type: 'letter' },
    shiftKey: { key: 'Ь', type: 'letter' },
  },
  Comma: {
    defaultKey: { key: 'б', type: 'letter' },
    shiftKey: { key: 'Б', type: 'letter' },
  },
  Period: {
    defaultKey: { key: 'ю', type: 'letter' },
    shiftKey: { key: 'Ю', type: 'letter' },
  },
  Slash: {
    defaultKey: { key: '.', type: 'symbol' },
    shiftKey: { key: ',', type: 'symbol' },
  },
  ShiftRight: { defaultKey: { key: 'Shift', type: 'sys' } },
  ControlLeft: { defaultKey: { key: 'Ctrl', type: 'sys' } },
  MetaLeft: { defaultKey: { key: '', type: 'sys' } },
  AltLeft: { defaultKey: { key: 'Alt', type: 'sys' } },
  Space: { defaultKey: { key: '', type: 'sys' } },
  AltRight: { defaultKey: { key: 'Alt', type: 'sys' } },
  MetaRight: { defaultKey: { key: 'Meta', type: 'sys' } },
  ContextMenu: { defaultKey: { key: 'ContextMenu', type: 'sys' } },
  ControlRight: { defaultKey: { key: 'Ctrl', type: 'sys' } },
};
