import Box from '@mui/material/Box';
import Rect from 'components/SVGKeyboard/Rect';
import Text from 'components/SVGKeyboard/Text';
import { memo } from 'react';

interface KeyProps {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  glyph?: string;
  homing?: boolean;
  fill?: string;
  fontColor?: string;
  isVisible: boolean;
  isWrong: boolean;
  isModifierKey: boolean;
}

function Key({
  id,
  x,
  y,
  width,
  height,
  fill = '#efefee',
  glyph,
  homing = false,
  fontColor = '#22211f',
  isVisible,
  isWrong,
  isModifierKey,
}: KeyProps): JSX.Element {
  return (
    <Box id={id} component="svg" x={x} y={y} overflow="visible">
      {isModifierKey && (
        <defs>
          <pattern
            id="newpattern"
            x="0"
            y="0"
            width="6"
            height="10"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
            opacity="0.7"
          >
            <g fillOpacity="1">
              <rect
                x="0"
                y="0"
                width="3"
                height="10"
                fill="rgba(1, 50, 32, 0.7)"
              />
              <rect x="10" y="10" width="2" height="10" fill="#efefef" />
            </g>
          </pattern>
        </defs>
      )}
      <Rect
        x={0}
        y={0}
        rx={2}
        ry={2}
        width={width}
        height={height}
        fill={isWrong ? '#ff0000' : fill}
        {...(isVisible && glyph && { stroke: '#000000', strokeWidth: 0.5 })}
        {...(isVisible &&
          isModifierKey && {
            stroke: '#000000',
            strokeWidth: 0.5,
            fill: 'url(#newpattern)',
          })}
      />

      <Text
        x={width / 2}
        y={height / 2}
        dominantBaseline="middle"
        textAnchor="middle"
        fill={fontColor}
        textRendering="optimizeLegibility"
        sx={{
          fontSize: '18px',
          fontWeight: 700,
          cursor: 'default',
          userSelect: 'none',
        }}
      >
        {glyph}
      </Text>
      {homing && (
        <Rect
          //   rx={2}
          ry={1}
          x={15}
          y={35}
          width={10}
          height={2}
          fill={'#7C7C7C'}
        />
      )}
    </Box>
  );
}

export default memo(Key);
