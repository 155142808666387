import { KeyDefinition } from 'domains/keyboard/KeyDefinition';

export type ExcludedFormChars = {
  keys: string[];
  keysWithShift: string[];
};

export const convertFormValuesToDbValues = (
  layout: KeyDefinition[],
  excludedChars: ExcludedFormChars
): KeyDefinition[] => {
  return [
    ...excludedChars.keys.map((char) => ({
      key: layout.find((key) => key.code === char && !key.shiftKey)
        ?.key as string,
      code: char,
      shiftKey: false,
    })),
    ...excludedChars.keysWithShift.map((char) => ({
      key: layout.find((key) => key.code === char && key.shiftKey)
        ?.key as string,
      code: char,
      shiftKey: true,
    })),
  ];
};

export const converDbValuesToFormValues = (
  excludedChars: SelectedChar[]
): ExcludedFormChars => {
  return {
    keys: excludedChars?.length
      ? [
          ...excludedChars
            .filter((char) => !char.shiftKey)
            .map((char) => char.code),
        ]
      : [],
    keysWithShift: excludedChars?.length
      ? [
          ...excludedChars
            .filter((char) => char.shiftKey)
            .map((char) => char.code),
        ]
      : [],
  };
};

export type SelectedChar = { key?: string; code: string; shiftKey: boolean };
