import Box from '@mui/material/Box';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import getKeysBy from 'domains/keyboard/logicalLayouts/getLogicalLayoutById';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import getPhysicalLayoutById from 'domains/keyboard/physicalLayouts/getPhysicalLayoutById';
import { KeyStat } from 'domains/test/TestResults';
import { ChangeEvent, FC, memo, useMemo } from 'react';
import CheckboxKey from './CheckboxKey';
import Row from './Row';

interface Props {
  physicalLayout: PhysicalLayout;
  logicalLayout: LogicalLayout;
  checkedKeys: string[];
  handleChange: (value: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  shift: boolean;
  keysStatistics?: KeyStat[];
}

const Keyboard: FC<Props> = ({
  physicalLayout,
  logicalLayout,
  checkedKeys,
  handleChange,
  name,
  keysStatistics,
  shift = false,
}) => {
  const physicalRowsLayout = useMemo(
    () => getPhysicalLayoutById(physicalLayout),
    [physicalLayout]
  );

  const allKeysByLogicalLayout = useMemo(
    () => getKeysBy(logicalLayout),
    [logicalLayout]
  );

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'block',
          width: '100%',
          margin: '0 auto',
          padding: '2% 1%',
          borderRadius: '0.5em',
          backgroundColor: '#cccccc',

          fontSize: '1.5vw',
          fontFamily: 'Arial, Courier New, Courier, monospace',
          fontWeight: 500,
        }}
      >
        {[
          physicalRowsLayout[0],
          physicalRowsLayout[1],
          physicalRowsLayout[2],
          physicalRowsLayout[3],
        ]?.map((keys, index) => (
          <Row key={`row_${index}`}>
            {keys?.map((key, keyIndex) => {
              const keysByCode = allKeysByLogicalLayout.filter(
                (keyObj) => keyObj.code === key.code
              );

              const keyByCode =
                keysByCode.length === 1
                  ? keysByCode[0]
                  : keysByCode.find((key) => key.shiftKey === shift);

              const keyResult = keysStatistics?.find(
                (keyStats) =>
                  keyStats.key.code === key.code &&
                  keyStats.key.shiftKey === shift
              );

              return (
                <CheckboxKey
                  physicalLayout={physicalLayout}
                  variant={key.size}
                  key={key.code}
                  id={key.code}
                  value={key.code}
                  checked={checkedKeys.indexOf(key.code) !== -1}
                  onChange={handleChange}
                  name={name}
                  label={keyByCode?.key ?? ''}
                  showKey={keyByCode?.type !== 'sys'}
                  reaction={
                    keyResult?.status === 'correct'
                      ? keyResult?.reaction
                      : undefined
                  }
                  error={
                    keyResult?.status === 'wrong' ||
                    keyResult?.status === 'missed'
                  }
                  homing={key?.homing}
                />
              );
            })}
          </Row>
        ))}
      </Box>
    </>
  );
};

export default memo(Keyboard);
