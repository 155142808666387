import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';

import PasswordChangeForm from 'components/Forms/PasswordChange';

interface Props {}

const ProfilePage: FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Мой аккаунт - keycap.pro</title>
      </Helmet>
      <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
        <PasswordChangeForm />
      </Container>
    </>
  );
};

export default ProfilePage;
