import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from '@reduxjs/toolkit';

import authReducer from '../drafts/auth/slice';
import notifierReducer from '../domains/notifier/slice';
import userReducer from '../domains/user/slice';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    notifier: notifierReducer,
    auth: authReducer,
    user: userReducer,
  });

export default createRootReducer;
