import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LessonEvent } from 'domains/test/LessonEvent';
import { Lesson } from 'domains/lessons/models/Lesson';
import type { KeyStat, LessonStats } from 'domains/lessons/models/LessonStats';
import { Layout } from 'domains/user/Layout';
import { average } from 'utils/average';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';

export const convertLogsToLessonStatsInfo = (
  lesson: Lesson,
  logs: LessonEvent[],
  lessonOptions: {
    taktTime: number;
    delayTime: number;
  },
  logicalLayout: LogicalLayout,
  physicalLayout: PhysicalLayout
): LessonStats => {
  const totalTime: number = getTotalTimeFromLogsInMs(logs);

  let numBlots = 0;

  const allKeystrokes = logs.filter(
    ({ type }) =>
      type === 'correctKeystroke' ||
      type === 'wrongKeystroke' ||
      type === 'missedKeystroke' ||
      type === 'blot'
  );

  const keyStats: KeyStat[] = [];
  lesson.chars.forEach((keyDef) => {
    const keyKeystrokes = allKeystrokes.filter(
      ({ target, type }) =>
        type !== 'blot' &&
        target?.key === keyDef.key &&
        target?.code === keyDef.code
    );
    //TODO: когда будут уроки с последовательностями нужно будет учитывать alternate

    let numCorrectKeystrokes = 0;
    let numWrongKeystrokes = 0;
    let numMissedKeystrokes = 0;

    let numTouchCorrectKeystrokes = 0;
    let numTouchWrongKeystrokes = 0;
    let numTouchMissedKeystrokes = 0;

    keyKeystrokes.forEach(({ type, isBlindly }) => {
      if (type === 'blot') {
        numBlots += 1;
      }
      if (!isBlindly) {
        if (type === 'correctKeystroke') {
          numCorrectKeystrokes += 1;
        }
        if (type === 'wrongKeystroke') {
          numWrongKeystrokes += 1;
        }
        if (type === 'missedKeystroke') {
          numMissedKeystrokes += 1;
        }
      }

      if (isBlindly) {
        if (type === 'correctKeystroke') {
          numTouchCorrectKeystrokes += 1;
        }
        if (type === 'wrongKeystroke') {
          numTouchWrongKeystrokes += 1;
        }
        if (type === 'missedKeystroke') {
          numTouchMissedKeystrokes += 1;
        }
      }
    });

    keyStats.push({
      key: keyDef,

      numCorrectKeystrokes,
      numWrongKeystrokes,
      numMissedKeystrokes,

      numTouchCorrectKeystrokes,
      numTouchWrongKeystrokes,
      numTouchMissedKeystrokes,

      reactions: keyKeystrokes
        .filter(
          ({ isBlindly, type }) => !isBlindly && type === 'correctKeystroke'
        )
        .map((keystroke) => keystroke.reaction) as number[],

      touchReactions: keyKeystrokes
        .filter(
          ({ isBlindly, type }) => isBlindly && type === 'correctKeystroke'
        )
        .map((keystroke) => keystroke.reaction) as number[],
    });
  });

  return {
    lessonId: lesson._id,
    type: lesson.type,
    logicalLayout: logicalLayout,
    physicalLayout: physicalLayout,
    lessonOptions,

    totalTime,
    title: lesson.title,

    numBlots,

    keyStats,

    logs,
  };
};

function getTotalTimeFromLogsInMs(logs: LessonEvent[]) {
  // const startTime = logs.find((event) => event.type === 'start')?.timecode;
  const endTime = logs.find((event) => event.type === 'finish')?.timecode ?? 0;

  const pauseTotalTime = logs
    .filter((event) => event.type === 'pause' || event.type === 'continue')
    .sort((event1, event2) => event1.timecode - event2.timecode)
    .reduce(function (sum, current, index, arr) {
      return index % 2 != 0
        ? sum + (current.timecode - arr[index - 1].timecode)
        : sum;
    }, 0);

  // console.log({ endTime, pauseTotalTime, totalTime: endTime - pauseTotalTime });

  return endTime - pauseTotalTime;
}

// const getAverageReaction = (logs: TestEvent[]) => {
//   return (
//     Math.round(
//       average(
//         logs
//           .filter((event) => event.type === 'correctKeystroke')
//           .map((event) => event.reaction) as number[]
//       ) * 100
//     ) / 100
//   );
// };
