import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import SVGKeyboard from 'components/SVGLessonKeyboard';
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { FC, KeyboardEventHandler } from 'react';

interface Props {
  pressedKeys: Set<string>;
  isPaused: boolean;
  isStarted: boolean;
  isBlindly: boolean;
  testedKey: KeyDefinition | null;
  typingZones: any;
  reset: () => void;
  physicalLayout: PhysicalLayout | null;
  logicalLayout: LogicalLayout | null;
  onKeyPress: KeyboardEventHandler<HTMLInputElement>;
  onKeyUp: KeyboardEventHandler<HTMLInputElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onFocus: () => void;
  onBlur: () => void;
  progress1: number;
  progress2: number;
  isFocused: boolean;
}

const Tutor: FC<Props> = ({
  pressedKeys,
  isPaused,
  isStarted,
  isBlindly,
  testedKey,
  reset,
  physicalLayout,
  logicalLayout,
  typingZones,
  onKeyPress,
  onKeyUp,
  onKeyDown,
  onFocus,
  onBlur,
  progress1,
  progress2,
  isFocused,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        justifyContent: 'center',
        flexWrap: 'nowrap',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
          overflow: 'hidden',
          gap: 1,
          pl: 1,
          pr: 1,
          // pt: 0.5,
          // pb: 0.5,
        }}
      >
        <LinearProgress
          variant="determinate"
          value={progress1}
          sx={{
            // mr: 2,
            display: 'block',
            // transform: 'rotate(-90deg)',
            width: '16px',
            height: '100%',
            borderRadius: 2,
            border: '2px solid #eeeeee',
            // boxShadow: '0px 0px 3px 0px #000000 inset',
            '& span.MuiLinearProgress-bar': {
              transform: `translateY(${100 - progress1}%) !important`, //has to have !important
            },
            // color: '#91BECB',
            color: theme.palette.grey[300],
            '& .MuiLinearProgress-bar': {
              // boxShadow: '0px 0px 3px 0px #000000 inset',
              backgroundColor: '#547FAD',
            },
          }}
          color="inherit"
        />
        <LinearProgress
          color="inherit"
          sx={{
            width: '16px',
            height: '100%',
            borderRadius: 2,
            border: '2px solid #eeeeee',
            '& span.MuiLinearProgress-bar': {
              transform: `translateY(${100 - progress2}%) !important`, //has to have !important
            },
            // color: '#AAD034',
            // color: '#F36B6C',
            // color: '#B2765E',
            // color: '#737373',
            // color: '#841C14',
            color: theme.palette.grey[300],
            '& .MuiLinearProgress-bar': {
              // boxShadow: '0px 0px 3px 0px #000000 inset',
              backgroundColor: '#A42C04',
            },
          }}
          variant="determinate"
          value={progress2}
        />
      </Box>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
        }}
        // ref={containerRef}
      >
        {(!isStarted || isPaused) && (
          <Box
            sx={{
              content: '""',
              position: 'absolute',
              width: '98.6%',
              right: '0.7%',
              height: '100%',
              margin: 0,
              padding: 0,
              borderRadius: '4px',

              backdropFilter: 'blur(4px)',
              zIndex: 50,
            }}
          >
            <Box
              sx={{
                content: '""',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                padding: '1em 2em',
                fontSize: '20px',
                margin: 'auto',
                top: '50%',
                left: '50%',
                //TODO: убрать коммент
                // webkitTransform: 'translate(-50%,-50%)',
                msTransform: 'translate(-50%,-50%)',
                transform: 'translate(-50%,-50%)',
                borderRadius: '4px',
                zIndex: 50,
              }}
            >
              {!isFocused && (
                <Typography variant="subtitle1" component="span">
                  Нажмите здесь для <b>активации</b>
                </Typography>
              )}
              {isFocused && !isStarted && !isPaused && (
                <>
                  <Typography variant="subtitle1" component="span">
                    Нажмите [пробел], чтобы <b>начать</b>
                  </Typography>
                  <Typography variant="subtitle1" component="span">
                    Нажмите [shift + пробел], чтобы <b>сделать паузу</b>
                  </Typography>
                </>
              )}
              {isFocused && isPaused && (
                <>
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{ fontWeight: 700 }}
                  >
                    ПАУЗА
                  </Typography>
                  <Typography variant="subtitle1" component="span">
                    Нажмите [пробел], чтобы <b>продолжить</b>
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        )}
        <input
          autoFocus
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent',
            outline: 'none',
            border: 'none',
            padding: 0,
            margin: 0,
            fontSize: 0,
            zIndex: 100,
            cursor: isFocused && isStarted && !isPaused ? 'none' : 'pointer',
          }}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <SVGKeyboard
          testedKey={testedKey}
          pressedKeys={pressedKeys}
          isBlindly={isBlindly}
          typingZones={typingZones}
          physicalLayout={physicalLayout}
          logicalLayout={logicalLayout}
        />
      </Box>
    </Box>
  );
};

export default Tutor;
