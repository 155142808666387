// Types
import types from './types';

import { UserCredentials } from 'drafts/auth/models/UserCredentials';

const authActions = {
  // Async
  signupAsync: (userData: UserCredentials) => ({
    type: types.SIGNUP_ASYNC,
    payload: userData,
  }),
  loginAsync: (authData: UserCredentials) => ({
    type: types.LOGIN_ASYNC,
    payload: authData,
  }),
  authenticateAsync: () => ({
    type: types.AUTHENTICATE_ASYNC,
  }),
  initializeAsync: () => ({
    type: types.INITIALIZE_ASYNC,
  }),
  logoutAsync: () => ({
    type: types.LOGOUT_ASYNC,
  }),
};

export default authActions;
