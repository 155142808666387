import Box from '@mui/material/Box';
import StatView from 'components/Stats/StatView';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { TestStatsData } from 'utils/convertLogToTestStatsData';
import { formatAccuracy, formatReaction } from 'utils/format';

interface Props extends TestStatsData {}

const Stats: FC<Props> = ({
  totalNumKeystrokes,
  numCorrectKeystrokes,
  numWrongKeystrokes,
  numMissedKeystrokes,
  averageReaction,
  accuracy,
}) => {
  const { t } = useTranslation();
  // console.log(score);
  return (
    <Box
      sx={{
        textAlign: 'center',
        '>div': {
          margin: '0 10px',
        },
      }}
    >
      <>
        <StatView name={t('totalKeystrokes')} value={totalNumKeystrokes} />
        <StatView name={t('correctKeystrokes')} value={numCorrectKeystrokes} />
        <StatView
          name={t('wrongMissedKeystrokes')}
          value={`${numWrongKeystrokes}(${numMissedKeystrokes})`}
        />
        <StatView
          name={t('averageReaction')}
          value={
            averageReaction
              ? `${formatReaction(averageReaction)}${t('ms')}`
              : `${t('notAvailable')}`
          }
        />
        <StatView
          name={t('accuracy')}
          value={
            accuracy ? `${formatAccuracy(accuracy)}%` : `${t('notAvailable')}`
          }
        />
      </>
    </Box>
  );
};

export default Stats;
