import { FC, MouseEvent, useState } from 'react';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { useTranslation } from 'react-i18next';

interface Props {
  logicalLayout: LogicalLayout | null;
  physicalLayout: PhysicalLayout | null;
  emulated: boolean | null;
  showLabel?: boolean;
}

const commonStyles = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 2,
  paddingLeft: 0.2,
  paddingRight: 0.2,
  color: 'text.secondary',
};

const KeyboardLayoutLabel: FC<Props> = ({
  logicalLayout,
  physicalLayout,
  emulated,
  showLabel = true,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        sx={{ display: 'inline-flex', alignItems: 'center' }}
        title="your keyboard layout"
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {showLabel && (
                <Typography
                  variant="caption"
                  sx={{ position: 'absolute', top: '-10px' }}
                >
                  {`${t('yourLayout')}:`}
                </Typography>
              )}
              <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ minWidth: '8ch' }}>
                  {logicalLayout ? t(`logicalLayouts.${logicalLayout}`) : '-'}
                </Typography>
                <Box sx={{ ...commonStyles, borderRadius: 1 }}>
                  <Typography component="span">
                    {physicalLayout
                      ? t(`physicalLayouts.${physicalLayout}`)
                      : '-'}
                  </Typography>
                </Box>
                {emulated !== null && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      bottom: '-15px',
                    }}
                  >
                    {emulated ? (
                      <>
                        <CheckCircleSharpIcon
                          fontSize="small"
                          sx={{ mr: 0.25 }}
                          color="success"
                        />
                        <Typography
                          variant="caption"
                          sx={{ mr: 0.1, cursor: 'default' }}
                        >
                          {t('emulated').toLowerCase()}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <CancelSharpIcon
                          fontSize="small"
                          sx={{ mr: 0.25 }}
                          color="warning"
                        />
                        <Typography
                          variant="caption"
                          sx={{ mr: 0.1, cursor: 'default' }}
                        >
                          {/* {t('emulated').toLowerCase()} */}
                          {t('notEmulated').toLowerCase()}
                        </Typography>
                      </>
                    )}
                    <Typography
                      variant="subtitle2"
                      sx={{ cursor: 'default' }}
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      <HelpOutlineSharpIcon fontSize="inherit" color="info" />
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            elevation={1}
            disableRestoreFocus
          >
            <Typography
              sx={{
                p: 1,
                maxWidth: '250px',
                wordWrap: 'normal',
                wordBreak: 'break-word',
              }}
            >
              При эмуляции, вы можете не беспокоиться о проверке соответствия
              раскладки клавиатуры в вашей ОС и раскладки в приложении
            </Typography>
          </Popover>
        </Box>
      </Box>
    </>
  );
};

export default KeyboardLayoutLabel;
