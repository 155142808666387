import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Catcher from 'components/Catcher';
import CreateNewExDialog from 'components/CreateNewEx/Dialog';
import ExpandMore from 'components/ExpandMore';
import GeneralStatistics from 'components/GeneralStatistics';
import InitialKeyboardSetup from 'components/InitialKeyboardSetup';
import KeyboardSetupDialog from 'components/KeyboardSetup/Dialog/Dialog';
import LessonList from 'components/Lessons';
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import PhysicalLayoutRadio from 'components/PhysicalLayoutRadio';
import StyledDialog from 'components/StyledDialog';
import { layouts } from 'domains/keyboard/layouts';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { enqueueSnackbar } from 'domains/notifier/slice';
import { Helmet } from 'react-helmet';
import {
  logicalLayoutSelector,
  myLayoutsSelector,
  physicalLayoutSelector,
} from 'domains/user/selectors/settings';
import { updateUserLayoutSettings } from 'domains/user/slice';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks';
import useExercises from 'hooks/useExercises';
import { ChangeEvent, FC, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import api from 'REST/api';
import byField from 'utils/byField';
import { object, string } from 'yup';
import getTypingZones from 'domains/keyboard/typingZones/getTypingZones';

interface Props {}

const LessonsPage: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const physicalLayout: PhysicalLayout | null = useAppSelector(
    physicalLayoutSelector
  );
  const logicalLayout: LogicalLayout | null = useAppSelector(
    logicalLayoutSelector
  );
  const myLayouts: string[] = useAppSelector(myLayoutsSelector);

  const fingerZones = useMemo(
    () => getTypingZones(physicalLayout),
    [physicalLayout]
  );

  const [basicExpanded, setBasicExpanded] = useState(true);
  const handleBasicExpandClick = () => {
    setBasicExpanded(!basicExpanded);
  };

  const [customExpanded, setCustomExpanded] = useState(true);
  const handleCustomExpandClick = () => {
    setCustomExpanded(!customExpanded);
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    updateUserSettingsMutate({ logicalLayout: newValue, physicalLayout });
  };

  const [open, setOpen] = useState(false);
  const [сreateExOpen, setCreateExOpen] = useState(false);

  const { mutate: updateUserSettingsMutate, isLoading } = useMutation(
    api.settings.update,
    {
      onSuccess: async (data, variables) => {
        dispatch(updateUserLayoutSettings(data));
        handleClose();
      },
      onError: (error) => {
        console.log('ERROR, ', error);
        dispatch(
          enqueueSnackbar(
            { message: error.message },
            {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              action: (key) => <NotifierCloseButton notifierKey={key} />,
            }
          )
        );
      },
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateExOpen = () => {
    setCreateExOpen(true);
  };

  const handleCreateExClose = () => {
    setCreateExOpen(false);
  };

  const initialValues = {
    physicalLayout: physicalLayout,
  };

  const validationSchema = object().shape({
    physicalLayout: string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateUserSettingsMutate({
        logicalLayout: logicalLayout,
        physicalLayout: values.physicalLayout,
      });
    },
    enableReinitialize: true,
  });

  const handlePhysicalLayoutChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateUserSettingsMutate({
      logicalLayout: logicalLayout,
      physicalLayout: (event.target as HTMLInputElement).value,
    });
    formik.handleChange(event);
  };

  const {
    lessons,
    isLessonsLoading,
    createExerciseMutation,
    createExerciseLoading,
    deleteExercise,
    deleteExerciseLoading,
  } = useExercises(handleCreateExClose);

  const {
    isLoading: isStatisticsLoading,
    isError: isStatisticsError,
    data: statistics,
  } = useQuery(
    ['statistics', logicalLayout, physicalLayout],
    (params) => {
      const [_key, logicalLayout, physicalLayout] = params.queryKey;
      return api.lessons.fetchStatistics(logicalLayout);
    },
    {
      onSuccess: (data) => {
        // console.log('LESSONS SUCCESS', data, Object.keys(data));
      },
      onError: (error) => {
        enqueueSnackbar(
          { message: error.message },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        );
      },
      enabled: !!logicalLayout || !!physicalLayout,
    }
  );

  return (
    <Catcher>
      <Helmet>
        <title>Упражнения - keycap.pro</title>
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 5 }}>
        {physicalLayout && logicalLayout ? (
          <>
            <GeneralStatistics
              loading={isStatisticsLoading}
              stats={statistics?.generalStatistics}
              sx={{ mb: 2, justifyContent: 'space-between' }}
            />

            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                width: 'inherit',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'inherit',
                }}
              >
                <Tabs
                  value={logicalLayout}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {myLayouts.map((lt) => (
                    <Tab
                      key={lt}
                      value={lt}
                      label={t(`logicalLayouts.${lt}`)}
                    />
                  ))}
                </Tabs>
                <Button onClick={handleOpen}>+</Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <PhysicalLayoutRadio
                  value={formik.values.physicalLayout}
                  options={
                    physicalLayout && logicalLayout
                      ? layouts[logicalLayout]?.availablePhysicalLayouts
                      : []
                  }
                  name="physicalLayout"
                  onChange={handlePhysicalLayoutChange}
                />
              </Box>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" component="h2">
                  Базовые
                </Typography>

                <ExpandMore
                  expand={basicExpanded}
                  onClick={handleBasicExpandClick}
                  aria-expanded={basicExpanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Box>
              <Divider />
              <Collapse in={basicExpanded} timeout="auto" unmountOnExit>
                <LessonList
                  lessons={lessons
                    ?.filter((lesson) => {
                      return (
                        lesson.physicalLayouts.includes(physicalLayout) &&
                        lesson.type === 'basic'
                      );
                    })
                    .sort(byField('number'))}
                  type="basic"
                  lessonsStatistics={statistics?.generalLessonsStatistics}
                  loading={isLessonsLoading}
                  fingerZones={fingerZones}
                  // excludedKeys={
                  //   layout?.logicalLayout
                  //     ? excludedChars?.[layout?.logicalLayout]
                  //     : null
                  // }
                />
              </Collapse>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" component="h2">
                  Мои
                </Typography>
                <Box
                  sx={{
                    '> button:nth-of-type(1)': {
                      mr: 1,
                    },
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleCreateExOpen}
                    startIcon={<AddIcon />}
                    disabled={!physicalLayout || !logicalLayout}
                  >
                    Новое упражнение
                  </Button>
                  <ExpandMore
                    expand={customExpanded}
                    onClick={handleCustomExpandClick}
                    aria-expanded={customExpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </Box>
              </Box>
              <Divider />
              <Collapse in={customExpanded} timeout="auto" unmountOnExit>
                {!lessons?.filter((lesson) => {
                  return (
                    lesson.physicalLayouts.includes(physicalLayout) &&
                    (lesson.type === 'custom' || lesson.type === 'test')
                  );
                }).length && !isLessonsLoading ? (
                  <Box sx={{ pt: 2 }}>
                    <Alert severity="warning" icon={false} sx={{ mb: 2 }}>
                      Список пока пуст..
                    </Alert>
                  </Box>
                ) : (
                  <LessonList
                    lessons={lessons
                      ?.filter((lesson) => {
                        return (
                          lesson.physicalLayouts.includes(physicalLayout) &&
                          (lesson.type === 'custom' || lesson.type === 'test')
                        );
                      })
                      .sort(byField('createdAt', 'desc'))}
                    type="custom"
                    lessonsStatistics={statistics?.generalLessonsStatistics}
                    loading={isLessonsLoading}
                    onDelete={deleteExercise}
                    fingerZones={fingerZones}
                  />
                )}
              </Collapse>
            </Box>

            <KeyboardSetupDialog
              open={open}
              physicalLayout={physicalLayout}
              logicalLayout={logicalLayout}
              onClose={handleClose}
              onSubmit={updateUserSettingsMutate}
              isLoading={isLoading}
            />

            <StyledDialog
              onClose={handleCreateExClose}
              aria-labelledby="test-result-dialog"
              open={сreateExOpen}
              onSubmit={handleCreateExClose}
              maxWidth="md"
              fullWidth
            >
              <CreateNewExDialog
                onClose={handleCreateExClose}
                onSubmit={(values) => {
                  // console.log(JSON.stringify(values, 2, null));
                  createExerciseMutation(values);
                }}
                logicalLayout={logicalLayout}
                physicalLayout={physicalLayout}
                loading={createExerciseLoading}
              />
            </StyledDialog>
          </>
        ) : (
          <>
            {(!logicalLayout || !physicalLayout) && (
              <Box sx={{ mb: 3 }}>
                <Alert severity="warning">
                  {t('initialKeyboardSetupWarning')}
                </Alert>
              </Box>
            )}
            <InitialKeyboardSetup
              onSubmit={updateUserSettingsMutate}
              physicalLayout={physicalLayout}
              logicalLayout={logicalLayout}
              isLoading={isLoading}
            />
          </>
        )}
      </Container>
    </Catcher>
  );
};

export default LessonsPage;
