export enum Stage {
  'INTRO' = 'INTRO', // знакомство
  // 'MAIN' = 'MAIN', // основной этап
  'CHECKING' = 'CHECKING', // проверка

  'PUNISHMENT' = 'PUNISHMENT', // наказание
  'REINFORSMENT' = 'REINFORSMENT', // закрепление

  'TRAINING' = 'TRAINING', // отработка после наказания
  'LEVEL_TRAINING' = 'LEVEL_TRAINING', // тренировка уровня

  'BLINDLY' = 'BLINDLY', // в слепую
}
