import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import InitialKeyboardSetup from 'components/InitialKeyboardSetup';
import LessonKeys from 'components/LessonList/LessonKeys';
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import Tutor from 'components/Tutor';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import getTypingZones from 'domains/keyboard/typingZones/getTypingZones';
import { LessonStats } from 'domains/lessons/models/LessonStats';
import { enqueueSnackbar } from 'domains/notifier/slice';
import { convertLogsToLessonStatsInfo } from 'domains/test/convertLogsToLessonStatsInfo';
import { logicalLayoutSelector } from 'domains/user/selectors/settings';
import { useAppDispatch, useAppSelector } from 'hooks';
import useLesson from 'hooks/useLesson';
import useLocalStorage from 'hooks/useLocalStorage';
import * as ROUTES from 'navigation/routes';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import api from 'REST/api';

import { Breakpoint, SelectChangeEvent } from '@mui/material';
import Settings from 'components/Demo/Settings';

import Statistics from 'components/Demo/Statistics';
import IntroDialog from 'components/IntroDialog';
import KeyboardSetup from 'components/KeyboardSetup';
import Stats from 'components/Stats';
import { defaultOptions } from 'pages/Lesson';
import { convertLogToLessonStatsData } from 'utils/convertLogToLessonStatsData';

const steps = [
  'Select campaign settings',
  'Create an ad group',
  'Create an ad',
];

const breakpoints = ['lg', 'xl'] as Breakpoint[];

interface Props {}

const Demo: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const logicalLayout: LogicalLayout | null = useAppSelector(
    logicalLayoutSelector
  );

  const [localTaktTime, setLocalTaktTime] = useLocalStorage<number>(
    'taktTime',
    2000
  );

  const [localDelayTime, setLocalDelayTime] = useLocalStorage<number>(
    'delayTime',
    200
  );

  const [tutorSizeCursor, setTutorSizeCursor] = useLocalStorage<number>(
    'tutorSizeCursor',
    0
  );

  const [showRulesInDemo, setShowRulesInDemo] = useLocalStorage<boolean>(
    'showRulesInDemo',
    true
  );

  const [openIntroDialog, setOpenIntroDialog] = useState(showRulesInDemo);

  const handleIntroDialogOpen = () => {
    setOpenIntroDialog(true);
  };

  const handleIntroDialogClose = () => {
    setShowRulesInDemo(false);
    setOpenIntroDialog(false);
  };

  const handleTutorSizeChange = () => {
    setTutorSizeCursor(
      tutorSizeCursor + 1 > breakpoints.length - 1 ? 0 : tutorSizeCursor + 1
    );
  };

  const [localSettingsExpanded, setLocalSettingsExpanded] =
    useLocalStorage<boolean>('settingsExpanded', false);

  const handleSettingsExpandClick = () => {
    setLocalSettingsExpanded(!localSettingsExpanded);
  };

  const handleDelayTimeChange = (
    event: SelectChangeEvent<number>
    // value: number
  ) => {
    const { target } = event;
    setLocalDelayTime(Number(target.value));
  };

  const handleTaktTimeChange = (
    event: SelectChangeEvent<number>
    // value: number
  ) => {
    const { target } = event;
    setLocalTaktTime(Number(target.value));
  };

  // const [localPhysicalLayout, setPhysicalLayoutLocalStorage] =
  //   useLocalStorage<PhysicalLayout | null>('physicalLayout', null);

  // const [localLogicalLayout, setLogicalLayoutLocalStorage] =
  //   useLocalStorage<LogicalLayout | null>('logicalLayout', null);

  const [localPhysicalLayout, setLocalPhysicalLayout] = useLocalStorage<
    PhysicalLayout | undefined
  >('physicalLayout', undefined);

  const [localLogicalLayout, setLocalLogicalLayout] = useLocalStorage<
    LogicalLayout | undefined
  >('logicalLayout', undefined);

  const [localEntryLevel, setLocalEntryLevel] = useLocalStorage<number>(
    'entryLevel',
    7
  );

  const [localNumControlChars, setLocalNumControlChars] = useLocalStorage<
    'byCharsNum' | number
  >('numControlChars', 'byCharsNum');

  const globalLogicalLayout: LogicalLayout | null | undefined = useMemo(
    () => (isAuthenticated ? logicalLayout : localLogicalLayout),
    [isAuthenticated, logicalLayout, localLogicalLayout]
  );

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [statsInfo, setStatsInfo] = useState<LessonStats>();

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep + 1)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 2);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const {
    mutate,
    isLoading,
    data: lesson,
  } = useMutation(api.lessons.fetchDemoExercise, {
    onSuccess: () => {
      // Инвалидация и обновление
      // console.log('ADD USER SETTINGS SUCCESS');
    },
    onError: (error) => {
      dispatch(
        enqueueSnackbar(
          { message: error.message },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        )
      );
    },
  });

  const typingZones = useMemo(
    () => getTypingZones(localPhysicalLayout),
    [localPhysicalLayout]
  );

  const {
    pressedKeys,
    isBlindly,
    progress1,
    progress2,
    logs,
    isFinished,
    isStarted,
    isPaused,
    isFocused,
    testedKey,
    reset,
    handleKeyPress,
    handleKeyUp,
    handleKeyDown,
    handleFocus,
    handleBlur,
  } = useLesson(
    lesson?.chars ?? [],
    // [layout?.physical].filter(
    //     (key) =>
    //       !excludedChars?.[layout?.logical].find(
    //         (excludedKey) =>
    //           excludedKey &&
    //           excludedKey.code === key.code &&
    //           excludedKey.shiftKey === key.shiftKey
    //       )
    //   )
    // : []) ?? [],
    {
      layoutId: localLogicalLayout,
      algorithm: lesson?.type,
      delayTime: localDelayTime,
      taktTime: localTaktTime,
      entryLevel: localEntryLevel,
      numControlChars: localNumControlChars,
    }
  ); // TODO: мержить пальцы пользователя, чтобы получить set?
  useEffect(() => {
    if (isFinished) {
      const lessonStats: LessonStats = convertLogsToLessonStatsInfo(
        lesson,
        logs,
        {
          taktTime: 2000,
          delayTime: 200,
        },
        lesson?.logicalLayout,
        localPhysicalLayout
      );
      setStatsInfo(lessonStats);
      handleNext();
    }
  }, [isFinished]);

  return (
    <Box
      sx={{
        width: '100%',
        // minHeight: isAuthenticated ? 'max-content' : '670px',
        p: 4,
        mb: 8,
        // pt: 8,
        // border: '2px solid #efefef',
        // backgroundColor: theme.palette.background.paper,
        // backgroundImage:
        //   activeStep === 0 ? `url(${BackgroundHero})` : undefined,
        // backgroundPosition: 'top',
        // backgroundSize: 'cover',
        // padding: 'theme.spacing(18, 0, 16)',
      }}
    >
      {activeStep === 0 && (
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: isAuthenticated ? undefined : 'space-between',
              alignItems: 'center',
              textAlign: 'center',
              height: '100%',
              p: 4,
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 0, mb: 2 }}
            >
              <Typography
                variant="h3"
                component="span"
                sx={{
                  fontFamily: 'Inter,system-ui,sans-serif',
                  fontWeight: 700,
                }}
              >
                НОВЫЙ
              </Typography>
              <Typography
                variant="h3"
                component="h1"
                sx={{
                  fontFamily: 'Inter,system-ui,sans-serif',
                  fontWeight: 700,
                  mb: 2,
                }}
              >
                КЛАВИАТУРНЫЙ ТРЕНАЖЁР
              </Typography>
              {/* <Typography variant="h3" component="h1" sx={{ fontWeight: 500 }}>
                🚀
              </Typography> */}
              {/* <Typography variant="h4" component="h1" sx={{ fontWeight: 500 }}>
                для освоения слепого десятипальцевого метода набора
              </Typography> */}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                mb: 4,
              }}
            >
              <Typography
                variant="subtitle2"
                component="h6"
                sx={{
                  fontWeight: 700,
                  color: 'text.secondary',
                }}
              >
                Доступные раскладки:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  // border: '2px solid #efefef',
                  // borderRadius: 2,
                  p: 0.5,
                  gap: 1,
                }}
              >
                <Box sx={{ display: 'inline-flex', gap: 1 }}>
                  <Typography
                    variant="body2"
                    component="h6"
                    sx={{
                      fontWeight: 700,
                      color:
                        globalLogicalLayout === 'us_qwerty'
                          ? '#0B72B9'
                          : 'inherit',
                    }}
                  >
                    {`${t('logicalLayouts.us_qwerty')}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="h6"
                    sx={{
                      fontWeight: 700,
                      color:
                        globalLogicalLayout === 'dvorak'
                          ? '#0B72B9'
                          : 'inherit',
                    }}
                  >
                    {`${t('logicalLayouts.dvorak')}`}
                  </Typography>

                  <Typography
                    variant="body2"
                    component="h6"
                    sx={{
                      fontWeight: 700,
                      color:
                        globalLogicalLayout === 'workman'
                          ? '#0B72B9'
                          : 'inherit',
                    }}
                  >
                    {`${t('logicalLayouts.workman')}`}
                  </Typography>

                  <Typography
                    variant="body2"
                    component="h6"
                    sx={{
                      fontWeight: 700,
                      color:
                        globalLogicalLayout === 'colemak'
                          ? '#0B72B9'
                          : 'inherit',
                    }}
                  >
                    {`${t('logicalLayouts.colemak')}`}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    component="h6"
                    sx={{
                      fontWeight: 700,
                      color:
                        globalLogicalLayout === 'jcuken'
                          ? '#0B72B9'
                          : 'inherit',
                    }}
                  >
                    {`${t('logicalLayouts.jcuken')}`}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {!isAuthenticated && (
              <Button
                onClick={() => {
                  localPhysicalLayout && localLogicalLayout
                    ? (() => {
                        handleSkip();
                        mutate(localLogicalLayout);
                      })()
                    : handleNext();
                  if (window.ym) {
                    ym(90304960, 'reachGoal', 'CLICK_ON_TRY_IT');
                  }
                  return true;
                }}
                disableElevation
                variant="contained"
                sx={{ position: 'relative', width: '25ch', height: '50px' }}
              >
                {/* <Typed
              typeSpeed={70}
              backSpeed={70}
              strings={['Попробовать']}
              // smartBackspace
              // shuffle={false}
              backDelay={1000}
              // fadeOut={false}
              // fadeOutDelay={100}
              // className={}
              // style={{ fontSize: '1rem', letterSpacing: '0.00938em' }}
              loopCount={0}
              showCursor
              cursorChar="|"
            /> */}
                Попробовать
              </Button>
            )}
          </Box>
        </Container>
      )}
      {activeStep === 1 && (
        <Container maxWidth="lg">
          <Typography component="p" variant="h6" sx={{ mb: 3 }}>
            Настройка клавиатуры
          </Typography>
          {(!localPhysicalLayout || !localLogicalLayout) && (
            <Alert
              severity="info"
              variant="outlined"
              icon={false}
              sx={{ mb: 2 }}
            >
              Пожалуйста, выполните начальную настройку клавиатуры
            </Alert>
          )}
          <InitialKeyboardSetup
            onSubmit={(values) => {
              setLocalLogicalLayout(values.logicalLayout);
              setLocalPhysicalLayout(values.physicalLayout);
              mutate(values.logicalLayout);
              handleNext();
            }}
            physicalLayout={localPhysicalLayout}
            logicalLayout={localLogicalLayout}
            isLoading={false}
          />
        </Container>
      )}
      {activeStep === 2 &&
        (isLoading ? (
          <Container maxWidth="md" sx={{ mb: 0 }}>
            <Box
              sx={{
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // flexWrap: 'nowrap',
                textAlign: 'center',
                margin: 'auto',
                mt: 20,
                width: '100%',
              }}
            >
              <Typography gutterBottom>Загрузка урока...</Typography>
              <LinearProgress />
            </Box>
          </Container>
        ) : (
          <>
            <Container maxWidth="md" sx={{ mb: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Typography
                      component="h2"
                      variant="h4"
                      sx={{ fontWeight: 400 }}
                      color="text.primary"
                      gutterBottom
                    >
                      {lesson?.type !== 'custom'
                        ? t(`lessonTitles.${lesson?.title}`)
                        : lesson?.title}
                    </Typography>
                  </Box>
                  <LessonKeys
                    keys={lesson?.chars ?? []}
                    excludedKeys={
                      []
                      // logicalLayout ? excludedChars?.[logicalLayout] : null
                    }
                    loading={isLoading}
                  />
                </Box>

                <KeyboardSetup
                  logicalLayout={localLogicalLayout}
                  physicalLayout={localPhysicalLayout}
                  handleKeyboardSetupDialogOpen={() => {
                    handleBack();
                    reset();
                  }}
                />
              </Box>

              <Settings
                delayTime={localDelayTime}
                taktTime={localTaktTime}
                expanded={localSettingsExpanded}
                disabled={isStarted}
                sizeCursor={tutorSizeCursor}
                onReset={reset}
                onTutorSizeChange={handleTutorSizeChange}
                onSettingsExpandClick={handleSettingsExpandClick}
                onTaktTimeChange={handleTaktTimeChange}
                onDelayTimeChange={handleDelayTimeChange}
                onIntroDialogOpen={handleIntroDialogOpen}
                numControlChars={localNumControlChars}
                entryLevel={localEntryLevel}
                onEntryLevelChange={(event) => {
                  const { target } = event;
                  setLocalEntryLevel(Number(target.value));
                }}
                onNumControlCharsChange={(event) => {
                  const { value } = event.target;
                  setLocalNumControlChars(value as 'byCharsNum' | number);
                }}
                onResetOptions={() => {
                  setLocalNumControlChars(defaultOptions.numControlChars);
                  setLocalEntryLevel(defaultOptions.entryLevel);
                  setLocalTaktTime(defaultOptions.taktTime);
                  setLocalDelayTime(defaultOptions.delayTime);
                }}
              />
            </Container>
            <Container maxWidth={breakpoints[tutorSizeCursor]} sx={{ mb: 8 }}>
              <Tutor
                pressedKeys={pressedKeys}
                isStarted={isStarted}
                isPaused={isPaused}
                isFocused={isFocused}
                testedKey={testedKey}
                physicalLayout={localPhysicalLayout}
                logicalLayout={lesson?.logicalLayout}
                typingZones={typingZones}
                reset={reset}
                onKeyPress={handleKeyPress}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onFocus={handleFocus}
                onBlur={handleBlur}
                progress1={progress1}
                progress2={progress2}
                isBlindly={isBlindly}
              />
            </Container>
            <Stats {...convertLogToLessonStatsData(logs)} />
            <IntroDialog
              open={openIntroDialog}
              onClose={handleIntroDialogClose}
            />
          </>
        ))}
      {activeStep === 3 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            pt: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Статистика
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Statistics statsInfo={statsInfo} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <Button
              onClick={() => {
                handleBack();
                reset();
              }}
            >
              Повторить
            </Button>
            <Button component={Link} to={ROUTES.SIGNUP} variant="contained">
              К регистрации
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Demo;
