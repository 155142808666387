import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { FC } from 'react';

interface Props extends BoxProps {
  icon: string;
  title: string;
  description: string;
}

const Feature: FC<Props> = ({ icon, title, description, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        p: 4,
        borderRadius: 2,
        border: '2px solid #efefef',
        transition:
          '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
        // cursor: pointer;
        ':hover': {
          transform: ' scale(1.01)',
          boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
        },
        ...props.sx,
      }}
    >
      <Typography component="h3" variant="h3" sx={{ mb: 0.5 }}>
        {icon}
      </Typography>
      <Typography component="h3" variant="h5" color="primary" sx={{ mb: 1.5 }}>
        {title}
      </Typography>
      <Typography component="p">{description}</Typography>
    </Box>
  );
};

export default Feature;
