import BoltIcon from '@mui/icons-material/BoltSharp';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LessonKeys from 'components/LessonList/LessonKeys';
import ProgressElem from 'components/LessonList/ProgressElem';
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import humanizeDuration from 'humanize-duration';
import 'moment/locale/ru';
import { FC } from 'react';

import IconButton from '@mui/material/IconButton';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import {
  formatAccuracy,
  formatAccuracyToInt,
  formatReaction,
} from 'utils/format';
import normalisePogressValue from 'utils/normalisePogressValue';
import Hands from 'components/hands';
interface Props {
  id: string;
  number: number;
  type?: string;
  title: string;
  keys: KeyDefinition[];
  stats: any;
  fingers: Set<number>;
  onDelete?: (id: string) => void;
}

const LessonItem: FC<Props> = ({
  id,
  title,
  type,
  stats,
  number,
  fingers,
  keys,
  onDelete,
}) => {
  return (
    // <ListItem>
    <Grid item xs={6} key={id}>
      <Card
        key={id}
        variant="outlined"
        sx={{
          width: '100%',
          // maxWidth: '400px',
          boxShadow: '0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)',
          transition:
            '.3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
          // cursor: pointer;
          ':hover': {
            transform: ' scale(1.05)',
            boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
          },
        }}
      >
        <CardActionArea component={Link} to={`lessons/${id}`}>
          <CardContent
            sx={{
              position: 'relative',
            }}
          >
            <Box sx={{ position: 'absolute', right: 12, width: '30%' }}>
              <Hands fingers={fingers} />
            </Box>
            {type === 'test' && (
              <Typography
                component="span"
                variant="subtitle2"
                align="center"
                sx={{
                  position: 'absolute',
                  top: '-2px',
                  left: '30px',
                  backgroundColor: 'rgba(16, 69, 124, 0.6)',
                  // textShadow: '-0.0375em 0 0 rgba(48, 48, 48, 0.1)',
                  // textShadow: 'rgba(255,255,255,0.5) 0px 3px 3px',
                  // textShadow: '1px 1px 3px rgba(255,255,255,0.3)',
                  color: '#ffffff',
                  // color: 'transparent',
                  lineHeight: '9px',
                  fontSize: '12px',
                  borderRadius: '0 0 3px 3px',
                  p: 0.75,
                }}
                gutterBottom
              >
                тест
              </Typography>
            )}
            {onDelete && (
              <IconButton
                aria-label="close"
                onClick={(event) => {
                  event.preventDefault();
                  onDelete(id);
                }}
                sx={{
                  position: 'absolute',
                  right: 4,
                  top: 4,
                  zIndex: 3,
                  color: (theme) => theme.palette.grey[500],
                }}
                title="удалить упражнение"
              >
                <CloseIcon />
              </IconButton>
            )}
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                mb: 1,
              }}
            >
              <Typography
                component="div"
                variant="body1"
                align="left"
                sx={{
                  pr: 4,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  fontWeight: 500,
                }}
                gutterBottom
              >
                {title}
              </Typography>
              <Box
                sx={{
                  maxHeight: '85px',
                  overflow: 'hidden',
                  ':hover': { maxHeight: '100%', overflow: 'visible' },
                  width: '70%',
                }}
              >
                <LessonKeys keys={keys} />
              </Box>
            </Box>

            <Box
              display="flex"
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
              }}
            >
              {stats?.count && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: 1,
                  }}
                >
                  <ProgressElem
                    value={
                      !isNaN(stats.lastResult.accuracy)
                        ? formatAccuracyToInt(stats.lastResult.accuracy)
                        : 0
                    }
                    diffFromAvgValue={formatAccuracy(
                      stats.lastResult.diffAccuracyWithAvgValue
                    )}
                    color="inherit"
                    sx={{ color: '#2BA4F4' }}
                    title="Процент символов, набранных без ошибок в последнем упражнении"
                    measureUnit="%"
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <BoltIcon sx={{ color: '#D4A711' }} />
                    {/* <BoltIcon sx={{ color: '#BCBCBC' }} /> */}
                    <ProgressElem
                      value={normalisePogressValue(
                        formatReaction(stats.lastResult.averageReaction),
                        2000,
                        0
                      )}
                      labelValue={formatReaction(
                        stats.lastResult.averageReaction
                      )}
                      diffFromAvgValue={
                        stats?.count > 1
                          ? formatReaction(
                              stats.lastResult.diffAverageReactionWithAvgValue
                            )
                          : 0
                      }
                      measureUnit="мс"
                      title="Среднее время нажатия в последнем упражнении"
                      color="inherit"
                      sx={{ color: '#2BA4F4' }}
                      isProgressPlusSign={false}
                      showProgress={false}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                pt: 1,
              }}
            >
              <Paper
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  pt: 0.5,
                  pb: 0.5,
                  pr: 1,
                  pl: 1,
                  mr: 1.5,
                  minWidth: '5ch',
                }}
                variant="outlined"
              >
                <Typography
                  component="span"
                  variant="subtitle2"
                  title="Количество выполнений упражнения"
                  noWrap
                >{`${stats?.count ?? 0}`}</Typography>
              </Paper>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle2"
                  title="Общее время работы над упражнением"
                  noWrap
                >{`${humanizeDuration(stats?.totalTime ?? 0, {
                  language: 'ru',
                  units: ['m'],
                  round: true,
                })}`}</Typography>
                <AccessTimeIcon fontSize="small" sx={{ ml: 0.5 }} />
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default LessonItem;
