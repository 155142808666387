const types = {
  // Async
  SIGNUP_ASYNC: 'SIGNUP_ASYNC',
  LOGIN_ASYNC: 'LOGIN_ASYNC',
  AUTHENTICATE_ASYNC: 'AUTHENTICATE_ASYNC',
  LOGOUT_ASYNC: 'LOGOUT_ASYNC',
  RESET_PASSWORD_ASYNC: 'RESET_PASSWORD_ASYNC',
  FORGOT_PASSWORD_ASYNC: 'FORGOT_PASSWORD_ASYNC',
  CHANGE_PASSWORD_ASYNC: 'CHANGE_PASSWORD_ASYNC',
  INITIALIZE_ASYNC: 'INITIALIZE_ASYNC',
};

export default types;
