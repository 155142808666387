import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';

import { colemak } from 'domains/keyboard/logicalLayouts/colemak';
import { dvorak } from 'domains/keyboard/logicalLayouts/dvorak';
import { jcuken } from 'domains/keyboard/logicalLayouts/jcuken';
import { us_qwerty } from 'domains/keyboard/logicalLayouts/us_qwerty';
import { workman } from 'domains/keyboard/logicalLayouts/workman';

export default function getKeysBy(
  logicalLayout: LogicalLayout
): KeyDefinition[] {
  switch (logicalLayout) {
    case 'us_qwerty':
      return us_qwerty;
    case 'dvorak':
      return dvorak;
    case 'colemak':
      return colemak;
    case 'workman':
      return workman;
    // case 'uk_qwerty':
    //   return us_qwerty;
    case 'jcuken':
      return jcuken;
    // case 'yashert':
    //   return yashert;
    default:
      return [];
  }
}
