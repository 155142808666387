import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

export interface IntroDialogProps {
  open: boolean;
  onClose: () => void;
}

function IntroDialog({ onClose, open }: IntroDialogProps) {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      {/* <StyledDialogTitle onClose={onClose}></StyledDialogTitle> */}

      <DialogContent dividers>
        <Typography sx={{ mb: 3, textAlign: 'justify' }}>
          <Typography
            component="span"
            sx={{
              display: 'inline-flex',
              transform: 'rotate(10deg) scale(-1, 1)',
            }}
          >
            🤚
          </Typography>
          <Typography
            component="span"
            sx={{
              display: 'inline-flex',
              transform: 'rotate(-10deg)',
            }}
          >
            🤚
          </Typography>
          {'    '}Каждый раз, прежде чем приступить к отработке упражнения,
          удостоверьтесь, что вы{' '}
          <b>держите руки в правильной начальной позиции</b>: указательные
          пальцы размещаются на клавишах А и О, в русской раскладке клавиатуры
          (в английской – это F и J соответственно), а остальные пальцы занимают
          свободные места в том же ряду клавиатуры, по порядку. И получается:
          левая рука (мизинец, безымянный, средний и указательный) на буквах
          ФЫВА (ASDF), правая на ОЛДЖ (JKL;). Большие пальцы находятся над
          клавишей Space (пробелом).
        </Typography>

        <Box
          sx={{
            border: '2px solid #547FAD',
            pt: 2,
            pb: 2,
            pl: 2,
            pr: 2,
            borderRadius: 2,
            mb: 2,
            // textAlign: 'justify',
          }}
        >
          <Typography
            align="center"
            variant="h5"
            component="h4"
            sx={{ fontWeight: 700, mb: 1 }}
          >
            Правила
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
            1. Во время набора текста на клавиатуру смотреть НЕЛЬЗЯ!
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'justify' }}
            gutterBottom
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, p: 0 }}
              component="span"
            >
              2. Каждый палец нажимает только «свои» клавиши!
            </Typography>{' '}
            За каждым пальцем закреплена своя зона клавиатуры. Это очень важно.
            Ни в коем случае не меняйте пальцы для клавиш, даже если вам
            кажется, что так удобней. В тренажёре зоны различимы по цветам -
            каждый палец нажимает клавиши только своего цветового диапазона.
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
            3. После каждого нажатия палец обязательно возвращается в исходную
            позицию!
          </Typography>
        </Box>

        <Typography
          sx={{
            // border: '2px solid #547FAD',
            pt: 1,
            pb: 1,
            pl: 2,
            pr: 2,
            borderRadius: 2,
            mb: 0.5,
            textAlign: 'justify',
          }}
        >
          ☝ Для облегчения поиска основной позиции и её тактильного
          распознавания, практически на всех клавиатурах клавиши А и О (F и J)
          имеют небольшие выступы, они позволяют ориентироваться на клавиатуре
          вслепую.
        </Typography>
        <Typography
          sx={{
            // border: '2px solid #547FAD',
            pt: 1,
            pb: 1,
            pl: 2,
            pr: 2,
            borderRadius: 2,
            mb: 0.5,
            textAlign: 'justify',
          }}
        >
          ☝ В самом начале обучения может помочь проговаривание вслух каждой
          буквы.
        </Typography>
        <Typography
          gutterBottom
          sx={{
            // border: '2px solid #547FAD',
            pt: 1,
            pb: 1,
            pl: 2,
            pr: 2,
            borderRadius: 2,
            mb: 1,
            textAlign: 'justify',
          }}
        >
          ☝ Если у вас внешняя клавиатура, расположите её так, чтобы руки на
          ней располагались симметрично относительно вашего тела, а не смещены
          влево. Центр основной части клавиатуры находится между клавишами П и H
          (G и H).
        </Typography>

        <Typography align="center" sx={{ fontWeight: 700 }} gutterBottom>
          Успехов! 🐱‍🏍
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button autoFocus color="success" onClick={onClose}>
          ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IntroDialog;
