import { keyframes } from '@emotion/react';
import Box, { BoxProps } from '@mui/material/Box';
import { FC } from 'react';

const moving = keyframes`
  0%{
      opacity: 0;
      transform: translate(0,7px) rotate(225deg);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: translate(0,-7px) rotate(225deg);
    }
`;

export interface ArrowProps extends BoxProps {}

const TestArrow: FC<ArrowProps> = ({ ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        // margin: '40px auto 10px auto',
        width: '20px',
        height: '0px',
        mt: '29px',
        ml: '10px',
        '& span': {
          display: 'block',
          width: '12px',
          height: '12px',
          borderBottom: '3px solid #337AB7',
          borderRight: '3px solid #337AB7',
          borderRadius: '2px',
          transform: 'rotate(225deg)',
          margin: '-21px 0',
          animation: `${moving} 1.5s infinite`,
        },
        ...props.sx,
      }}
    >
      <Box component="span" sx={{}} />
      {/* <Box component="span" sx={{ animationDelay: '-0.2s' }} /> */}
      {/* <Box component="span" sx={{ animationDelay: '-0.2s' }} /> */}
    </Box>
  );
};

export default TestArrow;
