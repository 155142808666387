import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { CSSProperties } from '@mui/system';
import 'moment/locale/ru';
import { FC } from 'react';

interface Props extends TypographyProps {
  value: string | number;
  title: string;
  backgroundColor?: CSSProperties['backgroundColor'];
  suffix?: string | number;
}

const Statistic: FC<Props> = ({
  value,
  title,
  suffix,
  backgroundColor,
  ...props
}) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: 'fit-content',
        padding: '20px 30px',

        backgroundColor: { backgroundColor },
      }}
    >
      <Box sx={{ mb: 0.5 }}>
        <Typography component="span" variant="h6" {...props}>
          {value}
        </Typography>{' '}
        <Typography component="span" variant="body1">
          {suffix}
        </Typography>
      </Box>

      <Typography component="span" variant="caption" alignContent="center">
        {title}
      </Typography>
    </Paper>
  );
};

export default Statistic;
