// Core
import { boolean, number, object, string } from 'yup';

export const controls = {
  shape: {
    responseTime: '',
    lagTime: '',
    accidentalHitMode: '',
  },
  schema: object().shape({
    responseTime: number().required(),
    lagTime: number().required(),
    accidentalHitMode: boolean(),
  }),
};

export const passwordForget = {
  shape: {
    email: '',
  },
  schema: object().shape({
    email: string().email().required(),
  }),
};

export const passwordChange = {
  shape: {
    oldPassword: '',
    newPassword: '',
    showPassword: false,
  },
  schema: object().shape({
    oldPassword: string().required(),
    newPassword: string().min(5).required(),
    showPassword: boolean(),
  }),
};
