// Core
import { all, call, takeEvery } from 'redux-saga/effects';

// Types
import types from '../types';

// Workers
import authenticate from './workers/authenticate';
import changePassword from './workers/changePassword';
import forgotPassword from './workers/forgotPassword';
import initialize from './workers/initialize';
import logout from './workers/logout';
import register from './workers/register';
import signin from './workers/signin';

export function* watchAuthenticate() {
  yield takeEvery(types.AUTHENTICATE_ASYNC, authenticate);
}

export function* watchSignIn() {
  yield takeEvery(types.LOGIN_ASYNC, signin);
}

export function* watchLogout() {
  yield takeEvery(types.LOGOUT_ASYNC, logout);
}

export function* watchRegister() {
  yield takeEvery(types.SIGNUP_ASYNC, register);
}

export function* watchInitialize() {
  yield takeEvery(types.INITIALIZE_ASYNC, initialize);
}

export function* watchChangePassword() {
  yield takeEvery(types.CHANGE_PASSWORD_ASYNC, changePassword);
}

export function* watchForgotPassword() {
  yield takeEvery(types.FORGOT_PASSWORD_ASYNC, forgotPassword);
}

export function* watchAuth() {
  yield all([
    call(watchAuthenticate),
    call(watchChangePassword),
    call(watchForgotPassword),
    call(watchSignIn),
    call(watchRegister),
    call(watchInitialize),
    call(watchLogout),
  ]);
}
