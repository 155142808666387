export const SIGNUP = '/register';
export const LOGIN = '/login';
export const SIGN_UP_WITH_GOOGLE_URI = `${process.env.REACT_APP_API_URL}/auth/google`;
export const SIGN_UP_WITH_GITHUB_URI = `${process.env.REACT_APP_API_URL}/auth/github`;
export const HOME = '/';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_RESET = '/resetPassword/:token';
export const LESSONS = '/lessons';
export const LESSON = '/lessons/:id';
export const ACCOUNT = '/account';
export const TEST = '/test';
export const DONATION = 'https://yoomoney.ru/to/4100117950077318';
