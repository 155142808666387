import Box from '@mui/material/Box';
import ansiLayout from 'components/SVGKeyboard/ansiLayout';
import Inner from 'components/SVGKeyboard/Inner';
import isoLayout from 'components/SVGKeyboard/isoLayout';
import Key from 'components/SVGKeyboard/Key';
import Rect from 'components/SVGKeyboard/Rect';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import getKeyCapsByLayout from 'domains/keyboard/logicalLayouts/v2/getKeyCapsByLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { useMemo } from 'react';
import IsoEnter from 'components/SVGKeyboard/IsoEnter';

interface Props {
  logicalLayout: LogicalLayout;
  physicalLayout: PhysicalLayout;
}

const Keyboard = ({ logicalLayout, physicalLayout }: Props): JSX.Element => {
  const keyCaps = useMemo(
    () => getKeyCapsByLayout(logicalLayout),
    [logicalLayout]
  );

  const viewBox = [0, 0, 639, 226];
  return (
    <Box
      component="svg"
      preserveAspectRatio="xMaxYMax none"
      viewBox={`${viewBox}`}
      sx={{
        aspectRatio: '639 / 226',
        contain: 'strict',
        display: 'block',
        width: '100%',
      }}
    >
      <Rect x={0} y={0} rx={9} ry={9} width={639} height={226} fill="#cccccc" />
      <Inner x={5} y={8}>
        {physicalLayout === 'iso' &&
          isoLayout.map((row, rowIndex) => {
            let widthAdder = 0;

            return row.map(({ id, width }, index) => {
              widthAdder += width;

              return id === 'Enter' ? (
                <IsoEnter
                  id={id}
                  key={index}
                  x={widthAdder - width + 2 * index}
                  y={42 * rowIndex}
                  height={40}
                  lowerGlyph={keyCaps[id].defaultKey?.key}
                  width={width}
                  fill={'#e3e3e1'}
                />
              ) : (
                <Key
                  id={id}
                  key={index}
                  x={widthAdder - width + 2 * index}
                  y={42 * rowIndex}
                  height={40}
                  upperGlyph={keyCaps[id].shiftKey?.key}
                  lowerGlyph={keyCaps[id].defaultKey?.key}
                  type={keyCaps[id].defaultKey.type}
                  homing={id === 'KeyF' || id === 'KeyJ'}
                  width={width}
                  fill={
                    keyCaps[id].defaultKey.type === 'sys' && id !== 'Space'
                      ? '#e3e3e1'
                      : undefined
                  }
                />
              );
            });
          })}
        {physicalLayout === 'ansi' &&
          ansiLayout.map((row, rowIndex) => {
            let widthAdder = 0;

            return row.map(({ id, width }, index) => {
              widthAdder += width;

              return (
                <Key
                  id={id}
                  key={index}
                  x={widthAdder - width + 2 * index}
                  y={42 * rowIndex}
                  height={40}
                  upperGlyph={keyCaps[id].shiftKey?.key}
                  lowerGlyph={keyCaps[id].defaultKey?.key}
                  type={keyCaps[id].defaultKey.type}
                  homing={id === 'KeyF' || id === 'KeyJ'}
                  width={width}
                  fill={
                    keyCaps[id].defaultKey.type === 'sys' && id !== 'Space'
                      ? '#e3e3e1'
                      : undefined
                  }
                />
              );
            });
          })}
      </Inner>
    </Box>
  );
};

export default Keyboard;
