// Core
import { apply, put } from 'redux-saga/effects';

import { setUser } from '../../../user/slice';
import {
  authenticate as auth,
  initialize,
  startFetching,
  stopFetching,
} from '../../slice';
import api from 'REST/api';

export function* authenticate() {
  try {
    yield put(startFetching());

    const response = yield apply(api, api.auth.authenticate);
    const {
      data: user,
      status,
      message,
    } = yield apply(response, response.json);

    if (!response.ok) {
      if (response.status === 401) {
        return null;
      }
      throw new Error(message);
    }

    yield put(setUser(user));

    yield put(auth());
  } catch (error) {
    // console.error(error);
  } finally {
    yield put(stopFetching());
    yield put(initialize());
  }
}

export default authenticate;
