import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GitHubIcon from 'components/icons/GitHub';
import GoogleIcon from 'components/icons/Google';
import { useFormik } from 'formik';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { boolean, object, string } from 'yup';

import types from 'domains/auth/types';
import { useAppDispatch, useAppSelector } from 'hooks';
import * as ROUTES from 'navigation/routes';

const SignInForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isFetching = useAppSelector((state) => state.auth.isFetching);

  const initialValues = {
    email: '',
    password: '',
    remember: true,
    showPassword: false,
  };

  const validationSchema = object().shape({
    email: string().email(t('formErrors.incorrect_email')).required(),
    password: string()
      .min(6, t('formErrors.password_min_length'))
      .max(32, t('formErrors.password_max_length'))
      .required(),
    remember: boolean(),
    showPassword: boolean(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch({
        type: types.LOGIN_ASYNC,
        payload: {
          email: values.email,
          password: values.password,
          remember: values.remember,
        },
      });
    },
  });

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{ textTransform: 'none', fontWeight: 'bold', mb: '50px' }}
      >
        {t('login')}
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          fullWidth
          margin="normal"
          autoFocus
          id="email"
          name="email"
          label={t('email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />
        <FormControl variant="outlined" margin="normal" sx={{ width: '100%' }}>
          <InputLabel
            htmlFor="password"
            error={formik.touched.password && !!formik.errors.password}
          >
            {t('password')}
          </InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            fullWidth
            autoComplete="on"
            type={formik.values.showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && !!formik.errors.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    formik.setFieldValue(
                      'showPassword',
                      !formik.values.showPassword
                    );
                  }}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {formik.values.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label={t('password')}
          />
          <FormHelperText
            id="outlined-weight-helper-text"
            error={formik.touched.password && !!formik.errors.password}
          >
            {formik.touched.password && formik.errors.password}
          </FormHelperText>
        </FormControl>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs>
            {false && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.remember}
                    onChange={formik.handleChange}
                    name="remember"
                    color="primary"
                  />
                }
                label={t('stay_signed_in')}
              />
            )}
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to={ROUTES.PASSWORD_FORGET}
              variant="text"
              sx={{
                textTransform: 'none',
              }}
            >
              {t('forgotPassword')}
            </Button>
          </Grid>
        </Grid>

        {isFetching && <LinearProgress />}
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={isFetching}
          sx={{
            mt: 2,
            // mb: '170px',
            height: '62px',
            textTransform: 'uppercase',
          }}
        >
          {t('signIn')}
        </Button>
        <Divider sx={{ mt: 2, mb: 2 }}>
          <Chip label="или" />
        </Divider>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: '170px' }}
        >
          {/* <Typography>Or Signup with email</Typography> */}
          <Button
            variant="contained"
            color="inherit"
            component="a"
            href={ROUTES.SIGN_UP_WITH_GOOGLE_URI}
            startIcon={<GoogleIcon fontSize="large" />}
            sx={{
              backgroundColor: '#ffffff',
              color: 'text.secondary',
              textTransform: 'none',
            }}
          >
            Войти с помощью Google
          </Button>
          <Button
            variant="contained"
            color="inherit"
            component="a"
            href={ROUTES.SIGN_UP_WITH_GITHUB_URI}
            startIcon={
              <GitHubIcon
                // fontSize="inherit"
                sx={{ color: '#000000', fontSize: '25px' }}
              />
            }
            sx={{
              // backgroundColor: '#000000',
              // color: 'white',
              color: 'text.secondary',
              textTransform: 'none',
            }}
          >
            Войти с помощью GitHub
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1" component="div">
            {t('doNotHaveAnAccount')}
          </Typography>
          <Button
            component={Link}
            to={ROUTES.SIGNUP}
            variant="text"
            fullWidth={false}
          >
            {t('signUpNow')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SignInForm;
