import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionsType } from 'components/Demo/Settings';
// import { ExcludedChars } from 'components/ExcludeСhars';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';

export interface User {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  emailVerified: boolean;
  isAdmin: boolean;
  provider: string | null;
  providerId: string | null;
  avatarUrl: string | null;
  locale: string | null;
  settings: UserSettings;
}

export interface UserSettings {
  logicalLayout: LogicalLayout | null;
  physicalLayout: PhysicalLayout | null;
  myLayouts: LogicalLayout[];
  options: OptionsType | null;
}

// type ExcludedCharsSettings = { [key in LogicalLayout]?: ExcludedChars };

const initialState: User = {
  firstName: null,
  lastName: null,
  email: null,
  emailVerified: false,
  isAdmin: false,
  provider: null,
  providerId: null,
  avatarUrl: null,
  settings: {
    logicalLayout: null,
    physicalLayout: null,
    myLayouts: [],
    options: null,
  },
  locale: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return action.payload;
    },
    clearUser: (state) => {
      return initialState;
    },
    // TODO: UserSettingsProps , где будут обязательными logicalLayout, physicalLayout ?
    updateUserLayoutSettings: (state, action: PayloadAction<UserSettings>) => {
      return {
        ...state,
        settings: action.payload,
      };
    },
    updateOptions: (state, action: PayloadAction<OptionsType>) => {
      return {
        ...state,
        settings: { ...state.settings, options: action.payload },
      };
    },
    // updateUserExcludedCharsSettings: (
    //   state,
    //   action: PayloadAction<{
    //     logicalLayout: LogicalLayout;
    //     chars: ExcludedChars;
    //   }>
    // ) => {
    //   return {
    //     ...state,
    //     settings: {
    //       ...state.settings,
    //       excludedChars: {
    //         ...state.settings.excludedChars,
    //         [action.payload.logicalLayout]: action.payload.chars,
    //       },
    //     },
    //   };
    // },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, clearUser, updateUserLayoutSettings, updateOptions } =
  userSlice.actions;

export default userSlice.reducer;
