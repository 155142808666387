import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';

import { colemak } from './colemak';
import { dvorak } from './dvorak';
import { jcuken } from './jcuken';
import { us_qwerty } from './us_qwerty';
import { workman } from './workman';

export default function getKeyCapsByLayout(
  logicalLayout: LogicalLayout
): KeyDefinition[] {
  switch (logicalLayout) {
    case 'us_qwerty':
      return us_qwerty;
    case 'dvorak':
      return dvorak;
    case 'colemak':
      return colemak;
    case 'workman':
      return workman;
    case 'jcuken':
      return jcuken;
    default:
      return [];
  }
}
