// Core
import { put } from 'redux-saga/effects';

// Instruments
import authActions from '../../actions';

export function* initialize() {
  yield put(authActions.authenticateAsync());
}

export default initialize;
