// Instruments
import { OptionsType } from 'components/Demo/Settings';
import { UserCredentials } from 'domains/auth/models/UserCredentials';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { Lesson } from 'domains/lessons/models/Lesson';
import { LessonProps } from 'domains/lessons/models/LessonProps';
import { LessonStats } from 'domains/lessons/models/LessonStats';
import { TestResults } from 'domains/test/TestResults';
import { UserSettings } from 'domains/user/UserSettings';

const api = {
  get token() {
    return localStorage.getItem('token');
  },
  auth: {
    register(userCredentials: UserCredentials) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        body: JSON.stringify(userCredentials),
        credentials: 'include',
      });
    },
    login(credentials: { email: string; password: string; remember: string }) {
      // console.log(credentials);

      return fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify(credentials),
      });
    },
    authenticate() {
      return fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        // body: JSON.stringify({token: this.token})
      });
    },
    logout() {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        method: 'GET',
        credentials: 'include',
      });
    },
    forgotPassword({ email }: { email: string }) {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/password/forgot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify({
          email,
        }),
      });
    },
    resetPassword({
      newPassword,
      token,
    }: {
      newPassword: string;
      token: string;
    }) {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/password/reset`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify({
          newPassword,
          token,
        }),
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        if (!response.ok) {
          const data = await response.json();
          // console.log('response error  json', data);
          throw Error(data.message);
        }
      });
    },
    changePassword({
      oldPassword,
      newPassword,
    }: {
      oldPassword: string;
      newPassword: string;
    }) {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/password/change`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify({
          oldPassword,
          newPassword,
        }),
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        if (!response.ok) {
          const data = await response.json();
          // console.log('response error  json', data);
          throw Error(data.message);
        }
      });
    },
  },
  settings: {
    update(userSettings: UserSettings) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/users/settings`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify(userSettings),
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
    updateOptions(options: OptionsType) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/users/options`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify(options),
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        const data = await response.json();
        if (response.ok) {
          return data.data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
  },
  test: {
    sendTestResult(testStats: TestResults) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/testResults`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify(testStats),
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
  },
  lessons: {
    fetch(layout: LogicalLayout) {
      return fetch(
        `${process.env.REACT_APP_API_URL}/api/lessons?logicalLayout=${layout}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...(process.env.NODE_ENV === 'production' && {
              'accept-encoding': 'gzip, deflate, sdch, br',
            }),
          },
          credentials: 'include',
        }
      ).then(async (response) => {
        // console.log('fetch lessons');
        const data = await response.json();
        if (response.ok) {
          return data.data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
    fetchDemoExercise(layout: LogicalLayout) {
      return fetch(
        `${process.env.REACT_APP_API_URL}/api/lessons/demo?logicalLayout=${layout}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...(process.env.NODE_ENV === 'production' && {
              'accept-encoding': 'gzip, deflate, sdch, br',
            }),
          },
          credentials: 'include',
        }
      ).then(async (response) => {
        // console.log('fetch lessons');
        const data = await response.json();
        if (response.ok) {
          return data.data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
    fetchStatistics(logicalLayout: LogicalLayout) {
      return fetch(
        `${process.env.REACT_APP_API_URL}/api/lessons/statistics?logicalLayout=${logicalLayout}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            ...(process.env.NODE_ENV === 'production' && {
              'accept-encoding': 'gzip, deflate, sdch, br',
            }),
          },
          credentials: 'include',
        }
      ).then(async (response) => {
        // console.log('fetch statistics: ', response.ok);
        const data = await response.json();
        if (response.ok) {
          return data.data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
  },
  lesson: {
    fetch(lessonId: string): Promise<Lesson> {
      return fetch(`${process.env.REACT_APP_API_URL}/api/lessons/${lessonId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        const data = await response.json();
        if (response.ok) {
          return data.data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
    createExercise(lesson: LessonProps) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/lessons`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify(lesson),
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        const data = await response.json();
        if (response.ok) {
          return data.data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
    deleteExercise(lessonId: string) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/lessons/${lessonId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        // body: JSON.stringify(lessonId),
      }).then(async (response) => {
        const data = await response.json();
        if (response.ok) {
          return data.data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
    sendLessonStats(lessonStats: LessonStats) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/lessons/statistics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(process.env.NODE_ENV === 'production' && {
            'accept-encoding': 'gzip, deflate, sdch, br',
          }),
        },
        credentials: 'include',
        body: JSON.stringify(lessonStats),
      }).then(async (response) => {
        // console.log('response status: ', response.ok);
        const data = await response.json();
        if (response.ok) {
          return data;
        } else {
          // console.log('response error  json', response.json());
          throw Error(data.message);
        }
      });
    },
  },
};

export default api;
