import { useTheme } from '@emotion/react';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { FC, MouseEvent, useState } from 'react';

export interface ArrowProps {
  text: string;
}

const Helper: FC<ArrowProps> = ({ text }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography
        variant="subtitle2"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpRoundedIcon
          fontSize="small"
          color="inherit"
          sx={{
            cursor: 'pointer',
            color: theme.palette.grey[600],
            ':hover': {
              color: theme.palette.primary.main,
            },
          }}
        />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          m: -1,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        // elevation={2}
        disableRestoreFocus
      >
        <Typography
          sx={{
            p: 2,
            maxWidth: '500px',
            wordWrap: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {text}
        </Typography>
      </Popover>
    </>
  );
};

export default Helper;
