import { FC } from 'react';
import { Helmet } from 'react-helmet';
import SignInForm from 'components/Forms/SignIn';

import Container from '@mui/material/Container';

interface Props {}

const SignInPage: FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Вход - keycap.pro</title>
      </Helmet>
      <Container component="main" maxWidth="xs" sx={{ mt: 12 }}>
        <SignInForm />
      </Container>
    </>
  );
};

export default SignInPage;
