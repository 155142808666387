import Button from '@mui/material/Button';
import { closeSnackbar } from 'domains/notifier/slice';
import { useAppDispatch } from 'hooks';
import { FC } from 'react';

interface Props {
  label?: string;
  notifierKey: string | number;
}

const NotifierCloseButton: FC<Props> = ({ label = 'Закрыть', notifierKey }) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      onClick={() => {
        dispatch(closeSnackbar({ key: notifierKey }));
      }}
      sx={{ color: '#ffffff' }}
    >
      {label}
    </Button>
  );
};

export default NotifierCloseButton;
