import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { typingZonesAnsi } from 'domains/keyboard/typingZones/typingZonesAnsi';
import { typingZonesIso } from 'domains/keyboard/typingZones/typingZonesIso';

export default function getTypingZones(physicalLayout: PhysicalLayout | null) {
  switch (physicalLayout) {
    case 'ansi':
      return typingZonesAnsi;
    case 'iso':
      return typingZonesIso;
    default:
      return typingZonesIso;
  }
}
