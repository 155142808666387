import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends SelectProps<PhysicalLayout> {
  options: PhysicalLayout[];
}

const PhysicalLayoutSelect: FC<Props> = ({ label, options, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="keyboard-select-label">{label}</InputLabel>
        <Select
          labelId="keyboard-select-label"
          id="keyboard-select"
          label={label}
          size="small"
          {...props}
        >
          {options?.map((keyboard) => (
            <MenuItem key={keyboard} value={keyboard}>
              {t(`physicalLayouts.${keyboard}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default PhysicalLayoutSelect;
