import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import Notifier from 'components/Notifier';
import theme from 'theme';
import './i18n';
import store, { history } from './init/store';
import App from './navigation/App';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SnackbarProvider>
          <Suspense fallback="Loading...">
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Notifier />
              <App />
            </ThemeProvider>
          </Suspense>
        </SnackbarProvider>
      </ConnectedRouter>
    </Provider>

    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
);
