import { TestEvent } from 'domains/test/TestEvent';
import { average } from 'utils/average';

export type TestStatsData = {
  totalNumKeystrokes: number;
  numCorrectKeystrokes: number;
  numWrongKeystrokes: number;
  numMissedKeystrokes: number;
  averageReaction: number;
  accuracy: number;
};

export function convertLogToLessonStatsData(logs: TestEvent[]): TestStatsData {
  let numCorrectKeystrokes = 0;
  let numWrongKeystrokes = 0;
  let numMissedKeystrokes = 0;
  const reactions: number[] = [];

  logs
    .filter(
      ({ type }) =>
        type === 'correctKeystroke' ||
        type === 'missedKeystroke' ||
        type === 'wrongKeystroke'
    )
    .forEach(({ type, reaction }) => {
      if (type === 'correctKeystroke') {
        numCorrectKeystrokes += 1;

        reactions.push(reaction as number);
      } else if (type === 'missedKeystroke') {
        numMissedKeystrokes += 1;
      } else if (type === 'wrongKeystroke') {
        numWrongKeystrokes += 1;
      }
    });
  return {
    totalNumKeystrokes: numCorrectKeystrokes + numWrongKeystrokes,
    numCorrectKeystrokes,
    numWrongKeystrokes,
    numMissedKeystrokes,
    averageReaction: average(reactions),
    accuracy:
      numCorrectKeystrokes /
      (numCorrectKeystrokes + numWrongKeystrokes + numMissedKeystrokes),
  };
}
