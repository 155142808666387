import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function Loading(): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50vh',
      }}
    >
      <CircularProgress size={40} />
    </Box>
  );
}

export default Loading;
