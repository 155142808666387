import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardLayoutLabel from 'components/KeyboardSetup/Label';
import { layouts } from 'domains/keyboard/layouts';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { FC } from 'react';

interface Props {
  logicalLayout: LogicalLayout | null;
  physicalLayout: PhysicalLayout | null;
  handleKeyboardSetupDialogOpen: () => void;
}

const KeyboardSetup: FC<Props> = ({
  logicalLayout,
  physicalLayout,
  handleKeyboardSetupDialogOpen,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <KeyboardLayoutLabel
          logicalLayout={logicalLayout}
          physicalLayout={physicalLayout}
          emulated={logicalLayout ? layouts[logicalLayout].emulated : null}
        />

        <Box sx={{ display: 'inline-flex' }}>
          <IconButton
            aria-label="delete"
            size="small"
            title="keyboard setup"
            onClick={handleKeyboardSetupDialogOpen}
            disableRipple
            disableFocusRipple
          >
            <SettingsIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default KeyboardSetup;
