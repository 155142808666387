import getKeysBy from 'domains/keyboard/logicalLayouts/getLogicalLayoutById';

export default function getKey(
  code: string,
  logicalLayout: string,
  shift: boolean
) {
  return getKeysBy(logicalLayout)?.find(
    (keyObj) => keyObj.code === code && keyObj.shiftKey === shift
  )?.key;
}
