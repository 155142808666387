import { RootState } from 'init/store';
import { createSelector } from 'reselect';

export const userSelector = (state: RootState) => state.user;

export const displayNameSelector = createSelector(userSelector, (user) => {
  return `${user.firstName ? user.firstName : ''} ${
    user.lastName ? user.lastName : ''
  }`;
});
