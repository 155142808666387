import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { ansi } from 'domains/keyboard/physicalLayouts/ansi';
import { iso } from 'domains/keyboard/physicalLayouts/iso';

export default function getPhysicalLayoutById(
  physicalLayoutId: PhysicalLayout
) {
  switch (physicalLayoutId) {
    case 'ansi':
      return ansi;
    case 'iso':
      return iso;
  }
}
