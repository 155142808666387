import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import SVGKeyboard from 'components/SVGKeyboard';
import StyledDialog from 'components/StyledDialog';
import StyledDialogTitle from 'components/StyledTitle';
import {
  LayoutLanguage,
  layoutLanguages,
} from 'domains/keyboard/LayoutLangage';
import { layouts } from 'domains/keyboard/layouts';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { UserSettings } from 'domains/user/UserSettings';
import LayoutLanguageSelect from './selects/LayoutLanguageSelect';
import LogicalLayoutSelect from './selects/LogicalLayoutSelect';
import PhysicalLayoutSelect from './selects/PhysicalLayoutSelect';

export interface KeyboardSetupDialogValues {
  language: LayoutLanguage;
  logicalLayout: LogicalLayout;
  physicalLayout: PhysicalLayout;
}

interface Props {
  open: boolean;
  onSubmit: (layout: UserSettings) => void;
  onClose: (layout: UserSettings) => void;
  physicalLayout: PhysicalLayout | null;
  logicalLayout: LogicalLayout | null;
  isLoading: boolean;
}
const defaultLayout = layouts.us_qwerty;

const KeyboardSetupDialog: FC<Props> = ({
  open,
  physicalLayout,
  logicalLayout,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();

  const initialValues: KeyboardSetupDialogValues =
    physicalLayout && logicalLayout
      ? {
          physicalLayout,
          logicalLayout,
          language: layouts[logicalLayout].language,
        }
      : {
          language: defaultLayout.language,
          logicalLayout: 'us_qwerty',
          physicalLayout: defaultLayout.availablePhysicalLayouts[0],
        };

  const validationSchema = object().shape({
    language: string().required(),
    logicalLayout: string().required(),
    physicalLayout: string().required(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onSubmit({
        logicalLayout: values.logicalLayout,
        physicalLayout: values.physicalLayout,
      });
    },
    validationSchema,
    enableReinitialize: true,
  });

  const [logicalLayoutOptions, setLogicalLayoutOptions] = useState(
    getKeyByOptionValue(layouts, 'language', formik.values.language)
  );

  const [physicalLayoutOptions, setPhysicalLayoutOptions] = useState(
    layouts[formik.values.logicalLayout].availablePhysicalLayouts
  );

  useEffect(() => {
    const physicalLayouts =
      layouts[formik.values.logicalLayout].availablePhysicalLayouts;
    setPhysicalLayoutOptions(physicalLayouts);

    formik.setFieldValue(
      'physicalLayout',
      physicalLayouts.includes(formik.values.physicalLayout)
        ? physicalLayouts.find(
            (keyboard) => keyboard === formik.values.physicalLayout
          )
        : physicalLayouts[0]
    );
  }, [formik.values.logicalLayout]);

  useEffect(() => {
    const logicalLayouts = getKeyByOptionValue(
      layouts,
      'language',
      formik.values.language
    ) as LogicalLayout[];
    setLogicalLayoutOptions(logicalLayouts);
    //TODO: хук срабатывает при реинициализации, поэтому добавлено условие ниже ,
    // подумать, как сделать по другому или сделать как в хуке выше
    if (!logicalLayouts.includes(formik.values.logicalLayout)) {
      formik.setFieldValue('logicalLayout', logicalLayouts[0]);
    }
  }, [formik.values.language]);

  const handleClose = () => {
    onClose({
      logicalLayout: formik.values.logicalLayout,
      physicalLayout: formik.values.physicalLayout,
    });
  };

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="keyboard-setup-dialog"
      open={open}
      onSubmit={() => {
        formik.handleSubmit();
      }}
      maxWidth="lg"
      fullWidth
    >
      <StyledDialogTitle id="keyboard-setup-dialog-title" onClose={handleClose}>
        {t('keyboardSetup')}
      </StyledDialogTitle>
      <DialogContent dividers>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              gap: 3,
              width: '100%',
              mb: 2,
            }}
          >
            <LayoutLanguageSelect
              name="language"
              label={t('labels.language')}
              value={formik.values.language}
              onChange={formik.handleChange}
              options={[...layoutLanguages]}
            />
            <LogicalLayoutSelect
              name="logicalLayout"
              label={t('labels.layout')}
              value={formik.values.logicalLayout}
              onChange={formik.handleChange}
              options={[...logicalLayoutOptions]}
            />
            <PhysicalLayoutSelect
              name="physicalLayout"
              label={t('labels.keyboard')}
              value={formik.values.physicalLayout}
              onChange={formik.handleChange}
              options={physicalLayoutOptions}
            />
          </Box>
          <Box sx={{ width: '80%', margin: '0 auto' }}>
            <SVGKeyboard
              physicalLayout={formik.values.physicalLayout}
              logicalLayout={formik.values.logicalLayout}
              //colorSchema
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={isLoading}
          type="submit"
          sx={{ minWidth: '140px', textAlign: 'center' }}
        >
          {isLoading ? '...' : t('confirm')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default KeyboardSetupDialog;

function getKeyByOptionValue(
  object: any,
  option: string,
  value: string
): string[] {
  return (
    Object.keys(object).filter((key) => object[key][option] === value) ?? []
  );
}
