import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Component } from 'react';

export default class Catcher extends Component {
  state = {
    error: false,
  };

  componentDidCatch(error, stack) {
    // console.log('ERROR:', error.message);
    // console.log('STACKTRACE:', stack.componentStack);

    this.setState({
      error: true,
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <Box
          component="section"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: '10px',
            p: '12px',
            // border: '2px solid #ede',
            borderRadius: '3px',
            // backgroundColor: '#ede',
            textAlign: ' center',
            m: '0 auto',
          }}
        >
          <Typography component="span" variant="subtitle1" sx={{}}>
            ❌ Произошла ошибка.
          </Typography>

          <Typography component="span" variant="subtitle1" sx={{ mb: 2 }}>
            Наша команда уже работает над тем, чтобы исправить это!
          </Typography>
          <Typography component="span" variant="h5">
            🐱‍💻
          </Typography>
        </Box>
      );
    }

    return children;
  }
}
