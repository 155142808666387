import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Catcher from 'components/Catcher';
import KeyboardSetup from 'components/KeyboardSetup';
import KeyboardSetupDialog from 'components/KeyboardSetup/Dialog/Dialog';
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import SpeedTest from 'components/SpeedTest';
import Stats from 'components/Stats';
import StyledDialog from 'components/StyledDialog';
import TestResultDialog from 'components/TestResultDialog';
import TestSettings from 'components/TestSettings';
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import getKeysBy from 'domains/keyboard/logicalLayouts/getLogicalLayoutById';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { enqueueSnackbar } from 'domains/notifier/slice';
import { converLogsToTestResults } from 'domains/test/convertLogsToTestResults';
import { TestResults } from 'domains/test/TestResults';
import {
  logicalLayoutSelector,
  physicalLayoutSelector,
} from 'domains/user/selectors/settings';
import { updateUserLayoutSettings } from 'domains/user/slice';
import { useAppDispatch, useAppSelector } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import useTest from 'hooks/useTest';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import api from 'REST/api';
import { convertLogToTestStatsData } from 'utils/convertLogToTestStatsData';

interface Props {}

const TestPage: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const physicalLayout: PhysicalLayout | null = useAppSelector(
    physicalLayoutSelector
  );
  const logicalLayout: LogicalLayout | null = useAppSelector(
    logicalLayoutSelector
  );

  const [localPhysicalLayout, setPhysicalLayoutLocalStorage] =
    useLocalStorage<PhysicalLayout | null>('physicalLayout', null);

  const [localLogicalLayout, setLogicalLayoutLocalStorage] =
    useLocalStorage<LogicalLayout | null>('logicalLayout', null);

  const globalLogicalLayout: LogicalLayout | null = useMemo(
    () => (isAuthenticated ? logicalLayout : localLogicalLayout),
    [isAuthenticated, logicalLayout, localLogicalLayout]
  );
  const globalPhysicalLayout: PhysicalLayout | null = useMemo(
    () => (isAuthenticated ? physicalLayout : localPhysicalLayout),
    [isAuthenticated, physicalLayout, localPhysicalLayout]
  );

  const [keyboardSetupDialogOpen, setKeyboardSetupDialogOpen] = useState(false);

  const handleKeyboardSetupDialogOpen = () => {
    setKeyboardSetupDialogOpen(true);
  };

  const handleKeyboardSetupDialogClose = () => {
    setKeyboardSetupDialogOpen(false);
  };

  useEffect(() => {
    if (!globalLogicalLayout || !globalPhysicalLayout) {
      handleKeyboardSetupDialogOpen();
    }
  }, []);

  //TODO: in F? сохранять настройки теста для каждой раскладки в профиле пользователя
  const [testOptions, setTestOptions] = useState({
    hasLetters: true,
    hasDigits: true,
    hasSymbols: true,
    caseSensitive: false,
    taktTime: 2000, //
    delayTime: 200, //
  });

  const isNotPrepared = useMemo(
    () =>
      (!testOptions.hasDigits &&
        !testOptions.hasLetters &&
        !testOptions.hasSymbols) ||
      !globalLogicalLayout,
    [testOptions, globalLogicalLayout]
  );

  const getKeySet = useCallback((): KeyDefinition[] => {
    const { hasLetters, hasDigits, hasSymbols, caseSensitive } = testOptions;

    return globalLogicalLayout
      ? getKeysBy(globalLogicalLayout).filter(
          (key) =>
            (hasLetters &&
              key.type === 'letter' &&
              (caseSensitive ? true : key.shiftKey === false)) ||
            (hasDigits && key.type === 'digit') ||
            (hasSymbols && key.type === 'symbol')
        )
      : [];
  }, [testOptions, globalLogicalLayout]);

  const [set, setSet] = useState<KeyDefinition[]>(getKeySet());

  useEffect(() => {
    setSet(getKeySet());
  }, [testOptions, logicalLayout]);

  const {
    logs,
    seconds,
    isStarted,
    isPaused,
    isFocused,
    isFinished,
    testedKey,
    progress,
    reset,
    handleKeyPress,
    handleFocus,
    handleBlur,
  } = useTest(set, globalLogicalLayout);

  // const set = useMemo(getSet(logicalLayout, testOptions: {digits: true, letters: false}), [logicalLayout, testOptions]);
  // const { testedKey, logs, seconds, isStarted, isFinished, progress, reset } = useTest(set, options: {digits: true, letters: false})
  // const { handleKeyPress, handleFocus, handleBlur } = useKeyboard(testedKey,
  // onCorrectKeystroke, onWrongKeystroke, onMissedKeystroke, onBlot,
  // onKeyPress, onFocus, onBlur
  // options: {virtualize: false});

  const [open, setOpen] = useState(false);
  const [statsInfo, setStatsInfo] = useState<TestResults>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    if (isFinished) {
      const testStats = converLogsToTestResults(
        set,
        logs,
        testOptions,
        globalLogicalLayout,
        globalPhysicalLayout
      );
      isAuthenticated && mutate(testStats);
      setStatsInfo(testStats);
      handleOpen();
    }
  }, [isFinished]);

  // отправка статистики
  const { mutate, isLoading } = useMutation(api.test.sendTestResult, {
    onSuccess: () => {
      // Инвалидация и обновление
      // console.log('TEST STATS SUCCESS');
    },
    onError: (error) => {
      dispatch(
        enqueueSnackbar(
          { message: error.message },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        )
      );
    },
  });

  const { mutate: keyboardSetupMutate, isLoading: isKeyboardSetupLoading } =
    useMutation(api.settings.update, {
      onSuccess: async (data, variables) => {
        dispatch(updateUserLayoutSettings(data));

        handleKeyboardSetupDialogClose();
      },
      onError: (error) => {
        // console.log('ERROR, ', error);
        dispatch(
          enqueueSnackbar(
            { message: error.message },
            {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              action: (key) => <NotifierCloseButton notifierKey={key} />,
            }
          )
        );
      },
    });

  return (
    <Catcher>
      <Helmet>
        <title>Тестирование - keycap.pro</title>
      </Helmet>
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{}}>
            <Typography
              variant="h4"
              align="center"
              component="h1"
              display="flex"
              sx={{ justifyContent: 'center' }}
            >
              {t('tesitng')}
            </Typography>
          </Box>

          <KeyboardSetup
            logicalLayout={globalLogicalLayout}
            physicalLayout={globalPhysicalLayout}
            handleKeyboardSetupDialogOpen={handleKeyboardSetupDialogOpen}
          />
        </Box>

        {(!globalPhysicalLayout || !globalLogicalLayout) && (
          <Box sx={{ mt: 1 }}>
            <Alert severity="error">{t('initialKeyboardSetupWarning')}</Alert>
          </Box>
        )}

        <Box sx={{ ml: 3 }}>
          <TestSettings
            onKeySetChanged={(values) => {
              setTestOptions((prevState) => ({ ...prevState, ...values }));
              // setSet(getKeySet(layout?.logical, values));
            }}
            disabled={
              isStarted || !globalPhysicalLayout || !globalLogicalLayout
            }
            sx={{ mb: 2 }}
          />
        </Box>

        <SpeedTest
          isStarted={isStarted}
          isPaused={isPaused}
          isFocused={isFocused}
          testedKey={testedKey}
          testProgress={progress}
          reset={reset}
          isPrepared={!isNotPrepared}
          showXHeight={testOptions.caseSensitive && testOptions.hasLetters}
          input={
            <input
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,
                fontSize: 0,
                cursor:
                  isFocused && isStarted && !isPaused ? 'none' : 'pointer',
              }}
              onKeyDown={handleKeyPress}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onFocus={isNotPrepared ? () => {} : handleFocus}
              onBlur={handleBlur}
            />
          }
          disabled={!globalPhysicalLayout || !globalLogicalLayout}
        />

        <Stats {...convertLogToTestStatsData(logs)} />
      </Container>

      <StyledDialog
        onClose={handleClose}
        aria-labelledby="test-result-dialog"
        open={open}
        onSubmit={handleClose}
        maxWidth="md"
        fullWidth
      >
        <TestResultDialog
          onClose={handleClose}
          onSubmit={handleClose}
          statsInfo={statsInfo}
        />
      </StyledDialog>

      <KeyboardSetupDialog
        open={keyboardSetupDialogOpen}
        physicalLayout={globalPhysicalLayout}
        logicalLayout={globalLogicalLayout}
        onClose={handleKeyboardSetupDialogClose}
        onSubmit={(values) => {
          if (isAuthenticated) {
            keyboardSetupMutate(values);
          } else {
            setLogicalLayoutLocalStorage(values.logicalLayout);
            setPhysicalLayoutLocalStorage(values.physicalLayout);
            handleKeyboardSetupDialogClose();
          }
          reset();
        }}
        isLoading={isKeyboardSetupLoading}
      />
    </Catcher>
  );
};

export default TestPage;
