import Box from '@mui/material/Box';
import ansiLayout from 'components/SVGKeyboard/ansiLayout';
import Inner from 'components/SVGLessonKeyboard/Inner';
import isoLayout from 'components/SVGKeyboard/isoLayout';
import Key from 'components/SVGLessonKeyboard/Key';
import Rect from 'components/SVGLessonKeyboard/Rect';
import Text from 'components/SVGLessonKeyboard/Text';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import getKeyCapsByLayout from 'domains/keyboard/logicalLayouts/v2/getKeyCapsByLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { useMemo } from 'react';
import IsoEnter from 'components/SVGKeyboard/IsoEnter';
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';

const colors = {
  0: '#f4b3c2',
  1: '#fdcf8c',
  2: '#bcdd9c',
  3: '#a3dce0',
  4: '#aec6e0',
  5: '#aec6e0',
  6: '#a3dce0',
  7: '#bcdd9c',
  8: '#fdcf8c',
  9: '#f4b3c2',
};

interface Props {
  pressedKeys: Set<string>;
  testedKey?: KeyDefinition;
  physicalLayout: PhysicalLayout;
  logicalLayout: LogicalLayout;
  typingZones: any;
  isBlindly: boolean;
}

function SVGKeyboard({
  logicalLayout,
  physicalLayout,
  pressedKeys,
  testedKey,
  isBlindly,
  typingZones,
}: Props): JSX.Element {
  const keyCaps = useMemo(
    () => getKeyCapsByLayout(logicalLayout),
    [logicalLayout]
  );

  const viewBox = [0, 0, 715, 204];
  return (
    <Box
      component="svg"
      preserveAspectRatio="xMaxYMax none"
      viewBox={`${viewBox}`}
      sx={{
        aspectRatio: '715 / 204',
        contain: 'strict',
        display: 'block',
        width: '100%',
        height: '100%',
      }}
    >
      <Rect x={0} y={0} rx={9} ry={9} width={715} height={204} fill="#eeeeee" />
      <Inner x={5} y={10}>
        {(physicalLayout === 'iso'
          ? isoLayout?.slice(0, -1)
          : ansiLayout?.slice(0, -1)
        ).map((row, rowIndex) => {
          let widthAdder = 0;

          return row.map(({ id, width }, index) => {
            widthAdder +=
              width +
              (id === 'KeyN' ||
              id === 'KeyH' ||
              id === 'KeyY' ||
              id === 'Digit7'
                ? 63
                : 0);
            const isVisible =
              id === 'ShiftLeft' ||
              id === 'ShiftRight' ||
              id === 'Space' ||
              keyCaps[id].defaultKey.type !== 'sys';

            const glyph =
              !isBlindly && testedKey && testedKey?.code === id
                ? testedKey.shiftKey
                  ? keyCaps[id].shiftKey?.key
                  : keyCaps[id].defaultKey?.key
                : undefined;

            return id === 'Enter' && physicalLayout === 'iso' ? (
              <IsoEnter
                id={id}
                key={index}
                x={widthAdder - width + 8 * index}
                y={48 * rowIndex}
                height={40}
                lowerGlyph={keyCaps[id].defaultKey?.key}
                width={width}
                fill={'#e3e3e1'}
                isVisible={false}
              />
            ) : (
              <Key
                key={`${id}_${index}`}
                id={id}
                x={widthAdder - width + 3 * index}
                y={46 * rowIndex}
                width={width}
                height={40}
                fill={isVisible ? colors[typingZones[id]] : undefined}
                glyph={glyph}
                // keyCaps[id].shiftKey?.key keyCaps[id].defaultKey?.key}
                homing={id === 'KeyF' || id === 'KeyJ'} // потом по настройка пользователя
                isVisible={isVisible}
                isWrong={
                  keyCaps[id].defaultKey.type !== 'sys' && pressedKeys?.has(id)
                }
                isModifierKey={
                  !isBlindly &&
                  !!testedKey?.shiftKey &&
                  (typingZones[testedKey.code] > 4
                    ? id === 'ShiftLeft'
                    : id === 'ShiftRight')
                }
              />
            );
          });
        })}

        <Box id={'blind'} component="svg" x={295} y={78} overflow="visible">
          <Rect
            x={0}
            y={0}
            rx={2}
            ry={2}
            width={40}
            height={40}
            fill={'#eeeeee'}
            // opacity="1"
            stroke={'#a1a1a1'}
            strokeWidth="0.2"
            overflow="visible"
          />
          {isBlindly && (
            <Text
              x={40 / 2}
              y={40 / 2}
              dominantBaseline="middle"
              textAnchor="middle"
              textRendering="optimizeLegibility"
              overflow="visible"
              sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 700,
                cursor: 'default',
                userSelect: 'none',
              }}
            >
              {testedKey?.key}
            </Text>
          )}
        </Box>
      </Inner>
    </Box>
  );
}

export default SVGKeyboard;
