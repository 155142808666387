// Core
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import { startFetching, stopFetching } from 'drafts/auth/slice';
// Actions
import { enqueueSnackbar } from 'domains/notifier/slice';
import { apply, put } from 'redux-saga/effects';
// Instruments
import api from '../../../../REST/api';

function* changePassword({ payload: { oldPassword, newPassword } }) {
  try {
    yield put(startFetching());
    // console.log({ oldPassword, newPassword });
    yield apply(api, api.auth.changePassword, [{ oldPassword, newPassword }]);
  } catch (error) {
    yield put(
      enqueueSnackbar(
        { message: error.message },
        {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: (key) => <NotifierCloseButton notifierKey={key} />,
        }
      )
    );
  } finally {
    yield put(stopFetching());
  }
}

export default changePassword;
