import { Fingers } from 'domains/keyboard/typingZones/Fingers';

export const typingZonesIso = {
  Backquote: Fingers.LEFT_PINKIE,
  Digit1: Fingers.LEFT_PINKIE,
  Digit2: Fingers.LEFT_RING,
  Digit3: Fingers.LEFT_MIDDLE,
  Digit4: Fingers.LEFT_INDEX,
  Digit5: Fingers.LEFT_INDEX,
  Digit6: Fingers.RIGHT_INDEX,
  Digit7: Fingers.RIGHT_INDEX,
  Digit8: Fingers.RIGHT_MIDDLE,
  Digit9: Fingers.RIGHT_RING,
  Digit0: Fingers.RIGHT_PINKIE,
  Minus: Fingers.RIGHT_PINKIE,
  Equal: Fingers.RIGHT_PINKIE,
  Backspace: Fingers.RIGHT_PINKIE,

  // Row
  Tab: Fingers.LEFT_PINKIE,
  KeyQ: Fingers.LEFT_PINKIE,
  KeyW: Fingers.LEFT_RING,
  KeyE: Fingers.LEFT_MIDDLE,
  KeyR: Fingers.LEFT_INDEX,
  KeyT: Fingers.LEFT_INDEX,
  KeyY: Fingers.RIGHT_INDEX,
  KeyU: Fingers.RIGHT_INDEX,
  KeyI: Fingers.RIGHT_MIDDLE,
  KeyO: Fingers.RIGHT_RING,
  KeyP: Fingers.RIGHT_PINKIE,
  BracketLeft: Fingers.RIGHT_PINKIE,
  BracketRight: Fingers.RIGHT_PINKIE,
  Enter: Fingers.RIGHT_PINKIE,

  // Row
  CapsLock: Fingers.LEFT_PINKIE,
  KeyA: Fingers.LEFT_PINKIE,
  KeyS: Fingers.LEFT_RING,
  KeyD: Fingers.LEFT_MIDDLE,
  KeyF: Fingers.LEFT_INDEX,
  KeyG: Fingers.LEFT_INDEX,
  KeyH: Fingers.RIGHT_INDEX,
  KeyJ: Fingers.RIGHT_INDEX,
  KeyK: Fingers.RIGHT_MIDDLE,
  KeyL: Fingers.RIGHT_RING,
  Semicolon: Fingers.RIGHT_PINKIE,
  Quote: Fingers.RIGHT_PINKIE,
  Backslash: Fingers.RIGHT_PINKIE,
  // 'Enter': ,

  // Row
  ShiftLeft: Fingers.LEFT_PINKIE,
  IntlBackslash: Fingers.LEFT_PINKIE,
  KeyZ: Fingers.LEFT_PINKIE,
  KeyX: Fingers.LEFT_RING,
  KeyC: Fingers.LEFT_MIDDLE,
  KeyV: Fingers.LEFT_INDEX,
  KeyB: Fingers.LEFT_INDEX,
  KeyN: Fingers.RIGHT_INDEX,
  KeyM: Fingers.RIGHT_INDEX,
  Comma: Fingers.RIGHT_MIDDLE,
  Period: Fingers.RIGHT_RING,
  Slash: Fingers.RIGHT_PINKIE,
  ShiftRight: Fingers.RIGHT_PINKIE,

  // Row
  ControlLeft: Fingers.LEFT_PINKIE,
  //    'MetaLeft': ,
  AltLeft: Fingers.LEFT_MIDDLE,
  Space: Fingers.RIGHT_THUMB || Fingers.LEFT_THUMB,
  AltRight: Fingers.RIGHT_RING,
  //    'MetaRight': ,
  //  'ContextMenu': ,
  ControlRight: Fingers.RIGHT_PINKIE,
};
