import Box from '@mui/material/Box';
import { FC, memo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Row: FC<Props> = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '.5%',
        flex: 'auto',
        aspectRatio: '645/40',
        justifyContent: 'flex-start',
        '&:not(:last-child)': {
          mb: '.5%',
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default memo(Row);
