import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PointerCursorIcon from 'components/icons/PointerCursor';
import Keycap from 'components/Keycap/Keycap';
import TestArrow from 'components/TestArrow';
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isPrepared: boolean;
  isStarted: boolean;
  isPaused: boolean;
  isFocused: boolean;
  testedKey: KeyDefinition | null;
  testProgress: number;
  // containerRef: RefObject<HTMLInputElement>;
  reset: () => void;
  input: ReactElement<HTMLInputElement>;
  disabled: boolean;
  showXHeight: boolean;
}

const SpeedTest: FC<Props> = ({
  isPrepared,
  isStarted,
  isPaused,
  isFocused,
  testedKey,
  // containerRef,
  testProgress,
  reset,
  input,
  disabled,
  showXHeight,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '50px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          mr: 1,
          mb: 10,
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <LinearProgress variant="determinate" value={testProgress} />
        </Box>

        <Box sx={{ display: 'inline-flex' }}>
          <IconButton
            // sx={{ transform: 'scale(-1, 1)' }}
            aria-label="reset "
            size="medium"
            title="Сбросить прогресс"
            onClick={reset}
          >
            <RefreshIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
            width: '128px',
            height: '128px',
            // width: 328,
            // height: 328,
          },
          mb: 2,
        }}
        // ref={containerRef}
      >
        {input}
        <Keycap
          testedKey={testedKey?.key}
          showXHeight={showXHeight && isFocused}
        />
        <Box sx={{ position: 'absolute', pointerEvents: 'none' }}>
          {!isFocused && !disabled && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: 'auto',
                textAlign: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'fit-content',
                cursor: 'pointer',
              }}
            >
              {isPrepared ? (
                <>
                  <PointerCursorIcon sx={{ width: '48px', height: '48px' }} />
                </>
              ) : (
                <ErrorOutlineIcon color="error" fontSize="large" />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '150px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 'auto',
          }}
        >
          {isPrepared && !isFocused ? (
            <>
              <TestArrow />
              <Typography
                variant="subtitle1"
                component="p"
                color="textSecondary"
              >
                {`${t('toActivate')} `}
                <b>{t('activate')}</b>
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="subtitle1"
                component="p"
                color="textSecondary"
              >
                {`${t('toStart')} `}
                <b>{t('startContinue')}</b>
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                color="textSecondary"
              >
                {`${t('toPause')} `}
                <b>{t('pause')}</b>
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SpeedTest;
