/**
 * https://w3c.github.io/uievents-code/#code-value-tables
 */

export const workman = {
  Backquote: {
    defaultKey: { key: '`', type: 'symbol' },
    shiftKey: { key: '~', type: 'symbol' },
  },
  Digit1: {
    defaultKey: { key: '1', type: 'digit' },
    shiftKey: { key: '!', type: 'symbol' },
  },
  Digit2: {
    defaultKey: { key: '2', type: 'digit' },
    shiftKey: { key: '@', type: 'symbol' },
  },
  Digit3: {
    defaultKey: { key: '3', type: 'digit' },
    shiftKey: { key: '#', type: 'symbol' },
  },
  Digit4: {
    defaultKey: { key: '4', type: 'digit' },
    shiftKey: { key: '$', type: 'symbol' },
  },
  Digit5: {
    defaultKey: { key: '5', type: 'digit' },
    shiftKey: { key: '%', type: 'symbol' },
  },
  Digit6: {
    defaultKey: { key: '6', type: 'digit' },
    shiftKey: { key: '^', type: 'symbol' },
  },
  Digit7: {
    defaultKey: { key: '7', type: 'digit' },
    shiftKey: { key: '&', type: 'symbol' },
  },
  Digit8: {
    defaultKey: { key: '8', type: 'digit' },
    shiftKey: { key: '*', type: 'symbol' },
  },
  Digit9: {
    defaultKey: { key: '9', type: 'digit' },
    shiftKey: { key: '(', type: 'symbol' },
  },
  Digit0: {
    defaultKey: { key: '0', type: 'digit' },
    shiftKey: { key: ')', type: 'symbol' },
  },
  Minus: {
    defaultKey: { key: '-', type: 'symbol' },
    shiftKey: { key: '_', type: 'symbol' },
  },
  Equal: {
    defaultKey: { key: '=', type: 'symbol' },
    shiftKey: { key: '+', type: 'symbol' },
  },
  Backspace: { defaultKey: { key: 'Backspace', type: 'sys' } },
  Tab: { defaultKey: { key: 'Tab', type: 'sys' } },
  KeyQ: {
    defaultKey: { key: 'q', type: 'letter' },
    shiftKey: { key: 'Q', type: 'letter' },
  },
  KeyW: {
    defaultKey: { key: 'd', type: 'letter' },
    shiftKey: { key: 'D', type: 'letter' },
  },
  KeyE: {
    defaultKey: { key: 'r', type: 'letter' },
    shiftKey: { key: 'R', type: 'letter' },
  },
  KeyR: {
    defaultKey: { key: 'w', type: 'letter' },
    shiftKey: { key: 'W', type: 'letter' },
  },
  KeyT: {
    defaultKey: { key: 'b', type: 'letter' },
    shiftKey: { key: 'B', type: 'letter' },
  },
  KeyY: {
    defaultKey: { key: 'j', type: 'letter' },
    shiftKey: { key: 'J', type: 'letter' },
  },
  KeyU: {
    defaultKey: { key: 'f', type: 'letter' },
    shiftKey: { key: 'F', type: 'letter' },
  },
  KeyI: {
    defaultKey: { key: 'u', type: 'letter' },
    shiftKey: { key: 'U', type: 'letter' },
  },
  KeyO: {
    defaultKey: { key: 'p', type: 'letter' },
    shiftKey: { key: 'P', type: 'letter' },
  },
  KeyP: {
    defaultKey: { key: ';', type: 'symbol' },
    shiftKey: { key: ':', type: 'symbol' },
  },
  BracketLeft: {
    defaultKey: { key: '[', type: 'symbol' },
    shiftKey: { key: '{', type: 'symbol' },
  },
  BracketRight: {
    defaultKey: { key: ']', type: 'symbol' },
    shiftKey: { key: '}', type: 'symbol' },
  },
  Backslash: {
    defaultKey: { key: '\\', type: 'symbol' },
    shiftKey: { key: '|', type: 'symbol' },
    alternate: 'IntlBackslash',
  },
  CapsLock: { defaultKey: { key: 'CapsLock', type: 'sys' } },
  KeyA: {
    defaultKey: { key: 'a', type: 'letter' },
    shiftKey: { key: 'A', type: 'letter' },
  },
  KeyS: {
    defaultKey: { key: 's', type: 'letter' },
    shiftKey: { key: 'S', type: 'letter' },
  },
  KeyD: {
    defaultKey: { key: 'h', type: 'letter' },
    shiftKey: { key: 'H', type: 'letter' },
  },
  KeyF: {
    defaultKey: { key: 't', type: 'letter' },
    shiftKey: { key: 'T', type: 'letter' },
  },
  KeyG: {
    defaultKey: { key: 'g', type: 'letter' },
    shiftKey: { key: 'G', type: 'letter' },
  },
  KeyH: {
    defaultKey: { key: 'y', type: 'letter' },
    shiftKey: { key: 'Y', type: 'letter' },
  },
  KeyJ: {
    defaultKey: { key: 'n', type: 'letter' },
    shiftKey: { key: 'N', type: 'letter' },
  },
  KeyK: {
    defaultKey: { key: 'e', type: 'letter' },
    shiftKey: { key: 'E', type: 'letter' },
  },
  KeyL: {
    defaultKey: { key: 'o', type: 'letter' },
    shiftKey: { key: 'O', type: 'letter' },
  },
  Semicolon: {
    defaultKey: { key: 'i', type: 'letter' },
    shiftKey: { key: 'I', type: 'letter' },
  },
  Quote: {
    defaultKey: { key: "'", type: 'symbol' },
    shiftKey: { key: '"', type: 'symbol' },
  },

  Enter: { defaultKey: { key: 'Enter', type: 'sys' } },
  ShiftLeft: { defaultKey: { key: 'Shift', type: 'sys' } },
  IntlBackslash: {
    defaultKey: { key: '\\', type: 'symbol' },
    shiftKey: { key: '|', type: 'symbol' },
    alternate: 'Backslash',
  },
  KeyZ: {
    defaultKey: { key: 'z', type: 'letter' },
    shiftKey: { key: 'Z', type: 'letter' },
  },
  KeyX: {
    defaultKey: { key: 'x', type: 'letter' },
    shiftKey: { key: 'X', type: 'letter' },
  },
  KeyC: {
    defaultKey: { key: 'm', type: 'letter' },
    shiftKey: { key: 'M', type: 'letter' },
  },
  KeyV: {
    defaultKey: { key: 'c', type: 'letter' },
    shiftKey: { key: 'C', type: 'letter' },
  },
  KeyB: {
    defaultKey: { key: 'v', type: 'letter' },
    shiftKey: { key: 'V', type: 'letter' },
  },
  KeyN: {
    defaultKey: { key: 'k', type: 'letter' },
    shiftKey: { key: 'K', type: 'letter' },
  },
  KeyM: {
    defaultKey: { key: 'l', type: 'letter' },
    shiftKey: { key: 'L', type: 'letter' },
  },
  Comma: {
    defaultKey: { key: ',', type: 'symbol' },
    shiftKey: { key: '<', type: 'symbol' },
  },
  Period: {
    defaultKey: { key: '.', type: 'symbol' },
    shiftKey: { key: '>', type: 'symbol' },
  },
  Slash: {
    defaultKey: { key: '/', type: 'symbol' },
    shiftKey: { key: '?', type: 'symbol' },
  },
  ShiftRight: { defaultKey: { key: 'Shift', type: 'sys' } },
  ControlLeft: { defaultKey: { key: 'Ctrl', type: 'sys' } },
  MetaLeft: { defaultKey: { key: '', type: 'sys' } },
  AltLeft: { defaultKey: { key: 'Alt', type: 'sys' } },
  Space: { defaultKey: { key: '', type: 'sys' } },
  AltRight: { defaultKey: { key: 'Alt', type: 'sys' } },
  MetaRight: { defaultKey: { key: 'Meta', type: 'sys' } },
  ContextMenu: { defaultKey: { key: 'ContextMenu', type: 'sys' } },
  ControlRight: { defaultKey: { key: 'Ctrl', type: 'sys' } },
};
