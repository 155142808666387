// MIN = Minimum expected value
// MAX = Maximium expected value
// Function to normalise the values (MIN / MAX could be integrated)
const normalisePogressValue = (
  value: number,
  maxValue: number,
  minValue = 0
): number => ((value - minValue) * 100) / (maxValue - minValue);

export default normalisePogressValue;
