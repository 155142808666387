import Box, { BoxProps } from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, object, ValidationError } from 'yup';

interface Props extends BoxProps {
  disabled: boolean;
  onKeySetChanged: ({
    hasLetters,
    hasDigits,
    hasSymbols,
    caseSensitive,
  }: {
    hasLetters: boolean;
    hasDigits: boolean;
    hasSymbols: boolean;
    caseSensitive: boolean;
  }) => void;
}

const TestSettings: FC<Props> = ({ disabled, onKeySetChanged, ...props }) => {
  const { t } = useTranslation();

  const initialValues = {
    letters: true,
    digits: true,
    symbols: true,
    caseSensitive: false,
  };
  // TODO: atLeastOneRequired FormikErrors type
  const validationSchema = object()
    .shape({
      letters: boolean(),
      digits: boolean(),
      symbols: boolean(),
      caseSensitive: boolean(),
    })
    .test('atLeastOneRequired', '', (values) => {
      if (values.letters || values.digits || values.symbols) {
        return true; // everything is fine
      }

      return new ValidationError(
        'Please check one checkbox',
        null,
        'atLeastOneRequired'
      );
    });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
    },
  });

  return (
    <Box {...props}>
      <Box>
        <FormControl
          required
          error={!!formik.errors.atLeastOneRequired}
          component="fieldset"
          sx={{ m: 3 }}
          variant="standard"
          disabled={disabled}
        >
          <FormLabel component="legend">
            {t('chooseCharacterСlasses')}
          </FormLabel>
          <FormGroup
            row
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              color: 'text.secondary',
              '& svg': {
                m: 1.5,
              },
              '& hr': {
                mx: 0.5,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.letters}
                  onChange={(event) => {
                    formik.setFieldValue('letters', event.target.checked);
                    onKeySetChanged({
                      hasLetters: event.target.checked,
                      hasDigits: formik.values.digits,
                      hasSymbols: formik.values.symbols,
                      caseSensitive: formik.values.caseSensitive,
                    });
                  }}
                  name="letters"
                />
              }
              label={t('letters')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.digits}
                  onChange={(event) => {
                    formik.setFieldValue('digits', event.target.checked);
                    onKeySetChanged({
                      hasLetters: formik.values.letters,
                      hasDigits: event.target.checked,
                      hasSymbols: formik.values.symbols,
                      caseSensitive: formik.values.caseSensitive,
                    });
                  }}
                  name="digits"
                />
              }
              label={t('digits')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.symbols}
                  onChange={(event) => {
                    formik.setFieldValue('symbols', event.target.checked);
                    onKeySetChanged({
                      hasLetters: formik.values.letters,
                      hasDigits: formik.values.digits,
                      hasSymbols: event.target.checked,
                      caseSensitive: formik.values.caseSensitive,
                    });
                  }}
                  name="symbols"
                />
              }
              label={t('symbols')}
            />
            <Divider orientation="vertical" variant="middle" flexItem />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.caseSensitive}
                  onChange={(event) => {
                    formik.setFieldValue('caseSensitive', event.target.checked);
                    onKeySetChanged({
                      hasLetters: formik.values.letters,
                      hasDigits: formik.values.digits,
                      hasSymbols: formik.values.symbols,
                      caseSensitive: event.target.checked,
                    });
                  }}
                  name="caseSensitive"
                />
              }
              labelPlacement="start"
              label={t('caseSensitive')}
            />
          </FormGroup>
          {formik.errors.atLeastOneRequired && (
            <FormHelperText>
              {t('pleaseChooseAtLeastOneCharacterClass')}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export default TestSettings;
