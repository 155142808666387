import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { LessonEvent } from 'domains/test/LessonEvent';
import type { TestEvent } from 'domains/test/TestEvent';
import type { KeyStat, KeyStatus, TestResults } from 'domains/test/TestResults';
import { average } from 'utils/average';

export const converLogsToTestResults = (
  keysSet: KeyDefinition[],
  logs: LessonEvent[],
  testOptions: {
    hasDigits: boolean;
    hasLetters: boolean;
    hasSymbols: boolean;
    caseSensitive: boolean;
    taktTime: number;
    delayTime: number;
  },
  logicalLayout: LogicalLayout,
  physicalLayout: PhysicalLayout
): TestResults => {
  const totalTime: number = getTotalTimeFromLogsInMs(logs);

  let numCorrectKeystrokes = 0;
  let numWrongKeystrokes = 0;
  let numMissedKeystrokes = 0;
  let numBlots = 0;

  const reactions: number[] = [];

  const allKeystrokes = logs.filter(
    ({ type }) =>
      type === 'correctKeystroke' ||
      type === 'wrongKeystroke' ||
      type === 'missedKeystroke' ||
      type === 'blot'
  );

  allKeystrokes.forEach(({ type, reaction }) => {
    if (type === 'blot') {
      numBlots += 1;
    } else if (type === 'correctKeystroke') {
      numCorrectKeystrokes += 1;
      reactions.push(reaction as number);
    } else if (type === 'wrongKeystroke') {
      numWrongKeystrokes += 1;
    } else if (type === 'missedKeystroke') {
      numMissedKeystrokes += 1;
    }
  });

  return {
    logicalLayout: logicalLayout,
    physicalLayout: physicalLayout,
    options: {
      hasDigits: testOptions.hasDigits,
      hasLetters: testOptions.hasLetters,
      hasSymbols: testOptions.hasSymbols,
      caseSensitive: testOptions.caseSensitive,
      taktTime: testOptions.taktTime,
      delayTime: testOptions.delayTime,
    },

    totalTime,
    accuracy:
      numCorrectKeystrokes /
      (numCorrectKeystrokes + numWrongKeystrokes + numMissedKeystrokes),
    averageReaction: average(reactions),

    totalNumKeystrokes: numCorrectKeystrokes + numWrongKeystrokes,
    numCorrectKeystrokes: numCorrectKeystrokes,
    numWrongKeystrokes: numWrongKeystrokes,
    numMissedKeystrokes: numMissedKeystrokes,
    numBlots: numBlots,

    keyStats: getKeyResults(logs),

    logs: logs,
  };
};

const getTotalTimeFromLogsInMs = (logs: TestEvent[]) => {
  // const startTime = logs.find((event) => event.type === 'start')?.timecode;
  const endTime = logs.find((event) => event.type === 'finish')?.timecode ?? 0;

  const pauseTotalTime = logs
    .filter((event) => event.type === 'pause' || event.type === 'continue')
    .sort((event1, event2) => event1.timecode - event2.timecode)
    .reduce(function (sum, current, index, arr) {
      return index % 2 != 0
        ? sum + (current.timecode - arr[index - 1].timecode)
        : sum;
    }, 0);

  // console.log({
  //   logs,
  //   endTime,
  //   pauseTotalTime,
  //   totalTime: endTime - pauseTotalTime,
  // });

  return endTime - pauseTotalTime;
};

const getKeyResults = (logs: TestEvent[]): KeyStat[] => {
  return logs
    .filter(
      (event) =>
        event.type === 'correctKeystroke' ||
        event.type === 'wrongKeystroke' ||
        event.type === 'missedKeystroke'
    )
    .map((event) => ({
      key: event.target as KeyDefinition,
      pressedKey: event.pressedKey,
      status: event.type.replace('Keystroke', '') as KeyStatus,
      reaction: event.reaction,
    }));
};
