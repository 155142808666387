// Core
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import { startFetching, stopFetching } from 'drafts/auth/slice';
// Actions
import { enqueueSnackbar } from 'domains/notifier/slice';
import { apply, put } from 'redux-saga/effects';
// Instruments
import api from '../../../../REST/api';

function* forgotPassword({ payload: email }) {
  try {
    yield put(startFetching());
    // console.log(email);
    const response = yield apply(api, api.auth.forgotPassword, [{ email }]);

    const { status, message } = yield apply(response, response.json);

    if (!response.ok) {
      throw new Error(message);
    } else {
      yield put(
        enqueueSnackbar(
          {
            message: message,
          },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        )
      );
    }
  } catch (error) {
    yield put(
      enqueueSnackbar(
        { message: error.message },
        {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: (key) => <NotifierCloseButton notifierKey={key} />,
        }
      )
    );
  } finally {
    yield put(stopFetching());
  }
}

export default forgotPassword;
