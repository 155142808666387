/**
 * https://w3c.github.io/uievents-code/#code-value-tables
 */

export const iso = [
  [
    {
      code: 'Backquote',
      size: 'backquote',
    },
    {
      code: 'Digit1',
      size: 'key',
    },
    {
      code: 'Digit2',
      size: 'key',
    },
    {
      code: 'Digit3',
      size: 'key',
    },
    {
      code: 'Digit4',
      size: 'key',
    },
    {
      code: 'Digit5',
      size: 'key',
    },
    {
      code: 'Digit6',
      size: 'key',
    },
    {
      code: 'Digit7',
      size: 'key',
    },
    {
      code: 'Digit8',
      size: 'key',
    },
    {
      code: 'Digit9',
      size: 'key',
    },
    {
      code: 'Digit0',
      size: 'key',
    },
    {
      code: 'Minus',
      size: 'key',
    },
    {
      code: 'Equal',
      size: 'key',
    },
    {
      code: 'Backspace',
      size: 'backspace',
    },
  ],
  [
    {
      code: 'Tab',
      size: 'tab',
    },
    {
      code: 'KeyQ',
      size: 'key',
    },
    {
      code: 'KeyW',
      size: 'key',
    },
    {
      code: 'KeyE',
      size: 'key',
    },
    {
      code: 'KeyR',
      size: 'key',
    },
    {
      code: 'KeyT',
      size: 'key',
    },
    {
      code: 'KeyY',
      size: 'key',
    },
    {
      code: 'KeyU',
      size: 'key',
    },
    {
      code: 'KeyI',
      size: 'key',
    },
    {
      code: 'KeyO',
      size: 'key',
    },
    {
      code: 'KeyP',
      size: 'key',
    },
    {
      code: 'BracketLeft',
      size: 'key',
    },
    {
      code: 'BracketRight',
      size: 'key',
    },
    {
      code: 'Enter',
      size: 'enter',
    },
  ],
  [
    {
      code: 'CapsLock',
      size: 'capsLock',
    },
    {
      code: 'KeyA',
      size: 'key',
    },
    {
      code: 'KeyS',
      size: 'key',
    },
    {
      code: 'KeyD',
      size: 'key',
    },
    {
      code: 'KeyF',
      size: 'key',
      homing: true,
    },
    {
      code: 'KeyG',
      size: 'key',
    },
    {
      code: 'KeyH',
      size: 'key',
    },
    {
      code: 'KeyJ',
      size: 'key',
      homing: true,
    },
    {
      code: 'KeyK',
      size: 'key',
    },
    {
      code: 'KeyL',
      size: 'key',
    },
    {
      code: 'Semicolon',
      size: 'key',
    },
    {
      code: 'Quote',
      size: 'key',
    },
    {
      code: 'Backslash',
      size: 'key',
    },
  ],
  [
    {
      code: 'ShiftLeft',
      size: 'shiftLeft',
    },
    {
      code: 'IntlBackslash',
      size: 'key',
    },
    {
      code: 'KeyZ',
      size: 'key',
    },
    {
      code: 'KeyX',
      size: 'key',
    },
    {
      code: 'KeyC',
      size: 'key',
    },
    {
      code: 'KeyV',
      size: 'key',
    },
    {
      code: 'KeyB',
      size: 'key',
    },
    {
      code: 'KeyN',
      size: 'key',
    },
    {
      code: 'KeyM',
      size: 'key',
    },
    {
      code: 'Comma',
      size: 'key',
    },
    {
      code: 'Period',
      size: 'key',
    },
    {
      code: 'Slash',
      size: 'key',
    },
    {
      code: 'ShiftRight',
      size: 'shiftRight',
    },
  ],
  [
    {
      code: 'ControlLeft',
      size: 'controlLeft',
    },
    {
      code: 'MetaLeft',
      size: 'metaLeft',
    },
    {
      code: 'AltLeft',
      size: 'altLeft',
    },
    {
      code: 'Space',
      size: 'space',
    },
    {
      code: 'AltRight',
      size: 'altRight',
    },
    {
      code: 'MetaRight',
      size: 'metaRight',
    },
    {
      code: 'ContextMenu',
      size: 'contextMenu',
    },
    {
      code: 'ControlRight',
      size: 'controlRight',
    },
  ],
];
