import { LayoutLanguage } from 'domains/keyboard/LayoutLangage';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';

export const layouts: { [K in LogicalLayout]: LayoutDesc } = {
  us_qwerty: {
    language: 'en',
    availablePhysicalLayouts: ['iso', 'ansi'],
    emulated: true,
  },
  dvorak: {
    language: 'en',
    availablePhysicalLayouts: ['iso', 'ansi'],
    emulated: true,
  },
  colemak: {
    language: 'en',
    availablePhysicalLayouts: ['iso', 'ansi'],
    emulated: true,
  },
  workman: {
    language: 'en',
    availablePhysicalLayouts: ['iso', 'ansi'],
    emulated: true,
  },
  // uk_qwerty: {
  //   language: 'en',
  //   availablePhysicalLayouts: ['iso'],
  //   emulated: false,
  // },
  jcuken: {
    language: 'ru',
    availablePhysicalLayouts: ['iso', 'ansi'],
    emulated: true,
  },
  // yashert: {
  //   language: 'ru',
  //   availablePhysicalLayouts: ['iso', 'ansi'],
  //   emulated: true,
  // },
};

// console.log(Object.keys(layouts));

type LayoutDesc = {
  language: LayoutLanguage;
  availablePhysicalLayouts: PhysicalLayout[];
  emulated: boolean;
};
