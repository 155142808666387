import { TypographyVariant } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';

interface Props extends Omit<PaperProps, 'variant'> {
  testedKey?: string | null;
  variant?: TypographyVariant;
  excluded?: boolean;
  showXHeight?: boolean;
}

const Keycap: FC<Props> = ({
  testedKey,
  variant = 'h3',
  excluded = false,
  showXHeight = false,
  ...props
}) => {
  return (
    <Paper
      {...props}
      variant="outlined"
      square
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        padding: '0.2rem',
        pointerEvents: 'none',
        color: excluded ? '#bfbfbf' : '#000',
        ':after': showXHeight
          ? {
              content: '""',
              position: 'absolute',
              top: '42%',
              pointerEvents: 'none',
              height: '28px',
              width: '30%',
              borderTop: '1px dashed rgba(0,0,0,0.5)',
              borderBottom: '1px dashed rgba(0,0,0,0.5)',
              // backgroundColor: 'red',
            }
          : {},
        ...props.sx,
      }}
    >
      <Typography variant={variant}>{testedKey}</Typography>
    </Paper>
  );
};

export default memo(Keycap);
