import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

interface Props extends LinearProgressProps {
  diffFromAvgValue: number;
  labelValue?: number;
  isProgressPlusSign?: boolean;
  showProgress?: boolean;
  measureUnit: string;
  title: string;
}

const ProgressElem: FC<Props> = ({
  labelValue,
  diffFromAvgValue,
  measureUnit,
  title,
  isProgressPlusSign = true,
  showProgress = true,
  ...props
}) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      {showProgress && (
        <Box sx={{ display: 'flex', width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            {...props}
            sx={{ width: '100%', borderRadius: 1, height: 6, ...props.sx }}
          />
        </Box>
      )}
      <Box
        sx={{
          minWidth: '11ch',
          textAlign: 'start',
        }}
      >
        <Typography component="span" variant="subtitle2" title={title} noWrap>
          {`${labelValue ?? props.value} ${measureUnit} `}
        </Typography>
        (
        <Typography
          component="span"
          variant="subtitle2"
          title="Разница со средним значением"
          noWrap
          color={
            diffFromAvgValue === 0
              ? 'text.secondary'
              : diffFromAvgValue > 0 && isProgressPlusSign
              ? '#68a162'
              : diffFromAvgValue > 0 && !isProgressPlusSign
              ? '#a1464e'
              : diffFromAvgValue < 0 && isProgressPlusSign
              ? '#a1464e'
              : '#68a162'
          }
        >
          {`${diffFromAvgValue <= 0 ? '🠧' : '🠥'} ${
            diffFromAvgValue > 0 ? '+' : '-'
          }${Math.abs(diffFromAvgValue)}`}
        </Typography>
        )
      </Box>
    </Box>
  );
};

export default ProgressElem;
