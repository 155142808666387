import { FC, useEffect } from 'react';

import Loading from 'components/Loading';
import authActions from 'domains/auth/actions';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useLocation } from 'react-router-dom';

import Private from './Private';
import Public from './Public';

type RouteType = { to: string; from: string | undefined };

interface Props {}

const App: FC<Props> = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const isInitialized = useAppSelector((state) => state.auth.isInitialized);

  const [route, setRoute] = useState<RouteType>({
    //--> it can be replaced with useRef or localStorage
    to: location.pathname,
    from: undefined,
  });

  useEffect(() => {
    if (window.ym && location.pathname !== route.to) {
      setTimeout(() => {
        window.ym(90304960, 'hit', location.pathname, {
          referer: route.to,
        });

        setRoute((prev) => ({ to: location.pathname, from: prev.to }));
      }, 100);
    }
  }, [location]);

  useEffect(() => {
    dispatch(authActions.initializeAsync());
    // setTimeout(() => {
    //   if (window.ym) {
    //     window.ym(90304960, 'hit', location.pathname, {});
    //   }
    // }, 100);
  }, []);

  if (!isInitialized) {
    return <Loading />;
  }

  return isAuthenticated ? <Private /> : <Public />;
};

export default App;
