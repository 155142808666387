import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Keycap from 'components/Keycap/Keycap';
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { FC } from 'react';
import { SelectedChar } from 'utils/convertFormValuesToValuesForDb';

interface Props {
  keys: KeyDefinition[];
  excludedKeys?: SelectedChar[];
  loading?: boolean;
}

const LessonKeys: FC<Props> = ({ keys, loading, excludedKeys }) => {
  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1 }}>
      {loading
        ? Array(8)
            .fill(0)
            .map((item, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height="2rem"
                width="2rem"
                component="div"
              />
            ))
        : keys.map((key) => (
            <Keycap
              key={`${key.code}_${key.key}`}
              testedKey={key.key}
              variant="body2"
              excluded={
                !!excludedKeys?.find(
                  (excludedKey) =>
                    excludedKey &&
                    excludedKey.code === key.code &&
                    excludedKey.shiftKey === key.shiftKey
                )
              }
              sx={{ width: '2rem', height: '2rem' }}
            />
          ))}
    </Box>
  );
};

export default LessonKeys;
