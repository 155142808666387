import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';

import SignUpForm from 'components/Forms/SignUp';

interface Props {}

const SignUpPage: FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Регистрация - keycap.pro</title>
      </Helmet>
      <Container maxWidth="xs" sx={{ mt: 12 }}>
        <SignUpForm />
      </Container>
    </>
  );
};

export default SignUpPage;
