import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import GitHubIcon from 'components/icons/GitHub';
import GoogleIcon from 'components/icons/Google';
import { useFormik } from 'formik';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { boolean, object, string } from 'yup';

import authActions from 'domains/auth/actions';
import { useAppDispatch, useAppSelector } from 'hooks';
import * as ROUTES from 'navigation/routes';

export interface User {
  email: string;
  password: string;
}

const SignUpForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isFetching = useAppSelector((state) => state.auth.isFetching);

  const initialValues = {
    email: '',
    password: '',
    showPassword: false,
  };

  const validationSchema = object().shape({
    email: string().email().required(),
    password: string().min(6, t('password_length_error')).required(),
    showPassword: boolean(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        authActions.signupAsync({
          email: values.email,
          password: values.password,
        })
      );
    },
  });

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{ fontWeight: 'bold', mb: '50px' }}
      >
        {t('signup')}
      </Typography>

      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          fullWidth
          id="email"
          name="email"
          label={t('email')}
          margin="normal"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          inputProps={{
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
        <FormControl variant="outlined" margin="normal" sx={{ width: '100%' }}>
          <InputLabel
            htmlFor="password"
            error={formik.touched.password && !!formik.errors.password}
          >
            {t('password')}
          </InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            fullWidth
            type={formik.values.showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && !!formik.errors.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    formik.setFieldValue(
                      'showPassword',
                      !formik.values.showPassword
                    );
                  }}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {formik.values.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label={t('password')}
            inputProps={{
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
          <FormHelperText
            id="outlined-weight-helper-text"
            error={formik.touched.password && !!formik.errors.password}
          >
            {(formik.errors.password === t('password_length_error') &&
              formik.errors.password) ||
              (formik.touched.password && formik.errors.password)}
          </FormHelperText>
        </FormControl>
        {isFetching && <LinearProgress />}
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          sx={{
            mt: 2,
            // mb: '170px',
            height: '62px',
          }}
          disabled={isFetching}
        >
          {t('sign_up')}
        </Button>
        <Divider sx={{ mt: 2, mb: 2 }}>
          <Chip label="или" />
        </Divider>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: '170px' }}
        >
          {/* <Typography>Or Signup with email</Typography> */}
          <Button
            variant="contained"
            color="inherit"
            component="a"
            href={ROUTES.SIGN_UP_WITH_GOOGLE_URI}
            startIcon={<GoogleIcon fontSize="large" />}
            sx={{
              backgroundColor: '#ffffff',
              color: 'text.secondary',
              textTransform: 'none',
            }}
          >
            Продолжить с Google
          </Button>
          <Button
            variant="contained"
            color="inherit"
            component="a"
            href={ROUTES.SIGN_UP_WITH_GITHUB_URI}
            startIcon={
              <GitHubIcon
                // fontSize="inherit"
                sx={{ color: '#000000', fontSize: '25px' }}
              />
            }
            sx={{
              // backgroundColor: '#000000',
              // color: 'white',
              color: 'text.secondary',
              textTransform: 'none',
            }}
          >
            Продолжить с GitHub
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1" component="div">
            {t('have_an_account')}
          </Typography>
          <Button
            component={Link}
            to={ROUTES.LOGIN}
            variant="text"
            fullWidth={false}
          >
            {t('log_in')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SignUpForm;
