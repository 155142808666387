// Core
import { all, call } from 'redux-saga/effects';
// Watchers
import { watchAuth } from 'domains/auth/saga/watchers';

function* rootSaga() {
  yield all([call(watchAuth)]);
}

export default rootSaga;
