import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LessonType } from 'domains/lessons/models/Lesson';
import { Stage } from 'domains/Stage';
import { useEffect, useRef, useState } from 'react';
import { getRandomInt } from 'utils/getRandomInt';
import normalisePogressValue from 'utils/normalisePogressValue';

const useStage = (
  keysSet: KeyDefinition[],
  options: {
    algorithm: LessonType;
    redBarLength: 'byCharsNum' | number;
    entryLevel: number;
    introLevel?: number;
    trainingLevel?: number;
    testLevel?: number;
  }
): {
  stage: Stage;
  handleNext: (
    isPreviousKeyPressCorrect: boolean
  ) => (KeyDefinition & { isLastKey: boolean }) | null;
  changeKey: () => (KeyDefinition & { isLastKey: boolean }) | null;
  isBlindly: boolean;
  progress1: number;
  progress2: number;
  resetProgress: () => void;
  isLastPunishmentKey: boolean;
} => {
  const NUM_LEVELS = 11;

  const MAX_NUM_TRAINING_KEYS = 7;
  const NUM_INTRO_KEYS = 8;

  const [level, setLevel] = useState(options.entryLevel ?? 6);

  // LEVEL_TRAINING
  const [numLevelTrainingKeys, setNumLevelTrainingKeys] = useState(0);
  const [levelTrainingCount, setLevelTrainingCount] = useState(0);

  // PUNISHMENT
  const [numPunishmentKeys, setNumPunishmentKeys] = useState(0);
  const [punishmentCount, setPunishmentCount] = useState(0);
  const [isLastPunishmentKey, setIsLastPunishmentKey] = useState(false);

  // TRAINING
  const [numTrainingKeys, setNumTrainingKeys] = useState(0);
  const [trainingCount, setTrainingCount] = useState(1);
  const [trainingIterationCount, setTrainingIterationCount] = useState(0);

  // REINFORSMENT
  const [numReinforsmentKeys, setNumReinforsmentKeys] = useState(0);
  const [reinforsmentCount, setReinforsmentCount] = useState(1);

  const [stage, setStage] = useState(Stage.INTRO);
  const [introCount, setIntroCount] = useState(1);
  // используется, если алгоритм не базовый
  const [numIntroKeys] = useState(NUM_INTRO_KEYS);
  const [restoredStage, setRestoredStage] = useState(Stage.INTRO);

  const [testedKey, setTestedKey] = useState<KeyDefinition | null>(null);

  const [progress1, setProgress1] = useState(
    normalisePogressValue(NUM_LEVELS - options.entryLevel, NUM_LEVELS)
  );
  const [progress2, setProgress2] = useState(0);

  // const introArrayRef = useRef([...keysSet]);
  // const checkingArrayRef = useRef([...keysSet]);
  const [introArray, setIntroArray] = useState([...keysSet]);
  const [checkingArray, setCheckingArray] = useState([...keysSet]);

  const [numCheckingKeys, setNumCheckingKeys] = useState(options.redBarLength);
  const [checkingCount, setCheckingCount] = useState(1);

  useEffect(() => {
    if (keysSet.length) {
      // console.log('keys useEffect: ', keysSet);
      setIntroArray([...keysSet]);
      setCheckingArray([...keysSet]);
      setNumCheckingKeys(options.redBarLength);
      setLevel(options.entryLevel);
      resetProgress();
    }
  }, [keysSet, options.redBarLength, options.entryLevel]);

  function handleNext(
    isKeyPressCorrect: boolean
  ): (KeyDefinition & { isLastKey: boolean }) | null {
    const nextStage = defineNextStage(isKeyPressCorrect);
    // console.log('--STAGE: ', nextStage, checkingCount);
    const excludedKey = isKeyPressCorrect && stage === nextStage;
    const nextKey = getKeyByStage(nextStage, excludedKey);
    // console.warn(nextStage, ' ', nextKey?.key);
    setStage(nextStage);
    setTestedKey(nextKey);

    const isLastKey =
      nextStage === Stage.CHECKING &&
      (typeof numCheckingKeys == 'number'
        ? excludedKey
          ? checkingCount + 1 === numCheckingKeys
          : checkingCount === numCheckingKeys
        : excludedKey
        ? excludeKey(checkingArray, testedKey).length === 1
        : checkingArray.length === 1);
    setIsLastPunishmentKey(
      stage === Stage.PUNISHMENT && punishmentCount === numPunishmentKeys - 1
    );
    // console.log({ nextStage, excludedKey, nextKey, isLastKey });
    return nextKey ? { ...nextKey, isLastKey } : null;
  }

  function defineNextStage(isKeyPressCorrect: boolean): Stage {
    switch (stage) {
      case Stage.INTRO:
        if (isKeyPressCorrect) {
          if (options.algorithm === 'basic') {
            setIntroArray((prevState) => excludeKey(prevState, testedKey));
            if (introArray.length === 1) {
              setRestoredStage(Stage.LEVEL_TRAINING);
            }
          } else {
            setIntroCount((prevState) => prevState + 1);
            if (introCount === numIntroKeys) {
              setRestoredStage(Stage.LEVEL_TRAINING);
            }
          }
        } else {
          preparePunishment();

          setRestoredStage(stage);
        }

        return isKeyPressCorrect
          ? introArray.length === 1 || introCount === numIntroKeys
            ? // INTRO --> MAIN (BLINDLY)
              Stage.BLINDLY
            : Stage.INTRO
          : // INTRO --> PUNISHMENT
            Stage.PUNISHMENT;
      case Stage.PUNISHMENT:
        if (isKeyPressCorrect && punishmentCount < numPunishmentKeys) {
          setPunishmentCount((prevState) => prevState + 1);
          // console.log({ punishmentCount, numPunishmentKeys });
        }

        if (punishmentCount === numPunishmentKeys) {
          prepareTraining();
        }

        return isKeyPressCorrect && punishmentCount === numPunishmentKeys
          ? restoredStage !== Stage.INTRO
            ? Stage.TRAINING
            : Stage.INTRO
          : Stage.PUNISHMENT;
      case Stage.BLINDLY:
        if (isKeyPressCorrect) {
          levelUp();

          setRestoredStage(Stage.LEVEL_TRAINING);
        } else {
          if (level < NUM_LEVELS) {
            levelDown();
          } else {
            prepareLevelTraining();
          }
          preparePunishment();
        }

        return isKeyPressCorrect ? Stage.REINFORSMENT : Stage.PUNISHMENT;
      case Stage.REINFORSMENT:
        if (isKeyPressCorrect) {
          if (reinforsmentCount < numReinforsmentKeys) {
            setReinforsmentCount((prevState) => prevState + 1);
          }
        } else {
          preparePunishment();
        }

        // console.log({ reinforsmentCount });

        if (reinforsmentCount === numReinforsmentKeys) {
          // console.log('level: ', level);
        }

        return isKeyPressCorrect
          ? reinforsmentCount === numReinforsmentKeys
            ? Stage.LEVEL_TRAINING
            : Stage.REINFORSMENT
          : Stage.PUNISHMENT;
      case Stage.LEVEL_TRAINING:
        if (isKeyPressCorrect) {
          if (levelTrainingCount < numLevelTrainingKeys) {
            setLevelTrainingCount((prevState) => prevState + 1);
          }
        } else {
          preparePunishment();
        }

        if (levelTrainingCount === numLevelTrainingKeys && level === 0) {
          setRestoredStage(Stage.CHECKING);
          setTrainingIterationCount(0);
          // console.log('TrainingIterationCount: ', 0);
        }

        return isKeyPressCorrect
          ? levelTrainingCount === numLevelTrainingKeys
            ? level === 0
              ? Stage.CHECKING
              : Stage.BLINDLY
            : Stage.LEVEL_TRAINING
          : Stage.PUNISHMENT;
      case Stage.TRAINING:
        if (isKeyPressCorrect) {
          if (trainingCount < numTrainingKeys) {
            setTrainingCount((prevState) => prevState + 1);
          }
        } else {
          setTrainingIterationCount((prevState) => prevState + 1);
          // console.log('TrainingIterationCount: ', trainingIterationCount + 1);
          preparePunishment();
        }

        if (
          restoredStage === Stage.LEVEL_TRAINING &&
          trainingCount === numTrainingKeys &&
          isKeyPressCorrect
        ) {
          // console.log('level', level);
        }

        return isKeyPressCorrect
          ? trainingCount === numTrainingKeys
            ? restoredStage === Stage.CHECKING
              ? Stage.CHECKING
              : restoredStage
            : Stage.TRAINING
          : Stage.PUNISHMENT;

      case Stage.CHECKING:
        if (isKeyPressCorrect) {
          setProgress2(
            typeof numCheckingKeys == 'number'
              ? normalisePogressValue(
                  checkingCount,
                  // keysSet.length - (checkingArray.length - 1),
                  numCheckingKeys
                )
              : normalisePogressValue(
                  keysSet.length - (checkingArray.length - 1),
                  keysSet.length
                )
          );
          if (typeof numCheckingKeys == 'number') {
            // console.log('CheckingCount +1');
            setCheckingCount((prevState) => prevState + 1);
          } else {
            setCheckingArray((prevState) => excludeKey(prevState, testedKey));
          }
        } else {
          preparePunishment();
        }

        return isKeyPressCorrect ? Stage.CHECKING : Stage.PUNISHMENT;
    }
  }

  /**
   * получить следующий символ по след. stage
   */
  function getKeyByStage(
    nextStage: Stage,
    excludeTestedKey = false
  ): KeyDefinition | null {
    switch (nextStage) {
      case Stage.INTRO:
        // console.log('get Key ', nextStage, introArray);
        return getRandomItem(
          excludeTestedKey ? excludeKey(introArray, testedKey) : introArray
        );
      case Stage.CHECKING:
        return typeof numCheckingKeys == 'number'
          ? getRandomItem(checkingArray)
          : getRandomItem(
              excludeTestedKey
                ? excludeKey(checkingArray, testedKey)
                : checkingArray
            );
      case Stage.PUNISHMENT:
      case Stage.REINFORSMENT:
        return testedKey;
      //'TRAINING', 'LEVEL_TRAINING', 'BLINDLY'
      default:
        return getRandomItem(keysSet);
    }
  }

  /**
   * меняет символ для того же stage
   */
  function changeKey() {
    const nextKey = getKeyByStage(stage);
    setTestedKey(nextKey);

    // console.log('changeKey func result: ', stage, nextKey);

    const isLastKey =
      stage === Stage.CHECKING &&
      (typeof numCheckingKeys == 'number'
        ? checkingCount === numCheckingKeys
        : checkingArray.length === 1);
    setIsLastPunishmentKey(
      stage === Stage.PUNISHMENT && punishmentCount === numPunishmentKeys - 1
    );
    return nextKey ? { ...nextKey, isLastKey } : null;
  }

  function preparePunishment() {
    const rand = getRandomInt(2, 3);
    // console.log('punishment RAND: ', rand);
    setNumPunishmentKeys(rand);
    setPunishmentCount(0);
  }

  function levelUp() {
    const newLevel = level - 1;
    setLevel(newLevel);
    setProgress1(normalisePogressValue(NUM_LEVELS - newLevel, NUM_LEVELS));

    setNumLevelTrainingKeys(newLevel);
    setLevelTrainingCount(0);

    prepareReinforcement();
    setTrainingIterationCount(0);
    // console.log('TrainingIterationCount: ', 0);
  }

  function prepareReinforcement() {
    // console.log('reinforsment num: ', Math.floor(level / 3) + 1);
    // setNumReinforsmentKeys(0);
    const numKeys =
      Math.floor(level / 3) + 1 >= 2 ? 1 : Math.floor(level / 3) + 1;
    // const rand = getRandomInt(1, 2);
    // console.log({ numKeys });
    // setNumReinforsmentKeys(numKeys >= 2 ? 1 : numKeys);
    setNumReinforsmentKeys(numKeys);
    setReinforsmentCount(0);
  }

  function levelDown() {
    const newLevel = level + 1;
    setLevel(newLevel);
    setProgress1(normalisePogressValue(NUM_LEVELS - newLevel, NUM_LEVELS));
    // console.error('-level: ', newLevel);

    setNumLevelTrainingKeys(newLevel);
    setLevelTrainingCount(0);

    setTrainingIterationCount(0);
    // console.log('TrainingIterationCount: ', 0);
  }

  function prepareLevelTraining() {
    setNumLevelTrainingKeys(level);
    setLevelTrainingCount(0);
  }

  function prepareTraining() {
    const num =
      MAX_NUM_TRAINING_KEYS - trainingIterationCount < 2
        ? 2
        : MAX_NUM_TRAINING_KEYS - trainingIterationCount;
    setNumTrainingKeys(num);
    setTrainingCount(0);
  }

  function resetProgress() {
    setLevel(options.entryLevel);
    setTestedKey(null);

    setIntroArray([...keysSet]);
    setCheckingArray([...keysSet]);
    setProgress1(
      normalisePogressValue(NUM_LEVELS - options.entryLevel, NUM_LEVELS)
    );
    setProgress2(0);

    setPunishmentCount(0);
    setTrainingIterationCount(0);
    setReinforsmentCount(0);
    setTrainingCount(0);
    setLevelTrainingCount(0);

    setStage(Stage.INTRO);
    setRestoredStage(Stage.INTRO);
  }

  return {
    stage,
    handleNext,
    changeKey,
    isBlindly: stage === Stage.BLINDLY || stage === Stage.CHECKING,
    progress1,
    progress2,
    resetProgress,
    isLastPunishmentKey,
  };
};

export default useStage;

// get random item from a Set or Array
function getRandomItem(
  arrayOrSet: Set<KeyDefinition> | KeyDefinition[]
): KeyDefinition {
  arrayOrSet = Array.isArray(arrayOrSet) ? arrayOrSet : Array.from(arrayOrSet);

  const rand = Math.floor(Math.random() * arrayOrSet.length);
  return arrayOrSet[rand];
}

// exclude key
function excludeKey(
  array: KeyDefinition[],
  excludedKey: KeyDefinition | null
): KeyDefinition[] {
  return excludedKey
    ? [...array].filter(
        (key) =>
          !(
            key.code === excludedKey?.code &&
            key.shiftKey === excludedKey?.shiftKey
          )
      )
    : [...array];
}
