import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';

import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import getKey from 'domains/keyboard/logicalLayouts/getKeyByCode';
import { LessonEvent } from 'domains/lessons/models/LessonEvent';
import useInterval from 'hooks/useInterval';
import useStage from 'hooks/useStage';
import { LessonType } from 'domains/lessons/models/Lesson';
import { Stage } from 'domains/Stage';

type LessonOptionType = {
  layoutId: string;
  algorithm?: LessonType;
  taktTime: number;
  delayTime: number;
  entryLevel: number;
  emulated?: boolean;
  punishmentDelayTime?: number;
  numControlChars: 'byCharsNum' | number;
};

// ✅ Remove nullable types from the type's keys
type WithoutNullableKeys<Type> = {
  [Key in keyof Type]-?: WithoutNullableKeys<NonNullable<Type[Key]>>;
};

const useLesson = (
  keysSet: KeyDefinition[],
  options: LessonOptionType
): {
  isBlindly: boolean;
  isFocused: boolean;
  progress1: number;
  progress2: number;
  seconds: number;
  logs: LessonEvent[];
  isStarted: boolean;
  isPaused: boolean;
  isFinished: boolean;
  testedKey: KeyDefinition | null;
  pressedKeys: Set<string>;
  reset: () => void;
  handleKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleKeyUp: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleFocus: () => void;
  handleBlur: () => void;
} => {
  const mergedOptions: WithoutNullableKeys<LessonOptionType> = useMemo(
    () => ({
      punishmentDelayTime: options.delayTime ?? 200,
      algorithm: 'basic',
      emulated: true, //TODO: убрать, когда появяться не эмулированные раскладки
      ...options,
    }),
    [options]
  );
  const [taktTime, setTaktTime] = useState(mergedOptions.taktTime);
  const [delayTime, setDelayTime] = useState(mergedOptions.delayTime);
  const [punishmentDelayTime, setPunishmentDelayTime] = useState(
    mergedOptions.punishmentDelayTime
  );
  const [redBarLength, setRedBarLength] = useState(
    mergedOptions.numControlChars
  );

  useEffect(() => {
    // console.log('mergedOptions: ', { ...mergedOptions });
    setTaktTime(mergedOptions.taktTime);
    setDelayTime(mergedOptions.delayTime);
    setRedBarLength(mergedOptions.numControlChars);
    setPunishmentDelayTime(mergedOptions.punishmentDelayTime);
  }, [
    mergedOptions.taktTime,
    mergedOptions.delayTime,
    mergedOptions.numControlChars,
    mergedOptions.punishmentDelayTime,
  ]);

  // const [seconds, isPaused, startStopwatch, pauseStopwatch, resetStopwatch] =
  //   useStopwatch();
  const [reactionTimeStart, setReactionTimeStart] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [isPaused, setIsPaused] = useState(false);
  const [pressedKeys, setPressedKeys] = useState<Set<string>>(new Set());

  // const pressedKeysRef = useRef<Set<string>>(new Set());

  const {
    stage,
    handleNext,
    changeKey,
    isBlindly,
    progress1,
    progress2,
    resetProgress,
    isLastPunishmentKey,
  } = useStage(keysSet, {
    algorithm: mergedOptions.algorithm ?? 'base',
    redBarLength: mergedOptions.numControlChars,
    entryLevel: mergedOptions.entryLevel,
  });

  const [testedKey, setTestedKey] = useState<
    | (KeyDefinition & {
        isLastKey: boolean;
      })
    | null
  >(null);

  const [logs, setLogs] = useState<LessonEvent[]>([]);
  const deleteTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [intervalTime, setIntervalTime] = useState<number | undefined>(
    undefined
  );
  const [isFocused, setIsFocused] = useState(false);

  useInterval(() => {
    if (isPaused || !isStarted) {
      return;
    }

    const time = Number(new Date());
    if (testedKey) {
      log({
        type: 'missedKeystroke',
        reaction: taktTime,
        target: testedKey,
        timecode: time - startTime,
        pressedKey: undefined,
        isBlindly,
      });

      nextWithDelay(false);
    }
  }, intervalTime);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [timerRef]);

  const start = () => {
    // console.log('START');
    // установить следующую букву
    setIsStarted(true);
    setStartTime(Number(new Date()));
    // запустить секундомер
    setIsPaused(false);
    // startStopwatch();
    log({
      type: 'start',
      reaction: undefined,
      target: undefined,
      timecode: 0,
      pressedKey: undefined,
    });
    setTestedKey(changeKey());
    setReactionTimeStart(Number(new Date()));
    setIntervalTime(taktTime);
  };

  type NextFn = (isPreviousKeyPressCorrect: boolean) => void;

  /**
   * установка testedKey в null, и таймер200, чтобы показать следующую
   */
  const nextWithDelay: NextFn = (isKeyPressCorrect) => {
    setTestedKey(null);
    // console.log('nextWithDelay');
    timerRef.current = setTimeout(
      () => next(isKeyPressCorrect),
      stage === Stage.PUNISHMENT && !isLastPunishmentKey
        ? punishmentDelayTime
        : delayTime
    );
  };

  /**
   * установка следующей буквы и ее показ
   */
  const next: NextFn = (isKeyPressCorrect) => {
    // console.log('next', testedKey);
    // установить следующую букву
    // setNextTestedKey();
    setTestedKey(() => handleNext(isKeyPressCorrect));
    // начало реакции
    setReactionTimeStart(Number(new Date()));
    setIntervalTime(taktTime);
  };

  // const setNextTestedKey = useCallback(() => {
  //   const random = Math.floor(Math.random() * keysSet.length);

  //   setTestedKey(keysSet[random]);
  // }, [keysSet, testedKey]);

  const pause = () => {
    // console.log('PAUSE');
    setIsPaused(true);
    // pauseStopwatch();
    setTestedKey(null);
    setIntervalTime(undefined);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    log({
      type: 'pause',
      reaction: undefined,
      target: undefined,
      timecode: Number(new Date()) - startTime,
      pressedKey: undefined,
    });
  };

  const stop = () => {
    // console.log('STOP');
    setIntervalTime(undefined);
    setTestedKey(null);

    log({
      type: 'finish',
      reaction: undefined,
      target: undefined,
      timecode: Number(new Date()) - startTime,
      pressedKey: undefined,
    });
    setIsFinished(true);
  };

  const reset = () => {
    // console.log('RESET');
    // resetStopwatch();
    setIsPaused(false);
    setIsStarted(false);
    resetProgress();
    setTestedKey(null);
    setIntervalTime(undefined);
    setIsFinished(false);
    setLogs([]);
    // обнулять таймер задержки ?
  };

  const handleKeyUp = (_e: KeyboardEvent<HTMLInputElement>) => {
    // const { code } = e;
    // console.log('up: ', code);
    // setPressedKeys((prev) => {
    //   const copy = new Set(prev);
    //   copy.delete(code);
    //   return copy;
    // });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { code, shiftKey } = e;
    // console.log('down: ', code);
    if (
      testedKey &&
      (testedKey.code !== code || testedKey?.shiftKey !== shiftKey)
    ) {
      setPressedKeys((prev) => {
        const copy = new Set(prev);
        copy.add(code);
        return copy;
      });

      deleteTimerRef.current = setTimeout(() => {
        setPressedKeys((prev) => {
          const copy = new Set(prev);
          copy.delete(code);
          return copy;
        });
      }, 220);
    }
  };

  useEffect(() => {
    () => {
      if (deleteTimerRef.current) {
        clearTimeout(deleteTimerRef.current);
      }
    };
  }, [deleteTimerRef]);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const canBeTestStarted = isFocused;
    if (!canBeTestStarted) {
      return;
    }

    const { key, shiftKey, code } = e;
    // console.log('press: ', code);
    // напоминание: есть свойство repeat, если нужно

    // console.log({ key, shiftKey, code }, e);

    if (!isStarted && code === 'Space' && !shiftKey) {
      // --> start

      start();
      return;
    } else if (isStarted && code === 'Space' && shiftKey) {
      // start --> pause
      pause();
      return;
    } else if (isStarted && isPaused && code === 'Space') {
      // pause --> continue
      // startStopwatch();
      setIsPaused(false);
      setReactionTimeStart(Number(new Date()));
      // показ другой буквы без влияния на прогресс !
      setTestedKey(changeKey());
      setIntervalTime(taktTime);
      log({
        type: 'continue',
        reaction: undefined,
        target: undefined,
        timecode: Number(new Date()) - startTime,
        pressedKey: undefined,
      });
      return;
    }

    if (testedKey && /^.$/u.test(key)) {
      // TODO: если использовать useCallback нельзя менять taktTimeRef.current здесь и в функции
      // nextWithDelay ниже , потому что кэшируется ж
      setIntervalTime(undefined);
      const time = Number(new Date());
      const reaction = time - reactionTimeStart;
      // console.log('WRITEN: ', key);
      // TODO: переписать условие ?
      const isKeyPressCorrect =
        (mergedOptions.emulated &&
          code === testedKey.code &&
          shiftKey === testedKey.shiftKey) ||
        (!mergedOptions.emulated && key === testedKey.key);

      log({
        type: isKeyPressCorrect ? 'correctKeystroke' : 'wrongKeystroke',
        reaction,
        target: testedKey,
        timecode: time - startTime,
        pressedKey: {
          code,
          shiftKey,
          key: mergedOptions.emulated
            ? getKey(code, mergedOptions.layoutId, shiftKey) ?? key
            : key,
        },
        isBlindly,
      });
      // console.log('?: :', { isKeyPressCorrect, key: testedKey.key });
      isKeyPressCorrect && testedKey.isLastKey
        ? stop()
        : nextWithDelay(isKeyPressCorrect);
    } else if (!isPaused && !testedKey && /^.$/u.test(key)) {
      log({
        type: 'blot',
        reaction: undefined,
        target: undefined,
        timecode: Number(new Date()) - startTime,
        pressedKey: {
          code,
          shiftKey,
          key: mergedOptions.emulated
            ? getKey(code, mergedOptions.layoutId, shiftKey) ?? key
            : key,
        },
      });
    }
  };

  const handleFocus = () => {
    // console.log('FOCUS');
    setIsFocused(true);
    setPressedKeys(new Set());
    // pressedKeysRef.current = new Set();
  };

  const handleBlur = () => {
    // console.log('blur');
    if (isStarted && !isPaused) {
      pause();
    }
    setIsFocused(false);
    setPressedKeys(new Set());
    // pressedKeysRef.current = new Set();
  };

  const log = (event: LessonEvent) => {
    setLogs((prevState) => [...prevState, event]);
    // console.log(event.type);
  };

  return {
    testedKey,
    pressedKeys,
    isBlindly,
    progress1,
    progress2: isFinished ? 100 : progress2,
    reset,

    seconds: 3,
    logs,
    isPaused,
    isStarted,
    isFinished,
    isFocused,
    handleKeyPress,
    handleKeyDown,
    handleKeyUp,
    handleFocus,
    handleBlur,
  };
};

export default useLesson;
