import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import http from 'i18next-http-backend';

i18n
  .use(http)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // resources: {
    //   en: languageEN,
    //   ru: languageRU,
    // },
    /* default language when load the website in browser */
    lng: 'ru',
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: 'ru',
    /* debugger For Development environment */
    debug: process.env.NODE_ENV !== 'production',
    // ns: ['translation'],
    // defaultNS: 'translation',
    // keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
