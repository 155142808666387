import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';

export interface Notification extends NotificationOptions {
  key: SnackbarKey;
  message: SnackbarMessage;
  dismissed?: boolean;
  // options?: OptionsObject;
}

interface NotificationOptions {
  options?: OptionsObject;
}

type notifierSliceState = {
  notifications: Notification[];
};

const initialState: notifierSliceState = {
  notifications: [],
};

export const notifierSlice = createSlice({
  name: 'notifier',
  initialState,
  reducers: {
    enqueueSnackbar: {
      reducer(
        // state,
        state: notifierSliceState,
        action: PayloadAction<
          { message: SnackbarMessage },
          string,
          { options?: OptionsObject }
        >
      ) {
        const key: SnackbarKey | undefined = action.meta?.options?.key;

        state.notifications.push({
          key: key ?? String(new Date().getTime() + Math.random()),
          message: action.payload.message,
          // options: action.meta?.options,
          ...(action.meta?.options && { options: action.meta?.options }),
        });
      },
      prepare(payload: { message: SnackbarMessage }, options?: OptionsObject) {
        return { payload, meta: { options } };
      },
    },
    closeSnackbar: (state, action: PayloadAction<{ key?: SnackbarKey }>) => {
      const dismissAll = !action.payload.key;

      state.notifications = state.notifications.map((notification) =>
        dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeSnackbar(state, action: PayloadAction<{ key: SnackbarKey }>) {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload.key
      );
    },
  },
});

// Action creators are generated for each case reducer function
export const { enqueueSnackbar, closeSnackbar, removeSnackbar } =
  notifierSlice.actions;

export default notifierSlice.reducer;
