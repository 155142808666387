import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

interface Props extends SvgIconProps {}

const PointerCursorIcon: FC<Props> = ({
  width = '48px',
  height = '48px',
  ...props
}) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      {...props}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path d="M13 2c-1.645 0-3 1.355-3 3v11.813l-.656-.688-.25-.219a2.968 2.968 0 00-4.188 0 2.968 2.968 0 000 4.188v.031l8.188 8.094.062.031.031.063a8.307 8.307 0 005 1.687h1.72a8.17 8.17 0 008.187-8.188V14c0-1.645-1.356-3-3-3-.426 0-.82.117-1.188.281C23.578 9.981 22.395 9 21 9c-.766 0-1.469.3-2 .781A2.984 2.984 0 0017 9a2.95 2.95 0 00-1 .188V5c0-1.645-1.355-3-3-3zm0 2c.555 0 1 .445 1 1v11h2v-4c0-.555.445-1 1-1 .555 0 1 .445 1 1v4h2v-4c0-.555.445-1 1-1 .555 0 1 .445 1 1v4h2.094v-2c0-.555.445-1 1-1 .554 0 1 .445 1 1v7.813c0 3.464-2.723 6.187-6.188 6.187h-1.718c-1.465 0-2.731-.523-3.782-1.313l-8.094-8c-.445-.445-.445-.93 0-1.375.446-.445.93-.445 1.375 0L12 21.625V5c0-.555.445-1 1-1z"></path>
    </SvgIcon>
  );
};
export default PointerCursorIcon;
