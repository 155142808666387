/**
 * https://w3c.github.io/uievents-code/#code-value-tables
 */

import { KeyDefinition } from 'domains/keyboard/KeyDefinition';

export const colemak: KeyDefinition[] = [
  { code: 'Backquote', shiftKey: false, key: '`', type: 'symbol' },
  { code: 'Backquote', shiftKey: true, key: '~', type: 'symbol' },
  { code: 'Digit1', shiftKey: false, key: '1', type: 'digit' },
  { code: 'Digit1', shiftKey: true, key: '!', type: 'symbol' },
  { code: 'Digit2', shiftKey: false, key: '2', type: 'digit' },
  { code: 'Digit2', shiftKey: true, key: '@', type: 'symbol' },
  { code: 'Digit3', shiftKey: false, key: '3', type: 'digit' },
  { code: 'Digit3', shiftKey: true, key: '#', type: 'symbol' },
  { code: 'Digit4', shiftKey: false, key: '4', type: 'digit' },
  { code: 'Digit4', shiftKey: true, key: '$', type: 'symbol' },
  { code: 'Digit5', shiftKey: false, key: '5', type: 'digit' },
  { code: 'Digit5', shiftKey: true, key: '%', type: 'symbol' },
  { code: 'Digit6', shiftKey: false, key: '6', type: 'digit' },
  { code: 'Digit6', shiftKey: true, key: '^', type: 'symbol' },
  { code: 'Digit7', shiftKey: false, key: '7', type: 'digit' },
  { code: 'Digit7', shiftKey: true, key: '&', type: 'symbol' },
  { code: 'Digit8', shiftKey: false, key: '8', type: 'digit' },
  { code: 'Digit8', shiftKey: true, key: '*', type: 'symbol' },
  { code: 'Digit9', shiftKey: false, key: '9', type: 'digit' },
  { code: 'Digit9', shiftKey: true, key: '(', type: 'symbol' },
  { code: 'Digit0', shiftKey: false, key: '0', type: 'digit' },
  { code: 'Digit0', shiftKey: true, key: ')', type: 'symbol' },
  {
    code: 'Minus',
    shiftKey: false,
    key: '-',
    type: 'symbol',
    alternate: 'IntlBackslash',
  },
  {
    code: 'Minus',
    shiftKey: true,
    key: '_',
    type: 'symbol',
    alternate: 'IntlBackslash',
  },
  { code: 'Equal', shiftKey: false, key: '=', type: 'symbol' },
  { code: 'Equal', shiftKey: true, key: '+', type: 'symbol' },
  { code: 'Backspace', key: 'Backspace', type: 'sys' },
  { code: 'Tab', key: 'Tab', type: 'sys' },
  { code: 'KeyQ', shiftKey: false, key: 'q', type: 'letter' },
  { code: 'KeyQ', shiftKey: true, key: 'Q', type: 'letter' },
  { code: 'KeyW', shiftKey: false, key: 'w', type: 'letter' },
  { code: 'KeyW', shiftKey: true, key: 'W', type: 'letter' },
  { code: 'KeyE', shiftKey: false, key: 'f', type: 'letter' },
  { code: 'KeyE', shiftKey: true, key: 'F', type: 'letter' },
  { code: 'KeyR', shiftKey: false, key: 'p', type: 'letter' },
  { code: 'KeyR', shiftKey: true, key: 'P', type: 'letter' },
  { code: 'KeyT', shiftKey: false, key: 'g', type: 'letter' },
  { code: 'KeyT', shiftKey: true, key: 'G', type: 'letter' },
  { code: 'KeyY', shiftKey: false, key: 'j', type: 'letter' },
  { code: 'KeyY', shiftKey: true, key: 'J', type: 'letter' },
  { code: 'KeyU', shiftKey: false, key: 'l', type: 'letter' },
  { code: 'KeyU', shiftKey: true, key: 'L', type: 'letter' },
  { code: 'KeyI', shiftKey: false, key: 'u', type: 'letter' },
  { code: 'KeyI', shiftKey: true, key: 'U', type: 'letter' },
  { code: 'KeyO', shiftKey: false, key: 'y', type: 'letter' },
  { code: 'KeyO', shiftKey: true, key: 'Y', type: 'letter' },
  { code: 'KeyP', shiftKey: false, key: ';', type: 'symbol' },
  { code: 'KeyP', shiftKey: true, key: ':', type: 'symbol' },
  { code: 'BracketLeft', shiftKey: false, key: '[', type: 'symbol' },
  { code: 'BracketLeft', shiftKey: true, key: '{', type: 'symbol' },
  { code: 'BracketRight', shiftKey: false, key: ']', type: 'symbol' },
  { code: 'BracketRight', shiftKey: true, key: '}', type: 'symbol' },
  {
    code: 'Backslash',
    shiftKey: false,
    key: '\\',
    type: 'symbol',
  },
  {
    code: 'Backslash',
    shiftKey: true,
    key: '|',
    type: 'symbol',
  },
  { code: 'CapsLock', key: 'CapsLock', type: 'sys' },
  { code: 'KeyA', shiftKey: false, key: 'a', type: 'letter' },
  { code: 'KeyA', shiftKey: true, key: 'A', type: 'letter' },
  { code: 'KeyS', shiftKey: false, key: 'r', type: 'letter' },
  { code: 'KeyS', shiftKey: true, key: 'R', type: 'letter' },
  { code: 'KeyD', shiftKey: false, key: 's', type: 'letter' },
  { code: 'KeyD', shiftKey: true, key: 'S', type: 'letter' },
  { code: 'KeyF', shiftKey: false, key: 't', type: 'letter' },
  { code: 'KeyF', shiftKey: true, key: 'T', type: 'letter' },
  { code: 'KeyG', shiftKey: false, key: 'd', type: 'letter' },
  { code: 'KeyG', shiftKey: true, key: 'D', type: 'letter' },
  { code: 'KeyH', shiftKey: false, key: 'h', type: 'letter' },
  { code: 'KeyH', shiftKey: true, key: 'H', type: 'letter' },
  { code: 'KeyJ', shiftKey: false, key: 'n', type: 'letter' },
  { code: 'KeyJ', shiftKey: true, key: 'N', type: 'letter' },
  { code: 'KeyK', shiftKey: false, key: 'e', type: 'letter' },
  { code: 'KeyK', shiftKey: true, key: 'E', type: 'letter' },
  { code: 'KeyL', shiftKey: false, key: 'i', type: 'letter' },
  { code: 'KeyL', shiftKey: true, key: 'I', type: 'letter' },
  { code: 'Semicolon', shiftKey: false, key: 'o', type: 'letter' },
  { code: 'Semicolon', shiftKey: true, key: 'O', type: 'letter' },
  { code: 'Quote', shiftKey: false, key: "'", type: 'symbol' },
  { code: 'Quote', shiftKey: true, key: '"', type: 'symbol' },
  { code: 'Enter', key: 'Enter', type: 'sys' },
  { code: 'ShiftLeft', key: 'Shift', type: 'sys' },
  {
    code: 'IntlBackslash',
    shiftKey: false,
    key: '-',
    type: 'symbol',
    alternate: 'Minus',
  },
  {
    code: 'IntlBackslash',
    shiftKey: true,
    key: '_',
    type: 'symbol',
    alternate: 'Minus',
  },
  { code: 'KeyZ', shiftKey: false, key: 'z', type: 'letter' },
  { code: 'KeyZ', shiftKey: true, key: 'Z', type: 'letter' },
  { code: 'KeyX', shiftKey: false, key: 'x', type: 'letter' },
  { code: 'KeyX', shiftKey: true, key: 'X', type: 'letter' },
  { code: 'KeyC', shiftKey: false, key: 'c', type: 'letter' },
  { code: 'KeyC', shiftKey: true, key: 'C', type: 'letter' },
  { code: 'KeyV', shiftKey: false, key: 'v', type: 'letter' },
  { code: 'KeyV', shiftKey: true, key: 'V', type: 'letter' },
  { code: 'KeyB', shiftKey: false, key: 'b', type: 'letter' },
  { code: 'KeyB', shiftKey: true, key: 'B', type: 'letter' },
  { code: 'KeyN', shiftKey: false, key: 'k', type: 'letter' },
  { code: 'KeyN', shiftKey: true, key: 'K', type: 'letter' },
  { code: 'KeyM', shiftKey: false, key: 'm', type: 'letter' },
  { code: 'KeyM', shiftKey: true, key: 'M', type: 'letter' },
  { code: 'Comma', shiftKey: false, key: ',', type: 'symbol' },
  { code: 'Comma', shiftKey: true, key: '<', type: 'symbol' },
  { code: 'Period', shiftKey: false, key: '.', type: 'symbol' },
  { code: 'Period', shiftKey: true, key: '>', type: 'symbol' },
  { code: 'Slash', shiftKey: false, key: '/', type: 'symbol' },
  { code: 'Slash', shiftKey: true, key: '?', type: 'symbol' },
  { code: 'ShiftRight', key: 'Shift', type: 'sys' },
  { code: 'ControlLeft', key: 'Ctrl', type: 'sys' },
  { code: 'MetaLeft', key: '', type: 'sys' },
  { code: 'AltLeft', key: 'Alt', type: 'sys' },
  { code: 'Space', key: '', type: 'sys' },
  { code: 'AltRight', key: 'Alt', type: 'sys' },
  { code: 'MetaRight', key: 'Meta', type: 'sys' },
  { code: 'ContextMenu', key: 'ContextMenu', type: 'sys' },
  { code: 'ControlRight', key: 'Ctrl', type: 'sys' },
];
