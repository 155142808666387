import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Keyboard from 'components/CreateNewEx/Keyboard';
import { convertFormValuesToDbValues } from 'utils/convertFormValuesToValuesForDb';
import StyledDialogTitle from 'components/StyledTitle';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import getKeysBy from 'domains/keyboard/logicalLayouts/getLogicalLayoutById';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { LessonProps } from 'domains/lessons/models/LessonProps';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useMemo } from 'react';
import { array, object, string } from 'yup';

interface Props {
  physicalLayout: PhysicalLayout;
  logicalLayout: LogicalLayout;
  onSubmit: (lesson: LessonProps) => void;
  onClose: () => void;
  loading: boolean;
}

const CreateNewExDialog: FC<Props> = ({
  physicalLayout,
  logicalLayout,
  onClose,
  onSubmit,
  loading,
}) => {
  const layoutKeys = useMemo(() => getKeysBy(logicalLayout), [logicalLayout]);

  const layoutKeysWithoutShift = useMemo(
    () =>
      layoutKeys?.filter((key) =>
        //TODO: был баг, что IntlBackslash тоже попадалась в ansi раскладке, что-тонадо сделать, чтобы избавиться от условия ниже
        physicalLayout === 'iso'
          ? !key.shiftKey && key.type !== 'sys'
          : !key.shiftKey && key.type !== 'sys' && key.code !== 'IntlBackslash'
      ),
    [layoutKeys]
  );
  const layoutKeysWithShift = useMemo(
    () =>
      layoutKeys?.filter((key) =>
        physicalLayout === 'iso'
          ? !key.shiftKey && key.type !== 'sys'
          : !key.shiftKey && key.type !== 'sys' && key.code !== 'IntlBackslash'
      ),
    [layoutKeys]
  );

  const validationSchema = object()
    .shape({
      title: string()
        .required()
        .max(32, 'Название должно быть не длиннее 32-х символов'),
      keys: array().of(string()),
      keysWithShift: array().of(string()),
    })
    .test({
      name: 'minCharsNumber',
      test: function (values) {
        const isValid =
          [...(values['keys'] ?? []), ...(values['keysWithShift'] ?? [])]
            .length >= 3;

        if (isValid) return true;
        return this.createError({
          path: 'minCharsNumber',
          message: 'Упражнение должно содержать не менее 3х клавиш',
        });
      },
    });

  const formik = useFormik({
    initialValues: {
      keysWithShift: [],
      keys: [],
      title: `exersice_${+new Date()}`,
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log(
      //   // { keys: values.keys, keysWithShift: values.keysWithShift },
      //   {
      //     title: values.title,
      //     logicalLayout,
      //     physicalLayouts: [physicalLayout],
      //     type: 'custom',
      //     chars: convertFormValuesToDbValues(layoutKeys, {
      //       keys: values.keys,
      //       keysWithShift: values.keysWithShift,
      //     }),
      //   },
      //   null,
      //   2
      // );

      onSubmit({
        title: values.title,
        logicalLayout,
        physicalLayouts: [physicalLayout],
        type: 'custom',
        chars: convertFormValuesToDbValues(layoutKeys, {
          keys: values.keys,
          keysWithShift: values.keysWithShift,
        }),
      });
    },
  });

  const handleCheckAllKeysChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    formik.setFieldValue(
      'keys',
      target.checked ? layoutKeysWithoutShift.map((keyObj) => keyObj.code) : []
    );
  };

  const handleCheckAllKeysWithShiftChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.currentTarget;
    formik.setFieldValue(
      'keysWithShift',
      target.checked ? layoutKeysWithShift.map((keyObj) => keyObj.code) : []
    );
  };

  return (
    <>
      <StyledDialogTitle id="keyboard-setup-dialog-title" onClose={onClose}>
        Новое упражнение
      </StyledDialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            // gap: 3,
            width: '100%',
          }}
        >
          <TextField
            id="title"
            name="title"
            label="Название"
            variant="standard"
            value={formik.values.title}
            onChange={formik.handleChange}
            autoFocus
            sx={{
              mb: 2,
            }}
            onBlur={formik.handleBlur}
            error={!!formik.errors.title}
            helperText={formik.errors.title}
          />

          <FormControl sx={{}} component="fieldset" variant="standard">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FormLabel component="legend">без Shift</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    id="checkAllKeys"
                    checked={
                      formik.values.keys.length ===
                      layoutKeysWithoutShift.length
                    }
                    onChange={handleCheckAllKeysChange}
                    name="checkAllKeys"
                    size="small"
                    indeterminate={
                      formik.values.keys.length !== 0 &&
                      formik.values.keys.length !==
                        layoutKeysWithoutShift.length
                    }
                  />
                }
                label="Выбрать все"
              />
            </Box>
            <FormGroup>
              <Keyboard
                physicalLayout={physicalLayout}
                logicalLayout={logicalLayout}
                checkedKeys={formik.values.keys}
                handleChange={formik.handleChange}
                name="keys"
                shift={false}
              />
            </FormGroup>
          </FormControl>

          <Box sx={{}} />
          <FormControl sx={{}} component="fieldset" variant="standard">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FormLabel component="legend">с Shift</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    id="checkAllKeysWithShift"
                    checked={
                      formik.values.keysWithShift.length ===
                      layoutKeysWithShift.length
                    }
                    onChange={handleCheckAllKeysWithShiftChange}
                    name="checkAllKeysWithShift"
                    size="small"
                    indeterminate={
                      formik.values.keysWithShift.length !== 0 &&
                      formik.values.keysWithShift.length !==
                        layoutKeysWithShift.length
                    }
                  />
                }
                label="Выбрать все"
              />
            </Box>

            <FormGroup>
              <Keyboard
                physicalLayout={physicalLayout}
                logicalLayout={logicalLayout}
                checkedKeys={formik.values.keysWithShift}
                handleChange={formik.handleChange}
                name="keysWithShift"
                shift={true}
              />
            </FormGroup>
            {formik.touched.keys &&
              formik.touched.keysWithShift &&
              formik.errors.minCharsNumber && (
                <FormHelperText
                  error={
                    formik.touched.keys &&
                    formik.touched.keysWithShift &&
                    formik.errors.minCharsNumber
                  }
                >
                  {formik.errors.minCharsNumber}
                </FormHelperText>
              )}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', width: '40%', margin: 'auto' }}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          type="submit"
          onClick={formik.handleSubmit}
        >
          {loading ? '...' : 'Добавить'}
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateNewExDialog;
