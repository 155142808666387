// Core
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import { apply, put } from 'redux-saga/effects';
// Actions
import { authenticate, startFetching, stopFetching } from 'domains/auth/slice';
import { enqueueSnackbar } from 'domains/notifier/slice';
// Instruments
import { setUser } from 'domains/user/slice';
import api from 'REST/api';

function* signin({ payload: { email, password, remember } }) {
  try {
    yield put(startFetching());

    const response = yield apply(api, api.auth.login, [
      { email, password, remember },
    ]);

    const { data: user, message } = yield apply(response, response.json);

    if (response.status !== 200) {
      throw new Error(message);
    }

    yield put(setUser(user));

    yield put(authenticate());
  } catch (error) {
    // console.log({ error });
    yield put(
      enqueueSnackbar(
        { message: error.message },
        {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: (key) => <NotifierCloseButton notifierKey={key} />,
        }
      )
    );
  } finally {
    yield put(stopFetching());
  }
}

export default signin;
