import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import SVGKeyboard from 'components/SVGKeyboard';
import LayoutLanguageSelect from 'components/KeyboardSetup/Dialog/selects/LayoutLanguageSelect';
import LogicalLayoutSelect from 'components/KeyboardSetup/Dialog/selects/LogicalLayoutSelect';
import PhysicalLayoutSelect from 'components/KeyboardSetup/Dialog/selects/PhysicalLayoutSelect';
import {
  LayoutLanguage,
  layoutLanguages,
} from 'domains/keyboard/LayoutLangage';
import { layouts } from 'domains/keyboard/layouts';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { UserSettings } from 'domains/user/UserSettings';

import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch } from 'hooks';

export interface KeyboardSetupDialogValues {
  language: LayoutLanguage;
  logicalLayout: LogicalLayout;
  physicalLayout: PhysicalLayout;
}

interface Props {
  onSubmit: (userSettings: UserSettings) => void;
  physicalLayout?: PhysicalLayout | null;
  logicalLayout?: LogicalLayout | null;
  isLoading: boolean;
}
const defaultLayout = layouts.us_qwerty;

const InitialKeyboardSetup: FC<Props> = ({
  onSubmit,
  physicalLayout,
  logicalLayout,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const initialValues: KeyboardSetupDialogValues =
    physicalLayout && logicalLayout
      ? {
          physicalLayout,
          logicalLayout,
          language: layouts[logicalLayout].language,
        }
      : {
          language: defaultLayout.language,
          logicalLayout: 'us_qwerty',
          physicalLayout: defaultLayout.availablePhysicalLayouts[0],
        };

  const validationSchema = object().shape({
    language: string().required(),
    logicalLayout: string().required(),
    physicalLayout: string().required(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // console.log('keyboard setup: ', { values });
      onSubmit({
        logicalLayout: values.logicalLayout,
        physicalLayout: values.physicalLayout,
      });
    },
    validationSchema,
    enableReinitialize: true,
  });

  const [logicalLayoutOptions, setLogicalLayoutOptions] = useState(
    getKeyByOptionValue(layouts, 'language', formik.values.language)
  );

  const [physicalLayoutOptions, setPhysicalLayoutOptions] = useState(
    layouts[formik.values.logicalLayout]?.availablePhysicalLayouts
  );

  useEffect(() => {
    const physicalLayouts =
      layouts[formik.values.logicalLayout]?.availablePhysicalLayouts;
    setPhysicalLayoutOptions(physicalLayouts);

    formik.setFieldValue(
      'physicalLayout',
      physicalLayouts.includes(formik.values.physicalLayout)
        ? physicalLayouts.find(
            (keyboard) => keyboard === formik.values.physicalLayout
          )
        : physicalLayouts[0]
    );
  }, [formik.values.logicalLayout]);

  useEffect(() => {
    const logicalLayouts = getKeyByOptionValue(
      layouts,
      'language',
      formik.values.language
    ) as LogicalLayout[];
    setLogicalLayoutOptions(logicalLayouts);
    //TODO: хук срабатывает при реинициализации, поэтому добавлено условие ниже ,
    // подумать, как сделать по другому или сделать как в хуке выше
    if (!logicalLayouts.includes(formik.values.logicalLayout)) {
      formik.setFieldValue('logicalLayout', logicalLayouts[0]);
    }
  }, [formik.values.language]);

  return (
    <>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            gap: 3,
            width: '100%',
            mb: 2,
          }}
        >
          <LayoutLanguageSelect
            name="language"
            label={t('labels.language')}
            value={formik.values.language}
            onChange={formik.handleChange}
            options={[...layoutLanguages]}
          />
          <LogicalLayoutSelect
            name="logicalLayout"
            label={t('labels.layout')}
            value={formik.values.logicalLayout}
            onChange={formik.handleChange}
            options={[...logicalLayoutOptions]}
          />
          <PhysicalLayoutSelect
            name="physicalLayout"
            label={t('labels.keyboard').toString()}
            value={formik.values.physicalLayout}
            onChange={formik.handleChange}
            options={physicalLayoutOptions}
          />
        </Box>
        <Box sx={{ width: '100%', margin: '0 auto', mb: 2 }}>
          <SVGKeyboard
            physicalLayout={formik.values.physicalLayout}
            logicalLayout={formik.values.logicalLayout}
            //colorSchema
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <LoadingButton
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={isLoading}
            loading={isLoading}
            variant="outlined"
            color="info"
            type="submit"
            sx={{ minWidth: '160px', textAlign: 'center' }}
          >
            {t('next')}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default InitialKeyboardSetup;

function getKeyByOptionValue(
  object: any,
  option: string,
  value: string
): string[] {
  return (
    Object.keys(object).filter((key) => object[key][option] === value) ?? []
  );
}
