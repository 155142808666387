import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import { enqueueSnackbar } from 'domains/notifier/slice';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import { useAppDispatch } from 'hooks';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import api from 'REST/api';
import { boolean, object, string } from 'yup';

interface Props {}

const PasswordResetPage: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const params: { token: string } = useParams();
  const { token } = params;

  const initialValues = {
    newPassword: '',
    showPassword: false,
  };

  const validationSchema = object().shape({
    newPassword: string().min(6, t('login_password_length_error')).required(),
    showPassword: boolean(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      mutate({ newPassword: values.newPassword, token: token });
    },
    validationSchema,
  });

  const { mutate, isLoading } = useMutation(api.auth.resetPassword, {
    onSuccess: () => {
      dispatch(
        enqueueSnackbar(
          { message: 'Новый пароль успешно установлен!' },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        )
      );
    },
    onError: (error) => {
      dispatch(
        enqueueSnackbar(
          { message: error.message },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        )
      );
    },
  });

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Helmet>
        <title>Восстановление доступа - keycap.pro</title>
      </Helmet>
      <Container maxWidth="xs" sx={{ mt: '140px' }}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          sx={{ textTransform: 'uppercase', fontWeight: 'bold', mb: '50px' }}
        >
          Восстановление пароля
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <FormControl
            variant="outlined"
            margin="normal"
            sx={{ width: '100%' }}
          >
            <InputLabel
              htmlFor="password"
              sx={
                formik.touched.newPassword && formik.errors.newPassword
                  ? {
                      color: 'error.main',
                      '&.Mui-focused': { color: 'error.main' },
                    }
                  : {}
              }
            >
              Новый пароль
            </InputLabel>
            <OutlinedInput
              id="newPassword"
              name="newPassword"
              fullWidth
              type={formik.values.showPassword ? 'text' : 'password'}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && !!formik.errors.newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      formik.setFieldValue(
                        'showPassword',
                        !formik.values.showPassword
                      );
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {formik.values.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Новый пароль"
            />
            <FormHelperText
              id="outlined-weight-helper-text"
              sx={
                formik.touched.newPassword && formik.errors.newPassword
                  ? { color: 'error.main' }
                  : {}
              }
            >
              {formik.touched.newPassword && formik.errors.newPassword}
            </FormHelperText>
          </FormControl>
          {isLoading && <LinearProgress />}
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Установить новый пароль
          </LoadingButton>
        </Box>
      </Container>
    </>
  );
};

export default PasswordResetPage;
