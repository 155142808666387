import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '@mui/lab/LoadingButton';
import types from 'domains/auth/types';
import { passwordForget } from 'domains/forms/shapes';
import { useAppDispatch, useAppSelector } from 'hooks';

interface Props {}

const PasswordForgetForm: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isFetching = useAppSelector((state) => state.auth.isFetching);

  const formik = useFormik({
    initialValues: passwordForget.shape,
    onSubmit: (values) => {
      dispatch({
        type: types.FORGOT_PASSWORD_ASYNC,
        payload: values.email,
      });
    },
    validationSchema: passwordForget.schema,
  });

  return (
    <>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{ textTransform: 'none', fontWeight: 'bold', mb: '50px' }}
      >
        {t('password_resetting')}
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          fullWidth
          id="email"
          name="email"
          label={t('email')}
          margin="normal"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          loading={isFetching}
          disabled={isFetching}
        >
          {t('reset_password')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default PasswordForgetForm;
