import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Keyboard from 'components/CreateNewEx/Keyboard';
import LessonList from 'components/Lessons';
import getKeysBy from 'domains/keyboard/logicalLayouts/getLogicalLayoutById';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import getTypingZones from 'domains/keyboard/typingZones/getTypingZones';
import { Lesson } from 'domains/lessons/models/Lesson';
import type { TestResults } from 'domains/test/TestResults';
import { physicalLayoutSelector } from 'domains/user/selectors/settings';
import { useFormik } from 'formik';
import { useAppSelector } from 'hooks';
import useExercises from 'hooks/useExercises';
import useLocalStorage from 'hooks/useLocalStorage';
import humanizeDuration from 'humanize-duration';
import * as ROUTES from 'navigation/routes';
import { ChangeEvent, FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { convertFormValuesToDbValues } from 'utils/convertFormValuesToValuesForDb';
import { formatAccuracy, formatReaction } from 'utils/format';
import { array, object, string } from 'yup';

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}

const StyledDialogTitle: FC<DialogTitleProps> = ({
  children,
  onClose,
  ...other
}) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  statsInfo: TestResults;
}

const TestResultDialog: FC<Props> = ({ onClose, onSubmit, statsInfo }) => {
  const { t } = useTranslation();

  const physicalLayout: PhysicalLayout | null = useAppSelector(
    physicalLayoutSelector
  );

  const fingerZones = useMemo(
    () => getTypingZones(physicalLayout),
    [physicalLayout]
  );

  const [createdLessons, setCreatedLessons] = useState<Lesson[]>([]);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const [showCreateNewExInfoInTest, setShowCreateNewExInfoInTest] =
    useLocalStorage<boolean | undefined>('showCreateNewExInfoInTest', true);

  const {
    createExerciseMutation,
    createExerciseLoading,
    deleteExercise,
    deleteExerciseLoading,
  } = useExercises(addNewExerciseCb, deleteExerciseCb);

  function deleteExerciseCb(lessonId: lessonId): void {
    setCreatedLessons((prev) =>
      prev.filter((lesson) => lesson._id !== lessonId)
    );
  }

  function addNewExerciseCb(addedLesson: Lesson): void {
    setCreatedLessons((prev) => [addedLesson, ...prev]);
    formik.resetForm({
      values: {
        keysWithShift: [],
        keys: [],
        title: `exersice_${+new Date()}`,
      },
    });
  }

  const layoutKeys = useMemo(
    () => getKeysBy(statsInfo.logicalLayout),
    [statsInfo]
  );

  const layoutKeysWithoutShift = useMemo(
    () =>
      layoutKeys?.filter((key) =>
        //TODO: был баг, что IntlBackslash тоже попадалась в ansi раскладке, что-тонадо сделать, чтобы избавиться от условия ниже
        statsInfo.physicalLayout === 'iso'
          ? !key.shiftKey && key.type !== 'sys'
          : !key.shiftKey && key.type !== 'sys' && key.code !== 'IntlBackslash'
      ),
    [layoutKeys]
  );
  const layoutKeysWithShift = useMemo(
    () =>
      layoutKeys?.filter((key) =>
        statsInfo.physicalLayout === 'iso'
          ? !key.shiftKey && key.type !== 'sys'
          : !key.shiftKey && key.type !== 'sys' && key.code !== 'IntlBackslash'
      ),
    [layoutKeys]
  );

  const validationSchema = object()
    .shape({
      title: string()
        .required()
        .max(32, 'Название должно быть не длиннее 32-х символов'),
      keys: array().of(string()),
      keysWithShift: array().of(string()),
    })
    .test({
      name: 'minCharsNumber',
      test: function (values) {
        const isValid =
          [...(values['keys'] ?? []), ...(values['keysWithShift'] ?? [])]
            .length >= 3;

        if (isValid) return true;
        return this.createError({
          path: 'minCharsNumber',
          message: 'Упражнение должно содержать не менее 3х клавиш',
        });
      },
    });

  const formik = useFormik({
    initialValues: {
      keysWithShift: [],
      keys: [],
      title: `exersice_${+new Date()}`,
    },
    validationSchema,
    onSubmit: (values) => {
      // alert(
      //   JSON.stringify(
      //     // { keys: values.keys, keysWithShift: values.keysWithShift },
      //     {
      //       title: values.title,
      //       logicalLayout: statsInfo.logicalLayout,
      //       physicalLayouts: [statsInfo.physicalLayout],
      //       type: 'custom',
      //       chars: convertFormValuesToDbValues(layoutKeys, {
      //         keys: values.keys,
      //         keysWithShift: values.keysWithShift,
      //       }),
      //     },
      //     null,
      //     2
      //   )
      // );

      createExerciseMutation({
        title: values.title,
        logicalLayout: statsInfo.logicalLayout,
        physicalLayouts: [statsInfo.physicalLayout],
        type: 'test',
        chars: convertFormValuesToDbValues(layoutKeys, {
          keys: values.keys,
          keysWithShift: values.keysWithShift,
        }),
      });
    },
  });

  const handleCheckAllKeysChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    formik.setFieldValue(
      'keys',
      target.checked ? layoutKeysWithoutShift.map((keyObj) => keyObj.code) : []
    );
  };

  const handleCheckAllKeysWithShiftChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.currentTarget;
    formik.setFieldValue(
      'keysWithShift',
      target.checked ? layoutKeysWithShift.map((keyObj) => keyObj.code) : []
    );
  };

  return (
    <>
      <StyledDialogTitle id="keyboard-setup-dialog-title" onClose={onClose}>
        {t('testResults')}
      </StyledDialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: 3,
            width: '100%',
            mb: 0,
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {/* "accuracy": "Точность", "averageReaction": "Среднее время нажатия",
            "numKeystrokes": "Всего нажатий", "numCorrectKeystrokes": "Верных
            нажатий", "numWrongMissedKeystrokes": "Ошибок (пропусков)", */}
            <Grid item xs={6}>
              <Typography>{t('totalTime')}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`${humanizeDuration(statsInfo.totalTime, {
                language: 'ru',
                units: ['m', 's'],
                delimiter: ' ',
                round: true,
              })}`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('accuracy')}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`${formatAccuracy(
                statsInfo.accuracy
              )}%`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('averageReaction')}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {isNaN(statsInfo.averageReaction) || !statsInfo.averageReaction
                  ? '-'
                  : `${formatReaction(statsInfo.averageReaction)} ${t('ms')}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('numCorrectKeystrokes')}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{statsInfo.numCorrectKeystrokes}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('numWrongMissedKeystrokes')}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {statsInfo.numWrongKeystrokes}
                {`(${statsInfo.numMissedKeystrokes})`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {isAuthenticated && (
          <>
            <Divider sx={{ mb: 2, mt: 4 }} />
            {showCreateNewExInfoInTest && (
              <Alert
                icon={false}
                role="info"
                color="info"
                onClose={() => {
                  setShowCreateNewExInfoInTest(false);
                }}
                sx={{ mb: 2 }}
              >
                Выберите клавиши, которые Вы хотите отработать и нажмите
                &quot;Добавить урок&quot;. Добавленный урок будет доступен в
                разделе уроков &quot;Мои&quot;.
              </Alert>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                // gap: 3,
                width: '100%',
              }}
            >
              <Typography
                component="p"
                align="left"
                variant="h6"
                sx={{ mb: 2 }}
              >
                Добавление урока
              </Typography>
              <TextField
                id="title"
                name="title"
                label="Название"
                variant="standard"
                value={formik.values.title}
                onChange={(event) => {
                  const { currentTarget } = event;
                  formik.setFieldValue('title', currentTarget.value);
                }}
                // autoFocus
                sx={{
                  mb: 2,
                }}
                onBlur={formik.handleBlur}
                error={!!formik.errors.title}
                helperText={formik.errors.title}
              />

              <FormControl sx={{}} component="fieldset" variant="standard">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormLabel component="legend">без Shift</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkAllKeys"
                        checked={
                          formik.values.keys.length ===
                          layoutKeysWithoutShift.length
                        }
                        onChange={handleCheckAllKeysChange}
                        name="checkAllKeys"
                        size="small"
                        indeterminate={
                          formik.values.keys.length !== 0 &&
                          formik.values.keys.length !==
                            layoutKeysWithoutShift.length
                        }
                      />
                    }
                    label="Выбрать все"
                  />
                </Box>
                <FormGroup>
                  <Keyboard
                    physicalLayout={statsInfo.physicalLayout}
                    logicalLayout={statsInfo.logicalLayout}
                    checkedKeys={formik.values.keys}
                    handleChange={formik.handleChange}
                    keysStatistics={statsInfo?.keyStats}
                    name="keys"
                    shift={false}
                  />
                </FormGroup>
              </FormControl>

              <Box sx={{}} />
              <FormControl sx={{}} component="fieldset" variant="standard">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormLabel component="legend">с Shift</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="checkAllKeysWithShift"
                        checked={
                          formik.values.keysWithShift.length ===
                          layoutKeysWithShift.length
                        }
                        onChange={handleCheckAllKeysWithShiftChange}
                        name="checkAllKeysWithShift"
                        size="small"
                        indeterminate={
                          formik.values.keysWithShift.length !== 0 &&
                          formik.values.keysWithShift.length !==
                            layoutKeysWithShift.length
                        }
                      />
                    }
                    label="Выбрать все"
                  />
                </Box>

                <FormGroup>
                  <Keyboard
                    physicalLayout={statsInfo.physicalLayout}
                    logicalLayout={statsInfo.logicalLayout}
                    checkedKeys={formik.values.keysWithShift}
                    handleChange={formik.handleChange}
                    name="keysWithShift"
                    shift={true}
                    keysStatistics={statsInfo?.keyStats}
                  />
                </FormGroup>
                {formik.touched.keys &&
                  formik.touched.keysWithShift &&
                  formik.errors.minCharsNumber && (
                    <FormHelperText
                      error={
                        formik.touched.keys &&
                        formik.touched.keysWithShift &&
                        formik.errors.minCharsNumber
                      }
                    >
                      {formik.errors.minCharsNumber}
                    </FormHelperText>
                  )}
              </FormControl>
            </Box>
          </>
        )}
        {createdLessons.length !== 0 && (
          <>
            <Box>
              <Divider sx={{ mt: 2, mb: 0 }} />

              {/* <Typography component="p" align="left" variant="h6" sx={{}}>
                Добавленные уроки:
              </Typography> */}
              <LessonList
                lessons={createdLessons}
                type="custom"
                lessonsStatistics={null}
                loading={false}
                onDelete={deleteExercise}
                fingerZones={fingerZones}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 3,
          width: 'auto',
          margin: 'auto',
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          color="success"
          sx={{ minWidth: '100px' }}
          onClick={onSubmit}
        >
          Ок
        </Button>
        {isAuthenticated ? (
          <Button
            variant="contained"
            fullWidth
            color="info"
            onClick={formik.handleSubmit}
            sx={{ minWidth: '170px' }}
          >
            {createExerciseLoading ? '...' : 'Добавить урок'}
          </Button>
        ) : (
          <Button
            component={Link}
            to={ROUTES.SIGNUP}
            variant="contained"
            sx={{
              textAlign: 'center',
              p: 0.75,
              minWidth: '130px',
              height: '100%',
            }}
          >
            К регистрации
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default TestResultDialog;
