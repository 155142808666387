import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import { LayoutLanguage } from 'domains/keyboard/LayoutLangage';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends SelectProps<LayoutLanguage> {
  options: LayoutLanguage[];
}

const LayoutLanguageSelect: FC<Props> = ({ label, options, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="language-select-label">{label}</InputLabel>
        <Select
          labelId="language-select-label"
          id="language-select"
          label={label}
          size="small"
          {...props}
        >
          {options?.map((language) => (
            <MenuItem key={language} value={language}>
              {t(`languages.${language}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default LayoutLanguageSelect;
