import { RootState } from 'init/store';
import { createSelector } from 'reselect';

export const userSelector = (state: RootState) => state.user;

export const settingsSelector = createSelector(userSelector, (user) => {
  return user.settings;
});

export const logicalLayoutSelector = createSelector(
  settingsSelector,
  (settings) => {
    return settings?.logicalLayout;
  }
);

export const optionsSelector = createSelector(settingsSelector, (settings) => {
  return settings?.options;
});

export const physicalLayoutSelector = createSelector(
  settingsSelector,
  (settings) => {
    return settings?.physicalLayout;
  }
);

export const myLayoutsSelector = createSelector(
  settingsSelector,
  (settings) => {
    return [...settings?.myLayouts].sort();
  }
);
