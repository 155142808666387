import { apply, put } from 'redux-saga/effects';

import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import { startFetching, stopFetching } from 'domains/auth/slice';
import { enqueueSnackbar } from 'domains/notifier/slice';
import { clearUser } from 'domains/user/slice';
import api from 'REST/api';
import { logout as logoutSync } from '../../slice';

function* logout() {
  try {
    yield put(startFetching());

    // yield apply(api, api.auth.logout);

    const response = yield apply(api, api.auth.logout);

    if (response.status !== 204) {
      const { message } = yield apply(response, response.json);

      throw new Error(message);
    }
  } catch (error) {
    yield put(
      enqueueSnackbar(
        { message: error.message },
        {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          action: (key) => <NotifierCloseButton notifierKey={key} />,
        }
      )
    );
  } finally {
    yield put(clearUser());
    yield put(stopFetching());
    yield put(logoutSync());
    // yield put(replace(ROUTES.SIGN_IN));
  }
}

export default logout;
