import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Feature from 'components/Features/Feature';

function Features() {
  return (
    <Container maxWidth="lg">
      <Box
        component="section"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
        }}
      >
        {/* <Typography component="h2" variant="h4" sx={{ display: 'flex' }}>
          Основные особенности <span>✨</span>
        </Typography> */}
        <Box
          sx={{
            display: 'flex',
            alignIems: 'flex-start',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 3,
            '> div': {
              maxWidth: '300px',
            },
            p: 2,
          }}
        >
          <Feature
            icon="🅿"
            title="Специальные графические подсказки"
            description={`Виртуальная клавиатура со специальными подсказками задействуют визуально-пространственную память и делают обучение комфортным.`}
          />
          <Feature
            icon="🎲"
            title={'Игровые элементы'}
            description={
              'Уникальный алгоритм обучения и возможность отслеживать прогресс делает процесс обучения на тренажёре динамичным и увлекательным.'
            }
          />
          <Feature
            icon="⚔️"
            title="Обратная связь"
            description={
              'Ошибившись, Вам не нужно переводить взгляд на клавиатуру и судорожно искать нужную клавишу. Благодаря подсказкам Вы всегда знаете, куда Вы нажали, было ли нажатие ошибочным и как исправиться, а алгоритм поможет Вам в этом 🙂.'
            }
          />
          <Feature
            icon="🧶"
            title={'Оптимальная загрузка внимания'}
            description={`Чтобы избежать лишней когнитивной нагрузки во время обучения, упражнения составлены оптимальным образом.`}
          />
          <Feature
            icon="📊"
            title={'Полезное тестирование'}
            description={
              'По окончании тестирования можно не только узнать общий процент точности и скорость набора, но также результаты по каждой клавише.'
            }
          />
          <Feature
            icon="📋"
            title={'Возможность создавать свои упражнения'}
            description={`Если Вы хотите проработать некоторый свой набор символов, можно создать своё упражнение.`}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default Features;
