import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';

import PasswordForgetForm from 'components/Forms/PasswordForget';

interface Props {}

const PasswordForgetPage: FC<Props> = () => {
  return (
    <>
      <Helmet>
        <title>Сброс пароля - keycap.pro</title>
      </Helmet>
      <Container maxWidth="xs" sx={{ mt: 12 }}>
        <PasswordForgetForm />
      </Container>
    </>
  );
};

export default PasswordForgetPage;
