import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Statistic from 'components/GeneralStatistics/Statistic';
import humanizeDuration from 'humanize-duration';
import 'moment/locale/ru';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAccuracy, formatReaction } from 'utils/format';

interface Props extends BoxProps {
  stats: any;
  loading: boolean;
}

const GeneralStatistics: FC<Props> = ({ stats, loading, ...props }) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      sx={{
        '> div:nth-of-type(n):not(:last-child)': {
          mr: 2,
        },
        '> div:nth-of-type(2)': {
          ml: 2,
        },
        ...props.sx,
      }}
    >
      {loading ? (
        <>
          <Skeleton
            variant="rectangular"
            width={220}
            height={130}
            sx={{ borderRadius: 1 }}
          />
          <Divider orientation="vertical" flexItem variant="middle" />
          <Skeleton
            variant="rectangular"
            width={120}
            height={130}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={130}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={130}
            sx={{ borderRadius: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width={120}
            height={130}
            sx={{ borderRadius: 1 }}
          />
        </>
      ) : (
        <>
          <Statistic
            value={
              stats?.totalTime
                ? humanizeDuration(stats.totalTime, {
                    language: 'ru',
                    units: ['m', 's'],
                    delimiter: ' ',
                    round: true,
                  })
                : '-'
            }
            title={t('totalWorkingTime').toLowerCase()}
            backgroundColor="#f3f3fa"
          />

          <Divider orientation="vertical" flexItem variant="middle" />

          <Statistic
            value={
              stats?.averageReaction
                ? `${formatReaction(stats.averageReaction)} ${t('ms')}`
                : '-'
            }
            title={t('averageReaction').toLowerCase()}
          />

          <Statistic
            value={
              stats?.accuracy ? `${formatAccuracy(stats.accuracy) ?? 0}%` : '-'
            }
            title={t('accuracy').toLowerCase()}
          />

          <Statistic
            value={stats?.numCorrectKeystrokes}
            title={t('numCorrectKeystrokes').toLowerCase()}
            color="green"
          />

          <Statistic
            value={`${stats?.numWrongKeystrokes} (${stats?.numMissedKeystrokes})`}
            title={t('numWrongMissedKeystrokes').toLowerCase()}
            color="#A20E10"
          />
        </>
      )}
    </Box>
  );
};

export default GeneralStatistics;
