import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import getStatsFromKeysStats from 'components/LessonResultDialog/getStatsFromKeysStats';
import { LessonStats } from 'domains/lessons/models/LessonStats';
import humanizeDuration from 'humanize-duration';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { formatAccuracy, formatReaction } from 'utils/format';

interface Props {
  statsInfo: LessonStats;
}

const Statistics: FC<Props> = ({ statsInfo }) => {
  const { t } = useTranslation();

  const {
    numCorrectKeystrokes,
    numWrongKeystrokes,
    numMissedKeystrokes,
    accuracy,
    averageReaction,
  } = getStatsFromKeysStats(statsInfo.keyStats);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          gap: 3,
          width: '500px',
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Typography>Общее время:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{`${humanizeDuration(statsInfo.totalTime, {
              language: 'ru',
              units: ['m', 's'],
              delimiter: ' ',
              round: true,
            })}`}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>Точность:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{`${formatAccuracy(accuracy)}%`}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>Правильных нажатий:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{numCorrectKeystrokes}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>Ошибок(пропусков):</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {numWrongKeystrokes}
              {`(${numMissedKeystrokes})`}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography>Среднее время нажатия:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {isNaN(averageReaction)
                ? '-'
                : `${formatReaction(averageReaction)} ${t('ms')}`}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Statistics;
