export function formatAccuracy(accuracy: number): number {
  return !isNaN(accuracy) ? Math.round(accuracy * 10000) / 100 : 0;
}

export function formatAccuracyToInt(accuracy: number): number {
  return !isNaN(accuracy) ? Math.round(accuracy * 100) : 0;
}

export function formatReaction(reaction: number): number {
  return !isNaN(reaction) ? Math.round(reaction) : 0;
}
