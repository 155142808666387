import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import getStatsFromKeysStats from 'components/LessonResultDialog/getStatsFromKeysStats';
import { LessonStats } from 'domains/lessons/models/LessonStats';
import humanizeDuration from 'humanize-duration';
import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { formatAccuracy, formatReaction } from 'utils/format';

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}

const StyledDialogTitle: FC<DialogTitleProps> = ({
  children,
  onClose,
  ...other
}) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  onSubmit: () => void;
  onClose: () => void;
  statsInfo: LessonStats;
}

const LessonResultDialog: FC<Props> = ({ onClose, onSubmit, statsInfo }) => {
  const { t } = useTranslation();

  const {
    numCorrectKeystrokes,
    numWrongKeystrokes,
    numMissedKeystrokes,
    accuracy,
    averageReaction,
  } = getStatsFromKeysStats(statsInfo.keyStats);
  return (
    <>
      <StyledDialogTitle id="keyboard-setup-dialog-title" onClose={onClose}>
        Статистика
      </StyledDialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: 3,
            width: '100%',
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <Typography>Общее время:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`${humanizeDuration(statsInfo.totalTime, {
                language: 'ru',
                units: ['m', 's'],
                delimiter: ' ',
                round: true,
              })}`}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Точность:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`${formatAccuracy(accuracy)}%`}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Правильных нажатий:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{numCorrectKeystrokes}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Ошибок(пропусков):</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {numWrongKeystrokes}
                {`(${numMissedKeystrokes})`}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography>Среднее время нажатия:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {isNaN(averageReaction)
                  ? '-'
                  : `${formatReaction(averageReaction)} ${t('ms')}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', width: '40%', margin: 'auto' }}>
        <Button
          autoFocus
          variant="contained"
          fullWidth
          color="success"
          onClick={onSubmit}
        >
          Ок
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonResultDialog;
