import NotifierCloseButton from 'components/Notifier/NotifierCloseButton';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import { Lesson } from 'domains/lessons/models/Lesson';
import { LessonProps } from 'domains/lessons/models/LessonProps';
import { enqueueSnackbar } from 'domains/notifier/slice';
import { logicalLayoutSelector } from 'domains/user/selectors/settings';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import api from 'REST/api';

const useExercises = (
  createCb?: (lesson?: Lesson) => void,
  deleteExerciseCb?: (lessonId: string) => void
): {
  lessons: Lesson[];
  isLessonsLoading: boolean;
  deleteExercise: (id: string) => void;
  deleteExerciseLoading: boolean;
  createExerciseMutation: (lesson: LessonProps) => void;
  createExerciseLoading: boolean;
} => {
  const dispatch = useAppDispatch();

  const logicalLayout: LogicalLayout | null = useAppSelector(
    logicalLayoutSelector
  );

  const [lessons, setLessons] = useState<Lesson[]>([]);

  const {
    isLoading: isLessonsLoading,
    isError,
    error,
    data,
  } = useQuery(
    ['lessons', logicalLayout],
    (params) => {
      const [_key, logicalLayout] = params.queryKey;
      return api.lessons.fetch(logicalLayout);
    },
    {
      onSuccess: (data) => {
        setLessons(data);
      },
      onError: (error) => {
        enqueueSnackbar(
          { message: error.message },
          {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            action: (key) => <NotifierCloseButton notifierKey={key} />,
          }
        );
      },
      enabled: logicalLayout !== null,
    }
  );

  const { mutate: createExerciseMutation, isLoading: createExerciseLoading } =
    useMutation(api.lesson.createExercise, {
      onSuccess: (data) => {
        // handleCreateExClose();
        createCb && createCb(data);
        // Инвалидация и обновление
        setLessons((prev) => [data, ...prev]);
        // console.log('LESSON CREATED SUCCESSFULL');
      },
      onError: (error) => {
        dispatch(
          enqueueSnackbar(
            { message: error.message },
            {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              action: (key) => <NotifierCloseButton notifierKey={key} />,
            }
          )
        );
      },
    });

  const { mutate: deleteExercise, isLoading: deleteExerciseLoading } =
    useMutation(api.lesson.deleteExercise, {
      onSuccess: (data, variables) => {
        setLessons((prev) => prev.filter((lesson) => lesson._id !== variables));
        deleteExerciseCb && deleteExerciseCb(variables);
      },
      onError: (error) => {
        dispatch(
          enqueueSnackbar(
            { message: error.message },
            {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              action: (key) => <NotifierCloseButton notifierKey={key} />,
            }
          )
        );
      },
    });

  return {
    lessons,
    createExerciseMutation,
    createExerciseLoading,
    isLessonsLoading,
    deleteExercise,
    deleteExerciseLoading,
  };
};

export default useExercises;
