import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Header from 'components/Header';
import * as ROUTES from './routes';

// Pages
import HomePage from 'pages/Home';
import PasswordForgetPage from 'pages/PasswordForget';
import PasswordResetPage from 'pages/PasswordReset';
import SignInPage from 'pages/SignIn';
import SignUpPage from 'pages/SignUp';
// import TestPage from 'pages/Test';

interface Props {}

const Public: FC<Props> = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.SIGNUP} component={SignUpPage} />
        <Route path={ROUTES.LOGIN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
        {/* <Route exact path={ROUTES.TEST} component={TestPage} /> */}
        <Redirect to={ROUTES.LOGIN} />
      </Switch>
    </>
  );
};

export default Public;
