import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from 'components/Header';
import * as ROUTES from './routes';

// Pages
import AccountPage from 'pages/Account';
import HomePage from 'pages/Home';
import LessonPage from 'pages/Lesson';
import LessonsPage from 'pages/Lessons';
import TestPage from 'pages/Test';

interface Props {}

const Private: FC<Props> = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.TEST} component={TestPage} />
        <Route exact path={ROUTES.LESSONS} component={LessonsPage} />
        <Route path={ROUTES.LESSON} component={LessonPage} />
        <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
        <Redirect to={ROUTES.HOME} />
      </Switch>
    </>
  );
};

export default Private;
