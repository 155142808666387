import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import { PhysicalLayout } from 'domains/keyboard/PhysicalLayout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends RadioGroupProps {
  options: PhysicalLayout[];
}

const PhysicalLayoutRadio: FC<Props> = ({ value, name, options, onChange }) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      {/* <FormLabel id="physicalLayout" sx={{ mr: 2, width: 'max-content' }}>
        {t('labels.keyboardStandard')}
      </FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="physicalLayout"
        name={name}
        value={value}
        onChange={onChange}
        sx={{ flexWrap: 'nowrap' }}
      >
        {options.map((physicalLayout: PhysicalLayout) => (
          <FormControlLabel
            key={physicalLayout}
            value={physicalLayout}
            control={<Radio />}
            label={t(`physicalLayouts.${physicalLayout}`)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default PhysicalLayoutRadio;
