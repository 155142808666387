import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import Catcher from 'components/Catcher';
import Features from 'components/Features';
import Demo from 'components/Demo';
import Footer from 'components/Footer';
import Logo from 'components/icons/Logo';

interface Props {}

const HomePage: FC<Props> = () => {
  return (
    <Catcher>
      <Helmet>
        <title>keycap.pro</title>
      </Helmet>
      <Container maxWidth="xl">
        <Demo />
        <Box
          sx={{
            display: 'flex',
            margin: '0 auto',
            width: 96,
            height: 4,
            backgroundColor: '#7C7C7C',
            borderRadius: 4,
            mb: 4,
          }}
        />
        <Features />
      </Container>
      <Footer
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mr: '41px',
            }}
          >
            <Logo fontSize="large" sx={{ fontSize: '2.7rem', mb: '2px' }} />{' '}
            Keycap.pro
          </Box>
        }
        description={
          <>
            📧 e-mail:{' '}
            <Link href="mailto:feedback@keycap.pro" color="inherit">
              feedback@keycap.pro
            </Link>
          </>
        }
      />
    </Catcher>
  );
};

export default HomePage;
