import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import LessonItem from 'components/LessonList/LessonItem';
import { Lesson, LessonType } from 'domains/lessons/models/Lesson';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
  lessons?: Lesson[];
  loading: boolean;
  lessonsStatistics: any;
  type: LessonType;
  onDelete?: (id: string) => void;
  fingerZones: any;
}

const LessonList: FC<Props> = ({
  lessons,
  loading,
  type,
  lessonsStatistics,
  onDelete,
  fingerZones,
}) => {
  const { t } = useTranslation();

  return (
    <Box component="section" sx={{ width: '100%', pt: 3 }}>
      {loading ? (
        <>
          <Grid container spacing={2} justifyContent="space-between">
            {Array(12)
              .fill(undefined)
              .map((_item, index) => (
                <Grid item xs={6} key={index}>
                  <Skeleton
                    variant="rectangular"
                    height="176px"
                    sx={{ borderRadius: 1 }}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <Grid container spacing={2} justifyContent="space-between">
          {lessons?.map((lesson, index) => {
            return (
              <LessonItem
                key={lesson._id}
                id={lesson._id}
                title={
                  type !== 'custom'
                    ? t(`lessonTitles.${lesson.title}`)
                    : lesson.title
                }
                type={lesson.type}
                keys={lesson.chars}
                fingers={
                  new Set(lesson.chars.map((key) => fingerZones[key.code]))
                }
                number={index + 1}
                stats={lessonsStatistics?.find(
                  (stats) => stats.lessonId === lesson._id
                )}
                onDelete={onDelete}
              />
            );
          })}
        </Grid>
      )}

      {/* </List> */}
    </Box>
  );
};

export default LessonList;
