const sys = {
  fontSize: '1.2vw',
  alignItems: 'flex-end',
  backgroundColor: '#e3e3e1',
  textTransform: 'lowercase',
  justifyContent: 'start',
};

const isoKeyStyles = {
  key: {
    aspectRatio: '40/40',
    flexDirection: 'column',
  },
  backquote: {
    aspectRatio: '40/40',
    flexDirection: 'column',
  },
  backspace: {
    aspectRatio: '83/40',
    ...sys,

    justifyContent: 'end',
  },
  tab: {
    aspectRatio: '63/40',
    ...sys,
  },
  backslash: {
    flexDirection: 'column',
  },
  capsLock: {
    aspectRatio: '70/40',
    ...sys,
  },
  enter: {
    aspectRatio: '60/40',
    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '88%',
      height: '120%',
      width: '88.35%',
      flex: 'auto',
      aspectRatio: '53/40',
      background: 'inherit',
      borderTop: 'initial',
      boxShadow: 'inherit',
      textShadow: 'inherit',
    },

    ...sys,

    justifyContent: 'end',
  },
  shiftLeft: {
    aspectRatio: '51/40',
    // aspectRatio: '50/40',
    ...sys,
  },
  shiftRight: {
    aspectRatio: '115/40',
    ...sys,

    justifyContent: 'end',
  },
  controlLeft: {
    aspectRatio: '63/40',
    ...sys,
  },
  metaLeft: {
    display: 'none',
    ...sys,
  },
  altLeft: {
    aspectRatio: '63/40',
    ...sys,
  },
  space: {
    // aspectRatio: '369/40',
    // aspectRatio: '379/40',
    flex: 'auto',
  },
  altRight: {
    aspectRatio: '63/40',
    ...sys,

    justifyContent: 'end',
  },
  metaRight: {
    display: 'none',
    ...sys,

    justifyContent: 'end',
  },
  contextMenu: {
    display: 'none',
    ...sys,

    justifyContent: 'end',
  },
  controlRight: {
    aspectRatio: '63/40',
    ...sys,

    justifyContent: 'end',
  },
};

export default isoKeyStyles;
