import Box from '@mui/material/Box';
import Rect from 'components/SVGKeyboard/Rect';
import Text from 'components/SVGKeyboard/Text';
import { memo } from 'react';
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';

interface KeyProps {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  upperGlyph: string;
  lowerGlyph: string;
  homing?: boolean;
  type: Pick<KeyDefinition, 'type'>;
  fill?: string;
  fontColor?: string;
}

function Key({
  id,
  x,
  y,
  width,
  height,
  fill = '#efefee',
  upperGlyph,
  lowerGlyph,
  type,
  homing = false,
  fontColor = '#22211f',
}: KeyProps): JSX.Element {
  return (
    <Box id={id} component="svg" x={x} y={y}>
      <Rect x={0} y={0} width={width} height={height} fill={fill} />
      {type === 'letter' && (
        <>
          <Text
            // x="50%"
            // y="50%"
            x={width / 2}
            y={height / 2}
            dominantBaseline="middle"
            textAnchor="middle"
            fill={fontColor}
            textRendering="optimizeLegibility"
            sx={{ fontSize: '11px', cursor: 'default', userSelect: 'none' }}
          >
            {upperGlyph}
          </Text>
        </>
      )}
      {type === 'sys' && (
        <>
          <Text
            // x="50%"
            // y="50%"
            x={12}
            y={height / 2 + 2}
            dominantBaseline="middle"
            // textAnchor="middle"
            fill={fontColor}
            textRendering="optimizeLegibility"
            sx={{ fontSize: '10px', cursor: 'default', userSelect: 'none' }}
          >
            {lowerGlyph}
          </Text>
        </>
      )}
      (
      {(type === 'symbol' || type === 'digit') && (
        <>
          <Text
            x={10}
            y={15}
            fill={fontColor}
            textRendering="optimizeLegibility"
            sx={{ fontSize: '11px', cursor: 'default', userSelect: 'none' }}
          >
            {upperGlyph}
          </Text>
          <Text
            x={10}
            y={30}
            fill={fontColor}
            textRendering="optimizeLegibility"
            sx={{ fontSize: '11px', cursor: 'default', userSelect: 'none' }}
          >
            {lowerGlyph}
          </Text>
        </>
      )}
      {homing && (
        <Rect
          //   rx={2}
          ry={2}
          x={15}
          y={35}
          width={10}
          height={2.5}
          fill={'#cccccc'}
        />
      )}
    </Box>
  );
}

export default memo(Key);
