import { KeyDefinition } from 'domains/keyboard/KeyDefinition';
import { LogicalLayout } from 'domains/keyboard/LogicalLayout';
import getKeyByCode from 'domains/keyboard/logicalLayouts/getKeyByCode';
import { TestEvent } from 'domains/test/TestEvent';
import useInterval from 'hooks/useInterval';
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import normalisePogressValue from 'utils/normalisePogressValue';

export type TestOptions = {
  taktTime: number;
  delayTime: number;
  emulated: boolean;
};

const useTest = (
  testKeys: KeyDefinition[],
  logicalLayout: LogicalLayout | null,
  option: TestOptions = {
    taktTime: 2000,
    delayTime: 200,
    emulated: true,
  }
): {
  logs: TestEvent[];
  isStarted: boolean;
  isPaused: boolean;
  isFocused: boolean;
  isFinished: boolean;
  testedKey: KeyDefinition | null;
  progress: number;
  reset: () => void;
  handleKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  handleFocus: () => void;
  handleBlur: () => void;
} => {
  const [taktTime] = useState(option.taktTime);
  const [delayTime] = useState(option.delayTime);
  const [remainingKeysForTest, setRemainingKeysForTest] = useState(
    testKeys ?? []
  );
  const remainingKeysForTestRef = useRef(remainingKeysForTest);

  const [reactionTimeStart, setReactionTimeStart] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [testedKey, setTestedKey] = useState<KeyDefinition | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [logs, setLogs] = useState<TestEvent[]>([]);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const taktTimeRef = useRef<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  // убрать reaction где не надо в логах
  // если изменился test set
  useEffect(() => {
    // console.log('test set changed');

    setRemainingKeysForTest(testKeys);
    remainingKeysForTestRef.current = testKeys;
  }, [testKeys]);

  const handleNext = () => {
    setProgress(
      normalisePogressValue(
        testKeys.length - remainingKeysForTest.length + 1,
        testKeys.length
      )
    );
    setRemainingKeysForTest((prevState) =>
      prevState.filter((key) =>
        key.code !== testedKey?.code
          ? true
          : key.shiftKey !== testedKey?.shiftKey
      )
    );
    setTestedKey(null);
    remainingKeysForTestRef.current = remainingKeysForTest.filter((key) =>
      key.code !== testedKey?.code ? true : key.shiftKey !== testedKey?.shiftKey
    );

    if (remainingKeysForTestRef.current.length) {
      timerRef.current = setTimeout(() => next(), delayTime);
    } else {
      stop();
    }
  };

  useInterval(() => {
    if (!taktTimeRef.current || isPaused || !isStarted) {
      return;
    }

    const time = Number(new Date());

    if (testedKey) {
      log({
        type: 'missedKeystroke',
        reaction: taktTime,
        target: testedKey,
        timecode: time - startTime,
        pressedKey: undefined,
      });

      handleNext();
    }
  }, taktTimeRef.current);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [timerRef]);

  const start = () => {
    // console.log('START');
    // установить следующую букву
    setIsStarted(true);
    setStartTime(Number(new Date()));
    // запустить секундомер
    setIsPaused(false);
    // startStopwatch();
    log({
      type: 'start',
      reaction: undefined,
      target: undefined,
      timecode: 0,
      pressedKey: undefined,
    });
    next();
  };

  /**
   * установка testedKey в null, и таймер200, чтобы показать следующую
   */
  const nextWithDelay = () => {
    setTestedKey(null);

    timerRef.current = setTimeout(() => next(), delayTime);
  };

  /**
   * установка следующей буквы и ее показ
   */
  const next = () => {
    taktTimeRef.current = taktTime;

    // установить следующую букву
    setNextTestedKey();
    // начало реакции
    setReactionTimeStart(Number(new Date()));
  };

  const setNextTestedKey = useCallback(() => {
    const random = Math.floor(
      Math.random() * remainingKeysForTestRef.current.length
    );

    setTestedKey(remainingKeysForTestRef.current[random]);
  }, [remainingKeysForTest, testedKey]);

  const pause = () => {
    // console.log('PAUSE');
    setIsPaused(true);
    // pauseStopwatch();
    setTestedKey(null);
    taktTimeRef.current = null;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    log({
      type: 'pause',
      reaction: undefined,
      target: undefined,
      timecode: Number(new Date()) - startTime,
      pressedKey: undefined,
    });
  };

  const stop = () => {
    // console.log('STOP');

    // pauseStopwatch();
    taktTimeRef.current = null;
    setTestedKey(null);

    log({
      type: 'finish',
      reaction: undefined,
      target: undefined,
      timecode: Number(new Date()) - startTime,
      pressedKey: undefined,
    });
    setIsFinished(true);
  };

  const reset = () => {
    // console.log('RESET');
    // resetStopwatch();
    setIsPaused(false);
    setIsStarted(false);
    setRemainingKeysForTest(testKeys);
    remainingKeysForTestRef.current = testKeys;
    setProgress(0);
    setTestedKey(null);
    taktTimeRef.current = null;
    setIsFinished(false);
    setLogs([]);
    // обнулять таймер задержки ?
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const canBeTestStarted = isFocused;
    if (!canBeTestStarted) {
      return;
    }

    const { key, shiftKey, code } = e;
    // напоминание: есть свойство repeat, если нужно

    // console.log({ key, shiftKey, code }, e);

    if (!isStarted && code === 'Space' && !shiftKey) {
      // --> start
      start();
      return;
    } else if (isStarted && code === 'Space' && shiftKey) {
      // start --> pause
      pause();
      return;
    } else if (isStarted && isPaused && code === 'Space') {
      // pause --> continue
      // startStopwatch();
      setIsPaused(false);
      next();
      log({
        type: 'continue',
        reaction: undefined,
        target: undefined,
        timecode: Number(new Date()) - startTime,
        pressedKey: undefined,
      });
      return;
    }

    if (testedKey && /^.$/u.test(key)) {
      taktTimeRef.current = null;
      const time = Number(new Date());
      const reaction = time - reactionTimeStart;

      log({
        type:
          (option.emulated &&
            (code === testedKey.code ||
              (testedKey.alternate && code === testedKey.alternate)) &&
            shiftKey === testedKey.shiftKey) ||
          (!option.emulated && key === testedKey.key)
            ? 'correctKeystroke'
            : 'wrongKeystroke',
        reaction,
        target: testedKey,
        timecode: time - startTime,
        pressedKey: {
          code,
          shiftKey,
          key: option.emulated
            ? getKeyByCode(code, logicalLayout, shiftKey) ?? key
            : key,
        },
      });

      handleNext();
    } else if (!isPaused && !testedKey && /^.$/u.test(key)) {
      log({
        type: 'blot',
        reaction: undefined,
        target: undefined,
        timecode: Number(new Date()) - startTime,
        pressedKey: {
          code,
          shiftKey,
          key: option.emulated
            ? getKeyByCode(code, logicalLayout, shiftKey) ?? key
            : key,
        },
      });
    }
  };

  const handleFocus = () => {
    // console.log('FOCUS');
    setIsFocused(true);
  };

  const handleBlur = () => {
    // console.log('blur');
    if (isStarted && !isPaused) {
      pause();
    }
    setIsFocused(false);
  };

  const log = (event: TestEvent) => {
    setLogs((prevState) => [...prevState, event]);
    // console.log(event);
  };

  return {
    logs,
    isStarted,
    isPaused,
    isFocused,
    testedKey,
    progress,
    reset,
    isFinished,
    handleKeyPress,
    handleFocus,
    handleBlur,
  };
};

export default useTest;
