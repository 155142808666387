import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Helper from 'components/Helper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  numControlChars: number | 'byCharsNum';
  entryLevel: number;
  delayTime: number;
  taktTime: number;
  expanded: boolean;
  disabled: boolean;
  sizeCursor: number;
  onReset: () => void;
  onTutorSizeChange: () => void;
  onSettingsExpandClick: () => void;
  onTaktTimeChange: (event: SelectChangeEvent<number>) => void;
  onDelayTimeChange: (event: SelectChangeEvent<number>) => void;
  onEntryLevelChange: (event: SelectChangeEvent<number>) => void;
  onNumControlCharsChange: (
    event: SelectChangeEvent<number | 'byCharsNum'>
  ) => void;
  onIntroDialogOpen?: () => void;
  onResetOptions: () => void;
}

export type OptionsType = {
  entryLevel: number;
  taktTime: number;
  delayTime: number;
  numControlChars: number | 'byCharsNum';
};

const Settings: FC<Props> = ({
  entryLevel,
  delayTime,
  sizeCursor,
  taktTime,
  numControlChars,
  expanded,
  disabled,
  onReset,
  onTutorSizeChange,
  onSettingsExpandClick,
  onTaktTimeChange,
  onDelayTimeChange,
  onIntroDialogOpen,
  onNumControlCharsChange,
  onEntryLevelChange,
  onResetOptions,
}) => {
  const { t } = useTranslation();

  return (
    <Paper variant="outlined" sx={{ mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
        }}
      >
        <Box>
          <IconButton
            // sx={{ transform: 'scale(-1, 1)' }}
            aria-label="reset "
            size="medium"
            title="Сбросить прогресс"
            onClick={onReset}
          >
            <RefreshIcon fontSize="medium" />
          </IconButton>

          <IconButton
            size="medium"
            title="Изменить размер клавиатуры"
            onClick={onTutorSizeChange}
          >
            {sizeCursor === 0 ? (
              <FullscreenIcon fontSize="medium" />
            ) : (
              <FullscreenExitIcon fontSize="medium" />
            )}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            // cursor: 'pointer',
          }}
        >
          {onIntroDialogOpen && (
            <Button
              onClick={onIntroDialogOpen}
              // variant="text"
              disableElevation
              sx={{
                textDecoration: 'none',
                textTransform: 'none',
                mr: 1,
                color: 'secondary',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                ':hover': {
                  border: '1px solid #048CD4',
                },
              }}
              title="Правила"
              variant="text"
            >
              📃
            </Button>
          )}
          <Button
            onClick={onSettingsExpandClick}
            variant="text"
            sx={{
              textDecoration: 'none',
              textTransform: 'none',
              mr: 1,
              color: 'text.secondary',
            }}
            endIcon={expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          >
            Настройки
          </Button>
        </Box>
      </Box>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        // sx={{ pr: 2, pl: 2 }}
      >
        <Divider sx={{ mb: 1 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            // justifyContent: 'flex-start',
            mb: 1,
            pr: 1,
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              component="span"
              variant="body1"
              sx={{ display: 'flex', flex: 1 }}
              gutterBottom
            >
              Время задержки, мс
            </Typography>
            <Select
              size="small"
              id="delayTime"
              name="delayTime"
              value={delayTime}
              // label="Age"
              disabled={disabled}
              onChange={onDelayTimeChange}
            >
              {Array.from(Array(10), (_, i) => i * 50 + 100).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Helper
            text={
              'Время задержки - период времени, в течении которого новый символ не появляется после нажатия'
            }
          />
        </Box>
        <Divider light flexItem sx={{ mt: 1, mb: 1 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            // justifyContent: 'flex-start',
            mb: 1,
            pr: 1,
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              component="span"
              variant="body1"
              gutterBottom
              sx={{ display: 'flex', flex: 1 }}
            >
              Время отклика, мс
            </Typography>
            <Select
              size="small"
              id="taktTime"
              name="taktTime"
              value={taktTime}
              // label="Age"
              disabled={disabled}
              onChange={onTaktTimeChange}
              MenuProps={{
                sx: { '& .MuiPaper-root': { maxHeight: '400px' } },
              }}
            >
              {Array.from(Array(20), (_, i) => i * 100 + 500).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Helper
            text={
              'Время отклика - период времени, в течение которого нажатие будет засчитано, иначе - пропуск'
            }
          />
        </Box>
        <Divider light flexItem sx={{ mt: 1, mb: 1 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            // justifyContent: 'flex-start',
            mb: 1,
            pr: 1,
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography
              component="span"
              variant="body1"
              gutterBottom
              sx={{ display: 'flex', flex: 1 }}
            >
              Длина красного прогресс бара
            </Typography>
            <Select
              size="small"
              id="numControlChars"
              name="numControlChars"
              value={numControlChars}
              // label="Age"
              disabled={disabled}
              onChange={onNumControlCharsChange}
              sx={{ width: '350px' }}
              MenuProps={{
                sx: {
                  '& .MuiPaper-root': { maxHeight: '400px' },
                },
              }}
            >
              <MenuItem key="byCharsNum" value={'byCharsNum'}>
                По количеству символов в упражнении
              </MenuItem>
              {Array.from(Array(6), (_, i) => i + 5).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
              {Array.from(Array(5), (_, i) => i * 2 + 12).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
              {Array.from(Array(6), (_, i) => i * 5 + 25).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
              {Array.from(Array(2), (_, i) => i * 25 + 75).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Helper
            text={
              'Длина красного прогресс бара - число контрольных символов по окончании упражнения'
            }
          />
        </Box>
        <Divider light flexItem sx={{ mt: 1, mb: 1 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            // justifyContent: 'flex-start',
            mb: 1,
            pr: 1,
            pl: 2,
          }}
        >
          <FormControl disabled={disabled}>
            <Typography
              component="span"
              variant="body1"
              gutterBottom
              sx={{ display: 'flex', flex: 1 }}
            >
              Стартовая позиция синего прогресс бара
            </Typography>
            {/* <FormLabel id="entryLevel">
              Стартовая позиция синего прогресс бара
            </FormLabel> */}
            <RadioGroup
              row
              aria-labelledby="entryLevel"
              name="entryLevel"
              id="entryLevel"
              value={entryLevel}
              onChange={onEntryLevelChange}
            >
              <FormControlLabel value="11" control={<Radio />} label="1" />
              <FormControlLabel value="10" control={<Radio />} label="2" />
              <FormControlLabel value="9" control={<Radio />} label="3" />
              <FormControlLabel value="8" control={<Radio />} label="4" />
              <FormControlLabel value="7" control={<Radio />} label="5" />
              <FormControlLabel value="6" control={<Radio />} label="6" />
              <FormControlLabel value="5" control={<Radio />} label="7" />
              <FormControlLabel value="4" control={<Radio />} label="8" />
              <FormControlLabel value="3" control={<Radio />} label="9" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider light flexItem sx={{ mt: 1, mb: 1 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            // justifyContent: 'flex-start',
            mb: 1,
            pr: 1,
            pl: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={onResetOptions}
            disabled={disabled}
          >
            Сбросить настройки
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default Settings;
