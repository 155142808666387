import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMutation } from 'react-query';
import api from 'REST/api';

import Alert from '@mui/material/Alert';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, object, string } from 'yup';

interface Props {}

const PasswordChangeForm: FC<Props> = () => {
  const { t } = useTranslation();

  const validationSchema = object().shape({
    oldPassword: string().required('Обязательное поле'),
    newPassword: string()
      .min(6, 'Пароль должен содержать не менее 6-ти символов')
      .required('Обязательное поле'),
    showOldPassword: boolean(),
    showNewPassword: boolean(),
  });

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    showNewPassword: false,
    showOldPassword: false,
  };

  const formik = useFormik({
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      mutate({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
    },
    initialValues,
    validationSchema,
  });

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    api.auth.changePassword
  );

  return (
    <>
      <Typography
        variant="body2"
        component="h6"
        // align="center"
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      >
        {t('changePassword')}
      </Typography>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1, width: '30%', margin: 'auto' }}
      >
        <TextField
          label={t('oldPassword')}
          id="oldPassword"
          name="oldPassword"
          fullWidth
          margin="normal"
          size="small"
          type={formik.values.showOldPassword ? 'text' : 'password'}
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          error={formik.touched.oldPassword && !!formik.errors.oldPassword}
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
        />

        <TextField
          label={t('newPassword')}
          id="newPassword"
          name="newPassword"
          fullWidth
          margin="normal"
          size="small"
          type={formik.values.showNewPassword ? 'text' : 'password'}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={formik.touched.newPassword && !!formik.errors.newPassword}
          helperText={formik.errors.newPassword}
          sx={{ mb: 2 }}
        />
        {error && isError && !isSuccess && !isLoading && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error?.message}
          </Alert>
        )}
        {isSuccess && !isLoading && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Пароль успешно изменён
          </Alert>
        )}
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('savePassword')}
        </LoadingButton>
      </Box>
    </>
  );
};

export default PasswordChangeForm;
