/**
 * https://w3c.github.io/uievents-code/#code-value-tables
 */
import { KeyDefinition } from 'domains/keyboard/KeyDefinition';

export const jcuken: KeyDefinition[] = [
  { code: 'Backquote', shiftKey: false, key: 'ё', type: 'letter' },
  { code: 'Backquote', shiftKey: true, key: 'Ё', type: 'letter' },
  { code: 'Digit1', shiftKey: false, key: '1', type: 'digit' },
  { code: 'Digit1', shiftKey: true, key: '!', type: 'symbol' },
  { code: 'Digit2', shiftKey: false, key: '2', type: 'digit' },
  { code: 'Digit2', shiftKey: true, key: '"', type: 'symbol' },
  { code: 'Digit3', shiftKey: false, key: '3', type: 'digit' },
  { code: 'Digit3', shiftKey: true, key: '№', type: 'symbol' },
  { code: 'Digit4', shiftKey: false, key: '4', type: 'digit' },
  { code: 'Digit4', shiftKey: true, key: ';', type: 'symbol' },
  { code: 'Digit5', shiftKey: false, key: '5', type: 'digit' },
  { code: 'Digit5', shiftKey: true, key: '%', type: 'symbol' },
  { code: 'Digit6', shiftKey: false, key: '6', type: 'digit' },
  { code: 'Digit6', shiftKey: true, key: ':', type: 'symbol' },
  { code: 'Digit7', shiftKey: false, key: '7', type: 'digit' },
  { code: 'Digit7', shiftKey: true, key: '?', type: 'symbol' },
  { code: 'Digit8', shiftKey: false, key: '8', type: 'digit' },
  { code: 'Digit8', shiftKey: true, key: '*', type: 'symbol' },
  { code: 'Digit9', shiftKey: false, key: '9', type: 'digit' },
  { code: 'Digit9', shiftKey: true, key: '(', type: 'symbol' },
  { code: 'Digit0', shiftKey: false, key: '0', type: 'digit' },
  { code: 'Digit0', shiftKey: true, key: ')', type: 'symbol' },
  { code: 'Minus', shiftKey: false, key: '-', type: 'symbol' },
  { code: 'Minus', shiftKey: true, key: '_', type: 'symbol' },
  { code: 'Equal', shiftKey: false, key: '=', type: 'symbol' },
  { code: 'Equal', shiftKey: true, key: '+', type: 'symbol' },
  { code: 'Backspace', key: 'Backspace', type: 'sys' },
  { code: 'Tab', key: 'Tab', type: 'sys' },
  { code: 'KeyQ', shiftKey: false, key: 'й', type: 'letter' },
  { code: 'KeyQ', shiftKey: true, key: 'Й', type: 'letter' },
  { code: 'KeyW', shiftKey: false, key: 'ц', type: 'letter' },
  { code: 'KeyW', shiftKey: true, key: 'Ц', type: 'letter' },
  { code: 'KeyE', shiftKey: false, key: 'у', type: 'letter' },
  { code: 'KeyE', shiftKey: true, key: 'У', type: 'letter' },
  { code: 'KeyR', shiftKey: false, key: 'к', type: 'letter' },
  { code: 'KeyR', shiftKey: true, key: 'К', type: 'letter' },
  { code: 'KeyT', shiftKey: false, key: 'е', type: 'letter' },
  { code: 'KeyT', shiftKey: true, key: 'Е', type: 'letter' },
  { code: 'KeyY', shiftKey: false, key: 'н', type: 'letter' },
  { code: 'KeyY', shiftKey: true, key: 'Н', type: 'letter' },
  { code: 'KeyU', shiftKey: false, key: 'г', type: 'letter' },
  { code: 'KeyU', shiftKey: true, key: 'Г', type: 'letter' },
  { code: 'KeyI', shiftKey: false, key: 'ш', type: 'letter' },
  { code: 'KeyI', shiftKey: true, key: 'Ш', type: 'letter' },
  { code: 'KeyO', shiftKey: false, key: 'щ', type: 'letter' },
  { code: 'KeyO', shiftKey: true, key: 'Щ', type: 'letter' },
  { code: 'KeyP', shiftKey: false, key: 'з', type: 'letter' },
  { code: 'KeyP', shiftKey: true, key: 'З', type: 'letter' },
  { code: 'BracketLeft', shiftKey: false, key: 'х', type: 'letter' },
  { code: 'BracketLeft', shiftKey: true, key: 'Х', type: 'letter' },
  { code: 'BracketRight', shiftKey: false, key: 'ъ', type: 'letter' },
  { code: 'BracketRight', shiftKey: true, key: 'Ъ', type: 'letter' },
  {
    code: 'Backslash',
    shiftKey: false,
    key: '\\',
    type: 'symbol',
    alternate: 'IntlBackslash',
  },
  {
    code: 'Backslash',
    shiftKey: true,
    key: '/',
    type: 'symbol',
    alternate: 'IntlBackslash',
  },
  { code: 'CapsLock', key: 'CapsLock', type: 'sys' },
  { code: 'KeyA', shiftKey: false, key: 'ф', type: 'letter' },
  { code: 'KeyA', shiftKey: true, key: 'Ф', type: 'letter' },
  { code: 'KeyS', shiftKey: false, key: 'ы', type: 'letter' },
  { code: 'KeyS', shiftKey: true, key: 'Ы', type: 'letter' },
  { code: 'KeyD', shiftKey: false, key: 'в', type: 'letter' },
  { code: 'KeyD', shiftKey: true, key: 'В', type: 'letter' },
  { code: 'KeyF', shiftKey: false, key: 'а', type: 'letter' },
  { code: 'KeyF', shiftKey: true, key: 'А', type: 'letter' },
  { code: 'KeyG', shiftKey: false, key: 'п', type: 'letter' },
  { code: 'KeyG', shiftKey: true, key: 'П', type: 'letter' },
  { code: 'KeyH', shiftKey: false, key: 'р', type: 'letter' },
  { code: 'KeyH', shiftKey: true, key: 'Р', type: 'letter' },
  { code: 'KeyJ', shiftKey: false, key: 'о', type: 'letter' },
  { code: 'KeyJ', shiftKey: true, key: 'О', type: 'letter' },
  { code: 'KeyK', shiftKey: false, key: 'л', type: 'letter' },
  { code: 'KeyK', shiftKey: true, key: 'Л', type: 'letter' },
  { code: 'KeyL', shiftKey: false, key: 'д', type: 'letter' },
  { code: 'KeyL', shiftKey: true, key: 'Д', type: 'letter' },
  { code: 'Semicolon', shiftKey: false, key: 'ж', type: 'letter' },
  { code: 'Semicolon', shiftKey: true, key: 'Ж', type: 'letter' },
  { code: 'Quote', shiftKey: false, key: 'э', type: 'letter' },
  { code: 'Quote', shiftKey: true, key: 'Э', type: 'letter' },
  { code: 'Enter', key: 'Enter', type: 'sys' },
  { code: 'ShiftLeft', key: 'Shift', type: 'sys' },
  {
    code: 'IntlBackslash',
    shiftKey: false,
    key: '\\',
    type: 'symbol',
    alternate: 'Backslash',
  },
  {
    code: 'IntlBackslash',
    shiftKey: true,
    key: '/',
    type: 'symbol',
    alternate: 'Backslash',
  },
  { code: 'KeyZ', shiftKey: false, key: 'я', type: 'letter' },
  { code: 'KeyZ', shiftKey: true, key: 'Я', type: 'letter' },
  { code: 'KeyX', shiftKey: false, key: 'ч', type: 'letter' },
  { code: 'KeyX', shiftKey: true, key: 'Ч', type: 'letter' },
  { code: 'KeyC', shiftKey: false, key: 'с', type: 'letter' },
  { code: 'KeyC', shiftKey: true, key: 'С', type: 'letter' },
  { code: 'KeyV', shiftKey: false, key: 'м', type: 'letter' },
  { code: 'KeyV', shiftKey: true, key: 'М', type: 'letter' },
  { code: 'KeyB', shiftKey: false, key: 'и', type: 'letter' },
  { code: 'KeyB', shiftKey: true, key: 'И', type: 'letter' },
  { code: 'KeyN', shiftKey: false, key: 'т', type: 'letter' },
  { code: 'KeyN', shiftKey: true, key: 'Т', type: 'letter' },
  { code: 'KeyM', shiftKey: false, key: 'ь', type: 'letter' },
  { code: 'KeyM', shiftKey: true, key: 'Ь', type: 'letter' },
  { code: 'Comma', shiftKey: false, key: 'б', type: 'letter' },
  { code: 'Comma', shiftKey: true, key: 'Б', type: 'letter' },
  { code: 'Period', shiftKey: false, key: 'ю', type: 'letter' },
  { code: 'Period', shiftKey: true, key: 'Ю', type: 'letter' },
  { code: 'Slash', shiftKey: false, key: '.', type: 'symbol' },
  { code: 'Slash', shiftKey: true, key: ',', type: 'symbol' },
  { code: 'ShiftRight', key: 'Shift', type: 'sys' },
  { code: 'ControlLeft', key: 'Ctrl', type: 'sys' },
  { code: 'MetaLeft', key: '', type: 'sys' },
  { code: 'AltLeft', key: 'Alt', type: 'sys' },
  { code: 'Space', key: '', type: 'sys' },
  { code: 'AltRight', key: 'Alt', type: 'sys' },
  { code: 'MetaRight', key: 'Meta', type: 'sys' },
  { code: 'ContextMenu', key: 'ContextMenu', type: 'sys' },
  { code: 'ControlRight', key: 'Ctrl', type: 'sys' },
];
